// Generated by Avo VERSION 155.30.0. You should never have to make changes to this file.
// If you find yourself in the situation where you have to edit the file please contact us at hi@avo.app.
// If you encounter a git conflict in this file run `avo pull` and it will be resolved automatically.
/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// fetch() polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  var support = {
    searchParams: 'URLSearchParams' in self,
    iterable: 'Symbol' in self && 'iterator' in Symbol,
    blob:
      'FileReader' in self &&
      'Blob' in self &&
      (function () {
        try {
          new Blob();
          return true
        } catch (e) {
          return false
        }
      })(),
    formData: 'FormData' in self,
    arrayBuffer: 'ArrayBuffer' in self
  };

  function isDataView(obj: any) {
    return obj && DataView.prototype.isPrototypeOf(obj)
  }

  if (support.arrayBuffer) {
    var viewClasses = [
      '[object Int8Array]',
      '[object Uint8Array]',
      '[object Uint8ClampedArray]',
      '[object Int16Array]',
      '[object Uint16Array]',
      '[object Int32Array]',
      '[object Uint32Array]',
      '[object Float32Array]',
      '[object Float64Array]'
    ];

    var isArrayBufferView =
      ArrayBuffer.isView ||
      function (obj) {
        return obj && viewClasses.indexOf(Object.prototype.toString.call(obj)) > -1
      };
  }

  function normalizeName(name: any) {
    if (typeof name !== 'string') {
      name = String(name);
    }
    if (/[^a-z0-9\-#$%&'*+.^_`|~]/i.test(name)) {
      throw new TypeError('Invalid character in header field name')
    }
    return name.toLowerCase()
  }

  function normalizeValue(value: any) {
    if (typeof value !== 'string') {
      value = String(value);
    }
    return value
  }

  // Build a destructive iterator for the value list
  function iteratorFor(items: any) {
    var iterator: any = {
      next: function () {
        var value = items.shift();
        return { done: value === undefined, value: value }
      }
    };

    if (support.iterable) {
      iterator[Symbol.iterator] = function () {
        return iterator
      };
    }

    return iterator
  }

  function Headers(headers: any) {
    // @ts-ignore
    (this as any).map = {};

    if (headers instanceof Headers) {
      (headers as any).forEach(function (value: any, name: any) {
        // @ts-ignore
        this.append(name, value);
        // @ts-ignore
      }, this);
    } else if (Array.isArray(headers)) {
      headers.forEach(function (header: any) {
        // @ts-ignore
        this.append(header[0], header[1]);
        // @ts-ignore
      }, this);
    } else if (headers) {
      Object.getOwnPropertyNames(headers).forEach(function (name: any) {
        // @ts-ignore
        this.append(name, headers[name]);
        // @ts-ignore
      }, this);
    }
  }

  Headers.prototype.append = function (name: any, value: any) {
    name = normalizeName(name);
    value = normalizeValue(value);
    var oldValue = this.map[name];
    this.map[name] = oldValue ? oldValue + ', ' + value : value;
  };

  Headers.prototype['delete'] = function (name: any) {
    delete this.map[normalizeName(name)];
  };

  Headers.prototype.get = function (name: any) {
    name = normalizeName(name);
    return this.has(name) ? this.map[name] : null
  };

  Headers.prototype.has = function (name: any) {
    return this.map.hasOwnProperty(normalizeName(name))
  };

  Headers.prototype.set = function (name: any, value: any) {
    this.map[normalizeName(name)] = normalizeValue(value);
  };

  Headers.prototype.forEach = function (callback: any, thisArg: any) {
    for (var name in this.map) {
      if (this.map.hasOwnProperty(name)) {
        callback.call(thisArg, this.map[name], name, this);
      }
    }
  };

  Headers.prototype.keys = function () {
    var items: any = [];
    this.forEach(function (_value: any, name: any) {
      items.push(name);
    });
    return iteratorFor(items)
  };

  Headers.prototype.values = function () {
    var items: any = [];
    this.forEach(function (value: any) {
      items.push(value);
    });
    return iteratorFor(items)
  };

  Headers.prototype.entries = function () {
    var items: any = [];
    this.forEach(function (value: any, name: any) {
      items.push([name, value]);
    });
    return iteratorFor(items)
  };

  if (support.iterable) {
    Headers.prototype[Symbol.iterator] = Headers.prototype.entries;
  }

  function consumed(body: any) {
    if (body.bodyUsed) {
      return true;
    }
    body.bodyUsed = true;
    return false;
  }

  function fileReaderReady(reader: any) {
    return new Promise(function (resolve: any, reject: any) {
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function () {
        reject(reader.error);
      };
    })
  }

  function readBlobAsArrayBuffer(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsArrayBuffer(blob);
    return promise
  }

  function readBlobAsText(blob: any) {
    var reader = new FileReader();
    var promise = fileReaderReady(reader);
    reader.readAsText(blob);
    return promise
  }

  function readArrayBufferAsText(buf: any) {
    var view = new Uint8Array(buf);
    var chars = new Array(view.length);

    for (var i = 0; i < view.length; i++) {
      chars[i] = String.fromCharCode(view[i]!);
    }
    return chars.join('')
  }

  function bufferClone(buf: any) {
    if (buf.slice) {
      return buf.slice(0)
    } else {
      var view = new Uint8Array(buf.byteLength);
      view.set(new Uint8Array(buf));
      return view.buffer
    }
  }

  function Body() {
    // @ts-ignore
    (this as any).bodyUsed = false;

    // @ts-ignore
    (this as any)._initBody = function (body: any) {
      this._bodyInit = body;
      if (!body) {
        this._bodyText = '';
      } else if (typeof body === 'string') {
        this._bodyText = body;
      } else if (support.blob && Blob.prototype.isPrototypeOf(body)) {
        this._bodyBlob = body;
      } else if (support.formData && FormData.prototype.isPrototypeOf(body)) {
        this._bodyFormData = body;
      } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
        this._bodyText = body.toString();
      } else if (support.arrayBuffer && support.blob && isDataView(body)) {
        this._bodyArrayBuffer = bufferClone(body.buffer);
        // IE 10-11 can't handle a DataView body.
        this._bodyInit = new Blob([this._bodyArrayBuffer]);
      } else if (support.arrayBuffer && (ArrayBuffer.prototype.isPrototypeOf(body) || isArrayBufferView(body))) {
        this._bodyArrayBuffer = bufferClone(body);
      } else {
        this._bodyText = body = Object.prototype.toString.call(body);
      }

      if (!this.headers.get('content-type')) {
        if (typeof body === 'string') {
          this.headers.set('content-type', 'text/plain;charset=UTF-8');
        } else if (this._bodyBlob && this._bodyBlob.type) {
          this.headers.set('content-type', this._bodyBlob.type);
        } else if (support.searchParams && URLSearchParams.prototype.isPrototypeOf(body)) {
          this.headers.set('content-type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
      }
    };

    if (support.blob) {
      // @ts-ignore
      (this as any).blob = function () {
        var rejected = consumed(this);
        if (rejected) {
          return Promise.reject(new TypeError('Already read'));
        }

        if (this._bodyBlob) {
          return Promise.resolve(this._bodyBlob);
        } else if (this._bodyArrayBuffer) {
          return Promise.resolve(new Blob([this._bodyArrayBuffer]));
        } else if (this._bodyFormData) {
          throw new Error('could not read FormData body as blob');
        } else {
          return Promise.resolve(new Blob([this._bodyText]));
        }
      };

      // @ts-ignore
      (this as any).arrayBuffer = function () {
        if (this._bodyArrayBuffer) {
          if (consumed(this)) {
            return Promise.reject(new TypeError('Already read'));
          } else {
            return Promise.resolve(this._bodyArrayBuffer);
          }
        } else {
          return this.blob().then(readBlobAsArrayBuffer);
        }
      };
    }

    // @ts-ignore
    (this as any).text = function () {
      var rejected = consumed(this);
      if (rejected) {
        return Promise.reject(new TypeError('Already read'));
      }

      if (this._bodyBlob) {
        return readBlobAsText(this._bodyBlob);
      } else if (this._bodyArrayBuffer) {
        return Promise.resolve(readArrayBufferAsText(this._bodyArrayBuffer));
      } else if (this._bodyFormData) {
        throw new Error('could not read FormData body as text');
      } else {
        return Promise.resolve(this._bodyText);
      }
    };

    if (support.formData) {
      // @ts-ignore
      (this as any).formData = function () {
        return this.text().then(decode)
      };
    }

    // @ts-ignore
    (this as any).json = function () {
      return this.text().then(JSON.parse)
    };

    // @ts-ignore
    return this
  }

  // HTTP methods whose capitalization should be normalized
  var methods = ['DELETE', 'GET', 'HEAD', 'OPTIONS', 'POST', 'PUT'];

  function normalizeMethod(method: any) {
    var upcased = method.toUpperCase();
    return methods.indexOf(upcased) > -1 ? upcased : method
  }

  function Request(input: any, options: any) {
    options = options || {};
    var body = options.body;

    if (input instanceof Request) {
      if ((input as any).bodyUsed) {
        throw new TypeError('Already read')
      }
      // @ts-ignore
      (this as any).url = (input as any).url;
      // @ts-ignore
      this.credentials = (input as any).credentials;
      if (!options.headers) {
        // @ts-ignore
        this.headers = new Headers((input as any).headers);
      }
      // @ts-ignore
      this.method = (input as any).method;
      // @ts-ignore
      this.mode = (input as any).mode;
      // @ts-ignore
      this.signal = (input as any).signal;
      if (!body && (input as any)._bodyInit != null) {
        body = (input as any)._bodyInit;
        (input as any).bodyUsed = true;
      }
    } else {
      // @ts-ignore
      this.url = String(input);
    }

    // @ts-ignore
    this.credentials = options.credentials || this.credentials || 'same-origin';
    // @ts-ignore
    if (options.headers || !this.headers) {
      // @ts-ignore
      this.headers = new Headers(options.headers);
    }
    // @ts-ignore
    this.method = normalizeMethod(options.method || this.method || 'GET');
    // @ts-ignore
    this.mode = options.mode || this.mode || null;
    // @ts-ignore
    this.signal = options.signal || this.signal;
    // @ts-ignore
    this.referrer = null;

    // @ts-ignore
    if ((this.method === 'GET' || this.method === 'HEAD') && body) {
      throw new TypeError('Body not allowed for GET or HEAD requests')
    }
    // @ts-ignore
    this._initBody(body);
  }

  Request.prototype.clone = function () {
    // @ts-ignore
    return new Request(this, { body: this._bodyInit })
  };

  function decode(body: any) {
    var form = new FormData();
    body
      .trim()
      .split('&')
      .forEach(function (bytes: any) {
        if (bytes) {
          var split = bytes.split('=');
          var name = split.shift().replace(/\+/g, ' ');
          var value = split.join('=').replace(/\+/g, ' ');
          form.append(decodeURIComponent(name), decodeURIComponent(value));
        }
      });
    return form
  }

  function parseHeaders(rawHeaders: any) {
    // @ts-ignore
    var headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders.split(/\r?\n/).forEach(function (line: any) {
      var parts = line.split(':');
      var key = parts.shift().trim();
      if (key) {
        var value = parts.join(':').trim();
        headers.append(key, value);
      }
    });
    return headers
  }

  Body.call(Request.prototype);

  function Response(bodyInit: any, options: any) {
    if (!options) {
      options = {};
    }

    // @ts-ignore
    this.type = 'default';
    // @ts-ignore
    this.status = options.status === undefined ? 200 : options.status;
    // @ts-ignore
    this.ok = this.status >= 200 && this.status < 300;
    // @ts-ignore
    this.statusText = 'statusText' in options ? options.statusText : 'OK';
    // @ts-ignore
    this.headers = new Headers(options.headers);
    // @ts-ignore
    this.url = options.url || '';
    // @ts-ignore
    this._initBody(bodyInit);
  }

  Body.call(Response.prototype);

  Response.prototype.clone = function () {
    // @ts-ignore
    return new Response(this._bodyInit, {
      status: this.status,
      statusText: this.statusText,
      // @ts-ignore
      headers: new Headers(this.headers),
      url: this.url
    })
  };

  Response.error = function () {
    // @ts-ignore
    var response = new Response(null, { status: 0, statusText: '' });
    response.type = 'error';
    return response
  };

  var redirectStatuses = [301, 302, 303, 307, 308];

  Response.redirect = function (url: any, status: any) {
    if (redirectStatuses.indexOf(status) === -1) {
      throw new RangeError('Invalid status code')
    }

    // @ts-ignore
    return new Response(null, { status: status, headers: { location: url } })
  };

  (self as any).DOMException = (self as any).DOMException;
  try {
    new (self as any).DOMException();
  } catch (err) {
    (self as any).DOMException = function (message: any, name: any) {
      this.message = message;
      this.name = name;
      var error = Error(message);
      this.stack = error.stack;
    };
    (self as any).DOMException.prototype = Object.create(Error.prototype);
    (self as any).DOMException.prototype.constructor = (self as any).DOMException;
  }

  function fetch(input: any, init: any) {
    return new Promise(function (resolve, reject) {
      // @ts-ignore
      var request = new Request(input, init);

      if (request.signal && request.signal.aborted) {
        return reject(new (self as any).DOMException('Aborted', 'AbortError'))
      }

      var xhr = new XMLHttpRequest();

      function abortXhr() {
        xhr.abort();
      }

      xhr.onload = function () {
        var options = {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: parseHeaders(xhr.getAllResponseHeaders() || '')
        };
        (options as any).url = 'responseURL' in xhr ? xhr.responseURL : options.headers.get('X-Request-URL');
        var body = 'response' in xhr ? xhr.response : (xhr as any).responseText;
        // @ts-ignore
        resolve(new Response(body, options));
      };

      xhr.onerror = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.ontimeout = function () {
        reject(new TypeError('Network request failed'));
      };

      xhr.onabort = function () {
        reject(new (self as any).DOMException('Aborted', 'AbortError'));
      };

      xhr.open(request.method, request.url, true);

      if (request.credentials === 'include') {
        xhr.withCredentials = true;
      } else if (request.credentials === 'omit') {
        xhr.withCredentials = false;
      }

      if ('responseType' in xhr && support.blob) {
        xhr.responseType = 'blob';
      }

      request.headers.forEach(function (value: any, name: any) {
        xhr.setRequestHeader(name, value);
      });

      if (request.signal) {
        request.signal.addEventListener('abort', abortXhr);

        xhr.onreadystatechange = function () {
          // DONE (success or failure)
          if (xhr.readyState === 4) {
            request.signal.removeEventListener('abort', abortXhr);
          }
        };
      }

      xhr.send(typeof request._bodyInit === 'undefined' ? null : request._bodyInit);
    })
  }

  fetch.polyfill = true;

  if (!self.fetch) {
    (self as any).fetch = fetch;
    (self as any).Headers = Headers;
    (self as any).Request = Request;
    (self as any).Response = Response;
  }
})();

export enum AvoEnv {
  Prod = "prod",
  Dev = "dev",
}

export interface CustomDestination {
  make?(env: AvoEnv, apiKey: string): void;
  logEvent?: (eventName: string, eventProperties: Record<string, any>) => void;
  setUserProperties?: (userId: string, userProperties: Record<string, any>) => void;
  identify?: (userId: string) => void;
  unidentify?: () => void;
  logPage?: (pageName: string, eventProperties: Record<string, any>) => void;
  revenue?: (amount: number, eventProperties: Record<string, any>) => void;
  setGroupProperties?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  addCurrentUserToGroup?: (
    groupType: string,
    groupId: string,
    groupProperties: Record<string, any>,
  ) => void;
  logEventWithGroups?: (
    eventName: string,
    eventProperties: any,
    groupTypesToGroupIds: Record<string, string>,
  ) => void;
}

// @ts-ignore
interface AvoAssertMessage {
  eventName?: string;
  tag?: string;
  propertyId?: string;
  message?: string;
  additionalProperties?: string[],
  shape?: any,
  shapeUserProps?: any,
  actualType?: string
}

let __AVO_ENV__: AvoEnv | null = null;
// @ts-ignore
let __AVO_NOOP__: boolean = false;
// @ts-ignore
let __AVO_LOGGER__: AvoLogger | null = null;
// @ts-ignore
let __STRICT__: boolean | null = null;
// @ts-ignore
let __REPORT_FAILURE_AS__: 'error' | 'warn' | 'log' | null = null;

// @ts-ignore
let __WEB_DEBUGGER__: boolean = true;
export const avoInspectorApiKey = "dIrDrnL63JTyGyy0tu2f";
// @ts-ignore
interface AvoInspector {}
let __INSPECTOR__: AvoInspector | null = null;


// polyfill Object.assign
// @ts-ignore
declare interface ObjectConstructor {
  assign: any;
}
// @ts-ignore
if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, "assign", {
    value: function assign(target: any, _varArgs: any) { // .length of function is 2
      if (target == null) { // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      let to = Object(target);

      for (let index = 1; index < arguments.length; index++) {
        let nextSource = arguments[index];

        if (nextSource != null) { // Skip over if undefined or null
          for (let nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

interface AvoLogger {
  logDebug(env: AvoEnv | null, message: string): boolean;
  logWarn(env: AvoEnv | null, message: string): boolean;
  logError(env: AvoEnv | null, error: string): boolean;
}

enum webDebuggerArea {
  BottomRight = "BottomRight",
  BottomLeft = "BottomLeft",
  TopRight = "TopRight",
  TopLeft = "TopLeft"
}

interface bottomRightParameters {
  bottom: number;
  right: number;
}

interface bottomLeftParameters {
  bottom: number;
  left: number;
}

interface topRightParameters {
  top: number;
  right: number;
}

interface topLeftParameters {
  top: number;
  left: number;
}

interface webDebuggerPosition {
  position: webDebuggerArea;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

interface webDebuggerOptions {
  position?: webDebuggerPosition;
}

interface WebDebuggerPositionSetter {
  BottomRight(p: bottomRightParameters): webDebuggerPosition;
  BottomLeft(p: bottomLeftParameters): webDebuggerPosition;
  TopRight(p: topRightParameters): webDebuggerPosition;
  TopLeft(p: topLeftParameters): webDebuggerPosition;
}

export const WebDebuggerPosition: WebDebuggerPositionSetter = {
  BottomRight: ({ bottom, right }) => ({
    position: webDebuggerArea.BottomRight,
    bottom,
    right,
  }),
  BottomLeft: ({ bottom, left }) => ({
    position: webDebuggerArea.BottomLeft,
    bottom,
    left,
  }),
  TopRight: ({ top, right }) => ({
    position: webDebuggerArea.TopRight,
    top,
    right,
  }),
  TopLeft: ({ top, left }) => ({
    position: webDebuggerArea.TopLeft,
    top,
    left,
  }),
}


let InternalAvoLogger: any = {
  logEventSent: function logEventSent(eventName: string, eventProperties: any, userProperties: any) {
    const message = "Event Sent:" + eventName + "Event Props:" + JSON.stringify(eventProperties) + "User Props:" + JSON.stringify(userProperties);

    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] Event Sent:", eventName, "Event Props:", eventProperties, "User Props:", userProperties);
  },

  log: function log(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logDebug && __AVO_LOGGER__.logDebug(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.log("[avo] " + message);
  },

  warn: function warn(message: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logWarn && __AVO_LOGGER__.logWarn(__AVO_ENV__, message)) {
      return
    }
    typeof console !== 'undefined' && console.warn("[avo] " + message);
  },

  error: function error(message: string, error: string) {
    if (__AVO_LOGGER__ && __AVO_LOGGER__.logError && __AVO_LOGGER__.logError(__AVO_ENV__, message + error)) {
      return
    }
    typeof console !== 'undefined' && console.error("[avo] " + message, error);
  }
};

function convertPropertiesArrayToMap(propertiesArray: [{id: string, name: string, value: string}]): {string: string | null | undefined} {
    let result: {string: string} = {}

    propertiesArray.forEach(value => {
      result[value.name] = value.value
    })

    return result
}

// @ts-ignore
let array_difference: any;
// @ts-ignore
let AvoAssert: any;
array_difference = function array_difference(a1: any[], a2: any[]) {
  let result: any[] = [];
  for (let i = 0; i < a1.length; i++) {
    if (a2.indexOf(a1[i]) === -1) {
      result.push(a1[i]);
    }
  }
  return result;
}

AvoAssert = {
  assertObject: function assertObject(propertyId: string, propName: string, obj: any) {
    if (typeof obj !== 'object') {
      let message = propName +
          ' should be of type object but you provided type ' +
          typeof obj +
          ' with value ' +
          JSON.stringify(obj);
      return [{tag: 'expectedObjectType', propertyId, message, actualType: typeof obj}];
    } else {
      return [];
    }
  },

  assertString: function assertString(propertyId: string, propName: string, str: string) {
    if (typeof str !== 'string') {
      let message = propName +
          ' should be of type string but you provided type ' +
          typeof str +
          ' with value ' +
          JSON.stringify(str);
      return [{tag: 'expectedStringType', propertyId, message, actualType: typeof str}];
    } else {
      return [];
    }
  },

  assertInt: function assertInt(propertyId: string, propName: string, int: number) {
    if (typeof int === 'number' && int !== Math.round(int)) {
      let message = propName +
          ' should be of type int but you provided type float with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: 'float'}];
    } else if (typeof int !== 'number') {
      let message = propName +
          ' should be of type int but you provided type ' +
          typeof int +
          ' with value ' +
          JSON.stringify(int);
      return [{tag: 'expectedIntType', propertyId, message, actualType: typeof int}];
    } else {
      return [];
    }
  },

  assertLong: function assertLong(propertyId: string, propName: string, long: number) {
    if (typeof long === 'number' && long !== Math.round(long)) {
      let message = propName +
          ' should be of type long but you provided type float with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: 'float'}];
    } else if (typeof long !== 'number') {
      let message = propName +
          ' should be of type long but you provided type ' +
          typeof long +
          ' with value ' +
          JSON.stringify(long);
      return [{tag: 'expectedLongType', propertyId, message, actualType: typeof long}];
    } else {
      return [];
    }
  },

  assertFloat: function assertFloat(propertyId: string, propName: string, float: number) {
    if (typeof float !== 'number') {
      let message = propName +
          ' should be of type float but you provided type ' +
          typeof float +
          ' with value ' +
          JSON.stringify(float);
      return [{tag: 'expectedFloatType', propertyId, message, actualType: typeof float}];
    } else {
      return [];
    }
  },

  assertBool: function assertBool(propertyId: string, propName: string, bool: boolean) {
    if (typeof bool !== 'boolean') {
      let message = propName +
          ' should be of type boolean but you provided type ' +
          typeof bool +
          ' with value ' +
          JSON.stringify(bool);
      return [{tag: 'expectedBoolType', propertyId, message, actualType: typeof bool}];
    } else {
      return [];
    }
  },

  assertMax: function assertMax(
    propertyId: string,
    propName: string,
    max: number,
    value: number
  ) {
    if (value > max) {
      let message = propName +
        ' has a maximum value of ' +
        max +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMax', propertyId, message}];
    } else {
      return [];
    }
  },

  assertMin: function assertMin(
    propertyId: string,
    propName: string,
    min: number,
    value: number
  ) {
    if (value < min) {
      let message = propName +
        ' has a minimum value of ' +
        min +
        ' but you provided the value ' +
        JSON.stringify(value);
      return [{tag: 'expectedMin', propertyId, message}];
    } else {
      return [];
    }
  },

  assertList: function assertList(propertyId: string, propName: string, value: any) {
    if (!Array.isArray(value)) {
      let message = propName + ' should be of type list but you provided type ' + typeof value;
      return [{tag: 'expectedList', propertyId, message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },

  assertNoAdditionalUserProperties: function assertNoAdditionalProperties(eventName: string, input: string[], spec: string[]) {
    let additionalKeys = array_difference(input, spec);
    if (additionalKeys.length) {
      let message = "Additional user properties when sending event " + eventName + ": " + JSON.stringify(additionalKeys);
      return [{tag: 'expectedNoAdditionalUserProperties', additionalProperties: additionalKeys, message: message}];
    } else {
      return [];
    }
  },
};

let _avo_invoke: any;
let _avo_invoke_meta: any;
let _avo_sampling_rate = 1.0;
_avo_invoke = function _avo_invoke(env: AvoEnv, eventId: string, hash: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "63mrvDVXQI2PuL0JnmZR",
          "br": "master",
          "en": env,
          "ev": eventId,
          "ha": hash,
          "sc": "MouZL0iIZXRZD1IE6U6E",
          "se": (new Date()).toISOString(),
          "so": "aUBojkM_H",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}

_avo_invoke_meta = function _avo_invoke_meta(env: AvoEnv, type: string, messages: {tag: string, propertyId: string}[], origin: string) {
  // @ts-ignore
  if (typeof (window as any) === 'undefined') { return; }
  if (_avo_sampling_rate > 0) {
    if (Math.random() < _avo_sampling_rate) {
      // @ts-ignore
      fetch("https://api.avo.app/i", {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "ac": "63mrvDVXQI2PuL0JnmZR",
          "br": "master",
          "en": env,
          "ty": type,
          "sc": "MouZL0iIZXRZD1IE6U6E",
          "se": (new Date()).toISOString(),
          "so": "aUBojkM_H",
          "va": messages.length === 0,
          "me": messages,
          "or": origin
        })
      }).then(function(res: any) { return res.json(); }).then(function(data: any) { _avo_sampling_rate = data.sa; }).catch(function() {});
    }
  }
}


let _avo_debugger_log: any;
let _avo_debugger_events_during_boot: any = [];
let _avo_debugger_ready = false;

if (typeof (window as any) !== 'undefined') {
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    let iframe: any = document.getElementById("avo-debugger");
    if (iframe && event && event.data && event.data.type_ === "avo-debugger-update-style") {
      iframe.style = event.data.style;
    } else if (iframe && event && event.data && event.data.type_ === "avo-debugger-ready") {
      let message = {
        type_: "avo-debugger-boot-events",
        schemaId: "MouZL0iIZXRZD1IE6U6E",
        href: window.location.href,
        events: _avo_debugger_events_during_boot
      };
      _avo_debugger_events_during_boot = [];
      _avo_debugger_ready = true;
      iframe.contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  });
}

_avo_debugger_log = function _avo_debugger_log(eventId: string, eventName: string, messages: any[], eventProperties: any[], userProperties: any[], groupProperties: any[]) {
  if (typeof (window as any) === 'undefined') { return; }
  let event = {
    eventId: eventId,
    eventName: eventName,
    messages: messages,
    timestamp: Date.now(),
    eventProperties,
    userProperties,
    groupProperties
  };

  if (_avo_debugger_ready) {
    let message = {type_: "avo-debugger-events", events: [event]};
    (document.getElementById("avo-debugger") as any).contentWindow.postMessage(message, "https://www.avo.app/_debugger")
  } else {
    _avo_debugger_events_during_boot.push(event);
  }
}

function _avo_debugger_send_position(position: webDebuggerPosition) {
  if (typeof window === 'undefined') { return; }
  window.addEventListener("message", function(event) {
    if (event.origin !== "https://www.avo.app") {
      return;
    }
    if (event.data.type_ === "avo-debugger-ready" && position !== null) {
      var message = {type_: "avo-debugger-position", position: position};
      document.getElementById("avo-debugger").contentWindow.postMessage(message, "https://www.avo.app/_debugger")
    }
  })
}


export const ContentSubtype = {
  'ROLE_MODEL': 'role_model',
  'RECIPE': 'recipe',
  'WATCH_ME_THRIVE': 'watch_me_thrive',
  'ARTICLE': 'article',
  'FSA': 'fsa',
  'WELL_BEING_BUNDLE': 'well_being_bundle',
  'FINANCIAL_WELLNESS': 'financial_wellness',
  'REMOTE_PERKS': 'remote_perks',
  'IN_OFFICE_PERKS': 'in_office_perks',
  'TIME_OFF': 'time_off',
  'FAMILY_SUPPORT': 'family_support',
  'MEDICAL': 'medical',
  'DENTAL': 'dental',
  'VISION': 'vision',
  'LIFE': 'life',
  'SHORT_TERM_DISABILITY': 'short_term_disability',
  'LONG_TERM_DISABILITY': 'long_term_disability',
  'AD_AND_D': 'ad_and_d',
} as const;
export type ContentSubtypeType = typeof ContentSubtype;
export type ContentSubtypeValueType = ContentSubtypeType[keyof ContentSubtypeType];

export const ContentType = {
  'ARTICLE': 'article',
  'BOOK': 'book',
  'COURSE': 'course',
  'LESSON': 'lesson',
  'PODCAST': 'podcast',
  'PODCAST_EPISODE': 'podcast_episode',
  'RECIPE': 'recipe',
  'RESET': 'reset',
  'ROLE_MODEL': 'role_model',
  'WEBINAR': 'webinar',
  'AUDIO_BOOK': 'audio_book',
  'AUDIO_BOOK_CHAPTER_PART': 'audio_book_chapter_part',
  'MODULE': 'module',
  'COMPANY_RESOURCE': 'company_resource',
  'MICROSTEP': 'microstep',
  'PODCAST_SEASON': 'podcast_season',
  'JOURNEY_DAILY_STEP': 'journey_daily_step',
  'INSURANCE': 'insurance',
  'EBOOK': 'ebook',
} as const;
export type ContentTypeType = typeof ContentType;
export type ContentTypeValueType = ContentTypeType[keyof ContentTypeType];

export const Method = {
  'KEYCLOAK': 'keycloak',
  'MAGIC_LINK': 'magic_link',
  'CREDENTIALS': 'credentials',
  'SSO': 'sso',
  'MICROSOFT': 'microsoft',
} as const;
export type MethodType = typeof Method;
export type MethodValueType = MethodType[keyof MethodType];

export const ContentSource = {
  'UMG': 'umg',
  'THRIVE': 'thrive',
} as const;
export type ContentSourceType = typeof ContentSource;
export type ContentSourceValueType = ContentSourceType[keyof ContentSourceType];

export const AppSource = {
  'WEB': 'web',
  'CALLS_PILOT': 'calls_pilot',
  'GENESYS': 'genesys',
  'HYBRID_IOS': 'hybrid-ios',
  'HYBRID_ANDROID': 'hybrid-android',
  'MSTEAMS': 'msteams',
  'NICE': 'nice',
  'SLACK': 'slack',
  'WEBEX': 'webex',
  'WEARABLES': 'wearables',
  'KEYCLOAK': 'keycloak',
} as const;
export type AppSourceType = typeof AppSource;
export type AppSourceValueType = AppSourceType[keyof AppSourceType];

export const Status = {
  'SUCCESS': 'success',
  'FAILURE': 'failure',
} as const;
export type StatusType = typeof Status;
export type StatusValueType = StatusType[keyof StatusType];

export const ActivityType = {
  'THRIVE_GUIDE_RECOMMENDATION': 'thrive_guide_recommendation',
  'LEARN_ARTICLE_CLICKED': 'learn_article_clicked',
  'PUSH_NOTIFICATION': 'push_notification',
  'ARTICLE_VIDEO_COMPLETED': 'article_video_completed',
  'COURSE_LESSON_COMPLETED': 'course_lesson_completed',
  'COURSE_MODULE_COMPLETED': 'course_module_completed',
  'COURSE_COMPLETED': 'course_completed',
  'PODCAST_COMPLETED': 'podcast_completed',
  'PODCAST_EPISODE_COMPLETED': 'podcast_episode_completed',
  'PODCAST_SEASON_COMPLETED': 'podcast_season_completed',
  'WEBINAR_SESSION_VIDEO_COMPLETED': 'webinar_session_video_completed',
  'AUDIOBOOK_DOWNLOADED': 'audiobook_downloaded',
  'LESSON_RESOURCE_DOWNLOADED': 'lesson_resource_downloaded',
  'COURSE_RESOURCE_DOWNLOADED': 'course_resource_downloaded',
  'WEBINAR_RESOURCE_CLICKED': 'webinar_resource_clicked',
  'MICROSTEP_MANAGEMENT': 'microstep_management',
  'CLAP_SUBMITTED': 'clap_submitted',
  'FAVORITE_SUBMITTED': 'favorite_submitted',
  'RESET_STARTED': 'reset_started',
  'RESET_COMPLETED': 'reset_completed',
  'STORY_PHOTO_UPLOADED': 'story_photo_uploaded',
  'STORY_SUBMISSION_FORM_OPENED': 'story_submission_form_opened',
  'STORY_CONSENT_GIVEN': 'story_consent_given',
  'STORY_SUBMISSION_COMPLETED': 'story_submission_completed',
  'ARTICLE_VIDEO_STARTED': 'article_video_started',
  'ARTICLE_STARTED': 'article_started',
  'AUDIO_BOOK_CHAPTER_PART_COMPLETED': 'audio_book_chapter_part_completed',
  'FEEDBACK_SUBMITTED': 'feedback_submitted',
  'RESET_RECOMMENDATION_SELECTED': 'reset_recommendation_selected',
  'LOAD_MORE_RESETS': 'load_more_resets',
  'RESET_SCHEDULE_COMPLETED': 'reset_schedule_completed',
  'RESET_SCHEDULE_OPENED': 'reset_schedule_opened',
  'RESET_THEME_RECOMMENDATION_OPTIONS_SERVED': 'reset_theme_recommendation_options_served',
  'RESET_CREATION_OPTIONS_SERVED': 'reset_creation_options_served',
  'DAILY_GOALS_COMPLETED': 'daily_goals_completed',
  'CHALLENGE_STARTED': 'challenge_started',
  'CHALLENGE_SIGNUP_FORM_SERVED': 'challenge_signup_form_served',
  'RESET_CREATION_STARTED': 'reset_creation_started',
  'RESET_CREATION_ADDED': 'reset_creation_added',
  'AUDIO_ADDED': 'audio_added',
  'MEDIA_ADDED': 'media_added',
  'RESET_SHARED': 'reset_shared',
  'RESET_SERVED': 'reset_served',
  'ONBOARDING_RESET_SERVED': 'onboarding_reset_served',
  'WEB_OPENED': 'web_opened',
  'THRIVE_GUIDE_USER_RESPONDED': 'thrive_guide_user_responded',
  'SOCIAL_GROUP_JOINED': 'social_group_joined',
  'STORY_ANSWER_ADDED': 'story_answer_added',
  'STORY_CANCELLED': 'story_cancelled',
  'STORY_NO_DATA': 'story_no_data',
  'STORY_SUBMISSION_STARTED': 'story_submission_started',
  'CHALLENGE_MICROSTEP': 'challenge_microstep',
  'CHALLENGE_LEFT': 'challenge_left',
  'CHALLENGE_SELECTED': 'challenge_selected',
  'USER_SIGNIN': 'user_signin',
  'USER_SIGNOUT': 'user_signout',
  'COMPANY_SELECTED': 'company_selected',
  'REFERRAL_SIGNUP_METHOD_SELECTED': 'referral_signup_method_selected',
  'REFERRAL_SIGNUP_STARTED': 'referral_signup_started',
  'USER_REQUESTED_USERNAME_PASSWORD': 'user_requested_username_password',
  'SIGNUP_METHOD_SELECTED': 'signup_method_selected',
  'SIGNUP_STARTED': 'signup_started',
  'WRONG_COMPANY_CLICKED': 'wrong_company_clicked',
  'REFERRAL_SIGNUP_FAILED': 'referral_signup_failed',
  'SIGNUP_FAILED': 'signup_failed',
  'SIGNUP_COMPLETED': 'signup_completed',
  'REFERRAL_SIGNUP_COMPLETED': 'referral_signup_completed',
  'CREDENTIALS_SIGNUP_COMPLETED': 'credentials_signup_completed',
  'USER_REQUESTED_RESET_PASSWORD': 'user_requested_reset_password',
  'REFERRAL_LINK_COPIED': 'referral_link_copied',
  'SEND_REFERRAL_EMAIL_SELECTED': 'send_referral_email_selected',
  'REFERRAL_QR_CODE_SELECTED': 'referral_qr_code_selected',
  'FRIENDS_AND_FAMILY_REFERRAL_QR_CODE_SELECTED': 'friends_and_family_referral_qr_code_selected',
  'FRIENDS_AND_FAMILY_REFERRAL_LINK_COPIED': 'friends_and_family_referral_link_copied',
  'CONTACT_SUPPORT_SELECTED': 'contact_support_selected',
  'OPEN_DEVICE_SETTINGS_SELECTED': 'open_device_settings_selected',
  'PULSE_NOTIFICATIONS_DISABLED': 'pulse_notifications_disabled',
  'PULSE_NOTIFICATIONS_ENABLED': 'pulse_notifications_enabled',
  'RESEND_VERIFICATION_EMAIL_SELECTED': 'resend_verification_email_selected',
  'TERMS_AND_CONDITIONS_SELECTED': 'terms_and_conditions_selected',
  'BENEFITS_CONNECT_VIEWED': 'benefits_connect_viewed',
  'BENEFITS_CONNECT_SELECTED': 'benefits_connect_selected',
  'DCI_SUBMITTED': 'dci_submitted',
  'DCI_SERVED': 'dci_served',
  'JOURNEY_CHANGED': 'journey_changed',
  'NEW_JOURNEY_STARTED': 'new_journey_started',
  'JOURNEY_LEVEL_COMPLETED': 'journey_level_completed',
  'JOURNEY_MICROSTEP': 'journey_microstep',
  'JOURNEY_VIDEO_COMPLETED': 'journey_video_completed',
  'JOURNEY_VIDEO_STARTED': 'journey_video_started',
  'RECOMMENDED_CONTENT_STARTED': 'recommended_content_started',
  'POPULAR_CONTENT_STARTED': 'popular_content_started',
  'RECOMMENDED_CONTENT_SERVED': 'recommended_content_served',
  'GARDEN_PLANTED': 'garden_planted',
  'PLANT_PURCHASED': 'plant_purchased',
  'PLANT_WATERED': 'plant_watered',
  'ACHIEVEMENT_REWARD_EMAIL_SENT': 'achievement_reward_email_sent',
  'DAILY_ACTIVITY_COMPLETED': 'daily_activity_completed',
  'URL_COPIED': 'url_copied',
  'EMAIL_SENT': 'email_sent',
  'DCI_PREFERENCE_VIEWED': 'dci_preference_viewed',
  'DCI_PREFERENCE_SELECTED': 'dci_preference_selected',
  'MANAGE_DEVICES_SELECTED': 'manage_devices_selected',
  'CHALLENGE_ACTIVITY_VIEWED': 'challenge_activity_viewed',
  'DEVICE_PRIVACY_INFO_SELECTED': 'device_privacy_info_selected',
  'WELLBEING_APP_SETUP_COMPLETED': 'wellbeing_app_setup_completed',
  'POPULAR_CONTENT_SERVED': 'popular_content_served',
  'POPULAR_CONTENT_SELECTED': 'popular_content_selected',
  'RECOMMENDED_CONTENT_SELECTED': 'recommended_content_selected',
  'THRIVE_GUIDE_SELECTED': 'thrive_guide_selected',
  'RESET_RECOMMENDATION_OPTION_SELECTED': 'reset_recommendation_option_selected',
  'EXPLANATION_SELECTED': 'explanation_selected',
  'MODULE_SERVED': 'module_served',
  'STRENGTH_DETAIL_SELECTED': 'strength_detail_selected',
  'TIMEZONE_UPDATED': 'timezone_updated',
  'LANGUAGE_UPDATED': 'language_updated',
  'LINKEDIN_CERTIFICATE_ADDED': 'linkedin_certificate_added',
  'AUDIO_BOOK_CHAPTER_PART_STARTED': 'audio_book_chapter_part_started',
  'COMPANY_RESOURCE_STARTED': 'company_resource_started',
  'COURSE_LESSON_STARTED': 'course_lesson_started',
  'COURSE_MODULE_STARTED': 'course_module_started',
  'COURSE_STARTED': 'course_started',
  'PODCAST_EPISODE_STARTED': 'podcast_episode_started',
  'PODCAST_SEASON_STARTED': 'podcast_season_started',
  'PODCAST_STARTED': 'podcast_started',
  'WEBINAR_SESSION_STARTED': 'webinar_session_started',
  'WEBINAR_SESSION_VIDEO_STARTED': 'webinar_session_video_started',
  'ACHIEVEMENT_CLAIMED': 'achievement_claimed',
  'DCI_SUBSCRIBED': 'dci_subscribed',
  'ARTICLE_VIEWED': 'article_viewed',
  'TODAY_RESET_EXPLORE_MORE_CLICKED': 'today_reset_explore_more_clicked',
  'ONBOARDING_COMPLETED': 'onboarding_completed',
  'TODAY_RESET_STARTED': 'today_reset_started',
  'TODAY_RESET_COMPLETED': 'today_reset_completed',
  'TODAY_RESET_SELECTED': 'today_reset_selected',
  'VISIT_RESET_SELECTED': 'visit_reset_selected',
  'ONBOARDING_ASSESSMENT_SERVED': 'onboarding_assessment_served',
  'ONBOARDING_RESPONSE_SUBMITTED': 'onboarding_response_submitted',
  'ONBOARDING_ASSESSMENT_COMPLETED': 'onboarding_assessment_completed',
  'ONBOARDING_ASSESSMENT_STARTED': 'onboarding_assessment_started',
  'RECOMMENDED_JOURNEY_STARTED': 'recommended_journey_started',
  'ONBOARDING_EMPATHIZE_SELECTED': 'onboarding_empathize_selected',
  'ONBOARDING_EMPATHIZE_SKIPPED': 'onboarding_empathize_skipped',
  'ONBOARDING_INTRODUCE_SELECTED': 'onboarding_introduce_selected',
  'ONBOARDING_INTRODUCE_SKIPPED': 'onboarding_introduce_skipped',
  'ONBOARDING_VALIDATE_SELECTED': 'onboarding_validate_selected',
  'ONBOARDING_VALIDATE_SKIPPED': 'onboarding_validate_skipped',
  'ONBOARDING_EXPECTATIONS_SELECTED': 'onboarding_expectations_selected',
  'ONBOARDING_CELEBRATE_SELECTED': 'onboarding_celebrate_selected',
  'ONBOARDING_REINFORCE_SELECTED': 'onboarding_reinforce_selected',
  'NPS_SERVED': 'nps_served',
  'NPS_SUBMITTED': 'nps_submitted',
  'PLANT_VIEWED': 'plant_viewed',
  'PLANT_SHOP_VIEWED': 'plant_shop_viewed',
  'MICROSTEP_VIEWED': 'microstep_viewed',
  'RESET_VIEWED': 'reset_viewed',
  'DCI_VIEWED': 'dci_viewed',
  'DCI_UNSUBSCRIBED': 'dci_unsubscribed',
  'CHATBOT_USER_RESPONDED': 'chatbot_user_responded',
  'JOURNEY_VIEWED': 'journey_viewed',
  'COMPANY_BANNER_VIEWED': 'company_banner_viewed',
  'COMPANY_BANNER_SELECTED': 'company_banner_selected',
  'RECOMMENDED_JOURNEY_SERVED': 'recommended_journey_served',
  'ACCOUNT_DELETE_REQUESTED': 'account_delete_requested',
  'ACCOUNT_DELETE_CANCELLED': 'account_delete_cancelled',
  'ACCOUNT_DELETE_CONFIRMED': 'account_delete_confirmed',
  'LIKE_SUBMITTED': 'like_submitted',
  'HEART_SUBMITTED': 'heart_submitted',
  'LAUGH_SUBMITTED': 'laugh_submitted',
  'SURPRISED_SUBMITTED': 'surprised_submitted',
  'OTHER_SUBMITTED': 'other_submitted',
  'RESET_WATCH_NEXT_SELECTED': 'reset_watch_next_selected',
  'RESET_CREATION_COMPLETED': 'reset_creation_completed',
  'TERM_SEARCHED': 'term_searched',
  'SEARCH_RESULT_SELECTED': 'search_result_selected',
  'SEARCH_RESULT_COPIED': 'search_result_copied',
  'SEARCH_PROMPT_SELECTED': 'search_prompt_selected',
  'SHOW_MORE_SELECTED': 'show_more_selected',
  'AUDIO_RESET_STARTED': 'audio_reset_started',
  'AUDIO_RESET_SELECTED': 'audio_reset_selected',
  'AUDIO_RESET_COMPLETED': 'audio_reset_completed',
  'SEARCH_SELECTED': 'search_selected',
  'AUDIO_RESET_SHARED': 'audio_reset_shared',
  'PERSONAL_RESET_SHARED': 'personal_reset_shared',
  'SHARED_MESSAGE_VIEWED': 'shared_message_viewed',
  'CONNECT_DEVICE_SELECTED': 'connect_device_selected',
  'DISCONNECT_DEVICE_SELECTED': 'disconnect_device_selected',
  'PRIVACY_POLICY_SELECTED': 'privacy_policy_selected',
  'JOURNEY_RESOURCE_SELECTED': 'journey_resource_selected',
  'WATER_PLANT_SELECTED': 'water_plant_selected',
  'WATCH_RESET_SELECTED': 'watch_reset_selected',
  'COMPLETE_MICROSTEP_SELECTED': 'complete_microstep_selected',
  'PLANT_STARTED': 'plant_started',
  'ALL_JOURNEYS_COMPLETED': 'all_journeys_completed',
  'JOURNEY_COMPLETED': 'journey_completed',
  'DEFAULT_RECOMMENDED_JOURNEY_STARTED': 'default_recommended_journey_started',
  'CLAIM_ACHIEVEMENTS_SELECTED': 'claim_achievements_selected',
  'LIVESHARE_RESET_STARTED': 'liveshare_reset_started',
  'LIVESHARE_RESET_SHARED': 'liveshare_reset_shared',
  'TST_RESET_SERVED': 'tst_reset_served',
  'TST_RESET_STARTED': 'tst_reset_started',
  'TST_RESET_COMPLETED': 'tst_reset_completed',
  'TST_ISSUE_REPORTED': 'tst_issue_reported',
  'TST_FEEDBACK_SUBMITTED': 'tst_feedback_submitted',
  'CONFIGURATIONS_CREATE_SELECTED': 'configurations_create_selected',
  'CONFIGURATIONS_DELETE_SELECTED': 'configurations_delete_selected',
  'CONFIGURATIONS_TEST_SELECTED': 'configurations_test_selected',
  'CONFIGURATIONS_TOGGLE_CALLS_SELECTED': 'configurations_toggle_calls_selected',
  'CONFIGURATIONS_UPDATE_LIMIT_SELECTED': 'configurations_update_limit_selected',
  'CONFIGURATIONS_UPDATE_SELECTED': 'configurations_update_selected',
  'REPORTING_PAGE_AGENT_SELECTED': 'reporting_page_agent_selected',
  'REPORTING_PAGE_REPORT_DOWNLOADED': 'reporting_page_report_downloaded',
  'REPORTING_PAGE_SELECTED': 'reporting_page_selected',
  'UPDATE_AGENT_ENGAGEMENT_REPORT_SELECTED': 'update_agent_engagement_report_selected',
  'UPDATE_AGENT_STATS_REPORT_SELECTED': 'update_agent_stats_report_selected',
  'UPDATE_REPORT_SELECTED': 'update_report_selected',
  'JOIN_INVITE_SENT': 'join_invite_sent',
  'TEAM_INVITE_SENT': 'team_invite_sent',
  'MORE_MICROSTEPS_SELECTED': 'more_microsteps_selected',
  'EXPLORE_SELECTED': 'explore_selected',
  'MICROSTEP_REMINDER_SELECTED': 'microstep_reminder_selected',
  'AI_RECOMMENDED_MICROSTEP': 'ai_recommended_microstep',
  'REPORTING_PAGE_SEND_RESET': 'reporting_page_send_reset',
  'DEFAULT_RECOMMENDED_JOURNEY_SERVED': 'default_recommended_journey_served',
  'ONBOARDING_ASSESSMENT_SKIPPED': 'onboarding_assessment_skipped',
  'LIBRARY_RESOURCE_SELECTED': 'library_resource_selected',
  'SHOW_ME_SOMETHING_ELSE_SELECTED': 'show_me_something_else_selected',
  'ONBOARDING_RESOURCE_SELECTED': 'onboarding_resource_selected',
  'APP_INSTALLED': 'app_installed',
  'ONBOARDING_MICROSTEP_CHECKIN_COMPLETED': 'onboarding_microstep_checkin_completed',
  'MICROSTEP_AUTO_CHECKED_IN': 'microstep_auto_checked_in',
  'MICROSTEP_LIKE_SUBMITTED': 'microstep_like_submitted',
  'MICROSTEP_DISLIKE_SUBMITTED': 'microstep_dislike_submitted',
  'RESET_RECOMMENDATION_OPTIONS_SELECTED': 'reset_recommendation_options_selected',
  'RESET_THEME_RECOMMENDATION_OPTIONS_SELECTED': 'reset_theme_recommendation_options_selected',
  'RESET_CREATION_OPTIONS_SELECTED': 'reset_creation_options_selected',
  'KEYCLOAK_USER_LOGGED_IN': 'keycloak_user_logged_in',
  'KEYCLOAK_USER_REGISTERED': 'keycloak_user_registered',
  'RESOURCE_VIEWED': 'resource_viewed',
  'DCI_EXPLANATION_SELECTED': 'dci_explanation_selected',
  'TRO_PREFERENCE_VIEWED': 'tro_preference_viewed',
  'TRO_SUBSCRIBED': 'tro_subscribed',
  'TRO_UNSUBSCRIBED': 'tro_unsubscribed',
  'DCI_BLOCKED': 'dci_blocked',
  'JOURNEY_RESOURCE_SERVED': 'journey_resource_served',
  'RESET_REMINDER_SELECTED': 'reset_reminder_selected',
  'RESET_REMINDER_SET': 'reset_reminder_set',
  'DAILY_GOALS_ALL_COMPLETED': 'daily_goals_all_completed',
  'RESET_SELECTED': 'reset_selected',
  'RESET_DEFERRED': 'reset_deferred',
  'JOURNEY_VIDEO_SERVED': 'journey_video_served',
  'MICROSTEP_REMINDER_SET': 'microstep_reminder_set',
  'MICROSTEP_SERVED': 'microstep_served',
  'FEEDBACK_MODULE_SELECTED': 'feedback_module_selected',
  'JOURNEY_SELECTION_SERVED': 'journey_selection_served',
  'CHALLENGE_VIEWED': 'challenge_viewed',
  'CHANNEL_RESET_SELECTED': 'channel_reset_selected',
  'PRIVATE_RESET_SELECTED': 'private_reset_selected',
  'SHARE_RESET_SELECTED': 'share_reset_selected',
  'REPLAY_RESET': 'replay_reset',
  'NOTIFICATIONS_OPENED': 'notifications_opened',
  'ACHIEVEMENTS_OPENED': 'achievements_opened',
  'VIEW_MORE_BADGES_SELECTED': 'view_more_badges_selected',
  'BADGE_DETAILS_VIEWED': 'badge_details_viewed',
  'MAKE_BADGE_PROGRESS_SELECTED': 'make_badge_progress_selected',
  'TAB_SELECTED': 'tab_selected',
  'CHALLENGE_GOAL_SELECTED': 'challenge_goal_selected',
  'CHALLENGE_PARTICIPATION_SELECTED': 'challenge_participation_selected',
  'DCI_SELECTED': 'dci_selected',
  'SCHEDULED_MICROSTEP': 'scheduled_microstep',
  'RECOMMENDED_MICROSTEP': 'recommended_microstep',
  'FEEDBACK_MODULE_SERVED': 'feedback_module_served',
  'PERSONAL_RESET_EDIT_SELECTED': 'personal_reset_edit_selected',
  'PERSONAL_RESET_EDIT_COMPLETED': 'personal_reset_edit_completed',
  'PERSONAL_RESET_EDIT_CANCELLED': 'personal_reset_edit_cancelled',
  'PERSONAL_RESET_MEDIA_UPLOADED': 'personal_reset_media_uploaded',
  'CONTENT_CREATION_CANCELLED': 'content_creation_cancelled',
  'GET_ANOTHER_RESET_SELECTED': 'get_another_reset_selected',
  'RESET_CANCELLED': 'reset_cancelled',
  'GET_ANOTHER_MICROSTEP_SELECTED': 'get_another_microstep_selected',
  'MICROSTEP_CANCELLED': 'microstep_cancelled',
  'NAVIGATE_TO_JOURNEY_SELECTED': 'navigate_to_journey_selected',
  'COMPLETE_CHALLENGE_SELECTED': 'complete_challenge_selected',
  'RESET_NOTIFICATION_SELECTED': 'reset_notification_selected',
  'JOURNEY_MICROSTEP_NOTIFICATION_SELECTED': 'journey_microstep_notification_selected',
  'YESTERDAY_JOURNEY_MICROSTEP': 'yesterday_journey_microstep',
  'CHALLENGE_GOAL_NOTIFICATION_SELECTED': 'challenge_goal_notification_selected',
  'REQUESTED_CHALLENGE_SERVED': 'requested_challenge_served',
  'RESET_NOTIFICATION_SERVED': 'reset_notification_served',
  'CHALLENGE_GOAL_NOTIFICATION_SERVED': 'challenge_goal_notification_served',
  'RESET_NOTIFICATION_DEFERRED': 'reset_notification_deferred',
  'MICROSTEP_HUB_DATES_SELECTED': 'microstep_hub_dates_selected',
  'MICROSTEP_EXPAND_SELECTED': 'microstep_expand_selected',
  'MICROSTEP_HUB_SORTING': 'microstep_hub_sorting',
  'MICROSTEP_TOPIC_FILTERING': 'microstep_topic_filtering',
  'MICROSTEP_FAVORITES_SORTING': 'microstep_favorites_sorting',
  'MICROSTEP_BROWSE_SORTING': 'microstep_browse_sorting',
  'REPLAY_RESET_SELECTED': 'replay_reset_selected',
  'PROGRESS_RESPONSE_SUBMITTED': 'progress_response_submitted',
  'PROGRESS_ASSESSMENT_SERVED': 'progress_assessment_served',
  'PROGRESS_ASSESSMENT_COMPLETED': 'progress_assessment_completed',
  'PROGRESS_ASSESSMENT_STARTED': 'progress_assessment_started',
  'PROGRESS_ASSESSMENT_DEFERRED': 'progress_assessment_deferred',
  'RESET_NOTIFICATION_UNSUBSCRIBED': 'reset_notification_unsubscribed',
  'RESET_NOTIFICATION_SUBSCRIBED': 'reset_notification_subscribed',
  'ACCESSIBLE_SKIP_NAV_SERVED': 'accessible_skip_nav_served',
  'ACCESSIBLE_SKIP_NAV_SELECTED': 'accessible_skip_nav_selected',
  'CHALLENGE_SIGNUP_STARTED': 'challenge_signup_started',
  'CHALLENGE_SIGNUP_COMPLETED': 'challenge_signup_completed',
  'SHARE_CHALLENGE_SELECTED': 'share_challenge_selected',
  'VIEW_CHALLENGE_SELECTED': 'view_challenge_selected',
  'COMPLETE_GOALS_SELECTED': 'complete_goals_selected',
  'VIEW_ALL_CHALLENGES_SELECTED': 'view_all_challenges_selected',
  'CHALLENGE_TEAM_CREATED': 'challenge_team_created',
  'JOIN_CHALLENGE_SELECTED': 'join_challenge_selected',
  'CHALLENGE_SERVED': 'challenge_served',
  'PRIVACY_POLICY_LEFT': 'privacy_policy_left',
  'COMPLETE_GOALS_PROMPT_SERVED': 'complete_goals_prompt_served',
  'BROWSER_NOTIFICATIONS_DECLINED': 'browser_notifications_declined',
  'MANAGE_BROWSER_NOTIFICATIONS_SELECTED': 'manage_browser_notifications_selected',
  'BROWSER_NOTIFICATION_SERVED': 'browser_notification_served',
  'BROWSER_NOTIFICATION_SELECTED': 'browser_notification_selected',
  'BROWSER_NOTIFICATIONS_TOGGLE_SERVED': 'browser_notifications_toggle_served',
  'BROWSER_NOTIFICATIONS_DEFERRED': 'browser_notifications_deferred',
  'BROWSER_NOTIFICATIONS_SUBSCRIBED': 'browser_notifications_subscribed',
  'ALLOW_DESKTOP_NOTIFICATIONS_SERVED': 'allow_desktop_notifications_served',
  'ALLOW_BROWSER_NOTIFICATIONS_SERVED': 'allow_browser_notifications_served',
  'DESKTOP_NOTIFICATIONS_SUBSCRIBED': 'desktop_notifications_subscribed',
  'DCI_PREFERENCE_OPENED': 'dci_preference_opened',
  'CHALLENGE_SHARED': 'challenge_shared',
  'DCI_PREFERENCE_SERVED': 'dci_preference_served',
  'INSIGHTS_NEW_DAY_ZERO_SERVED': 'insights_new_day_zero_served',
  'MANUAL_ACTIVITY_ADDED': 'manual_activity_added',
  'CLEAR_INTENTION_SELECTED': 'clear_intention_selected',
  'CLOSE_BUTTON_SELECTED': 'close_button_selected',
  'EDIT_INTENTION_SELECTED': 'edit_intention_selected',
  'FOCUS_AREAS_SUGGESTED': 'focus_areas_suggested',
  'GET_STARTED_SELECTED': 'get_started_selected',
  'INTENTION_BANNER_EDIT_SELECTED': 'intention_banner_edit_selected',
  'LANGUAGE_SET': 'language_set',
  'NOT_NOW_SELECTED': 'not_now_selected',
  'PERSONALIZED_PLAN_SELECTED': 'personalized_plan_selected',
  'PRE_SELECTED_RESPONSE': 'pre_selected_response',
  'SUPPORT_SELECTED': 'support_selected',
  'TRY_IT_NOW_SELECTED': 'try_it_now_selected',
  'USER_WRITTEN_RESPONSE': 'user_written_response',
  'PLAY_NEXT_RESET_SELECTED': 'play_next_reset_selected',
  'RESET_AUTOPLAY_TOGGLED_OFF': 'reset_autoplay_toggled_off',
  'RESET_AUTOPLAY_TOGGLED_ON': 'reset_autoplay_toggled_on',
  'RESET_DISLIKE_SUBMITTED': 'reset_dislike_submitted',
  'RESET_LIKE_SUBMITTED': 'reset_like_submitted',
  'RECIPE_SELECTED': 'recipe_selected',
  'POST_DCI_RESET_SELECTED': 'post_dci_reset_selected',
  'POST_DCI_RESET_VIEWED': 'post_dci_reset_viewed',
  'ONBOARDING_PROMPT_SERVED': 'onboarding_prompt_served',
  'ONBOARDING_STARTED': 'onboarding_started',
  'ACHIEVEMENT_NOTIFICATION_SELECTED': 'achievement_notification_selected',
  'ACHIEVEMENT_NOTIFICATION_SERVED': 'achievement_notification_served',
  'AGREE_AND_GET_STARTED_SELECTED': 'agree_and_get_started_selected',
  'BACK_SELECTED': 'back_selected',
  'CHOOSE_FOR_ME_SELECTED': 'choose_for_me_selected',
  'EDIT_INTENTION_PROMPT_SERVED': 'edit_intention_prompt_served',
  'GET_MY_MICROSTEPS_COMPLETED': 'get_my_microsteps_completed',
  'GET_MY_MICROSTEPS_PROMPT_SERVED': 'get_my_microsteps_prompt_served',
  'GET_MY_MICROSTEPS_SERVED': 'get_my_microsteps_served',
  'INTENTION_SETTING_PROMPT_SERVED': 'intention_setting_prompt_served',
  'KEEP_EXISTING_INTENTION_SELECTED': 'keep_existing_intention_selected',
  'SET_A_NEW_INTENTION_SELECTED': 'set_a_new_intention_selected',
  'LIVESHARE_RESET_COMPLETED': 'liveshare_reset_completed',
  'RESET_VOTE_COMPLETED': 'reset_vote_completed',
  'RESET_VOTE_CREATED': 'reset_vote_created',
  'RESET_VOTE_STARTED': 'reset_vote_started',
  'RESET_VOTE_SUBMITTED': 'reset_vote_submitted',
  'EBOOK_DOWNLOADED': 'ebook_downloaded',
  'WEB_ANNOUNCEMENT_NOTIFICATION_SELECTED': 'web_announcement_notification_selected',
  'WEB_ANNOUNCEMENT_NOTIFICATION_SERVED': 'web_announcement_notification_served',
} as const;
export type ActivityTypeType = typeof ActivityType;
export type ActivityTypeValueType = ActivityTypeType[keyof ActivityTypeType];

export const ResetType = {
  'RESET_BANNER': 'reset_banner',
  'PERSONAL_RESET': 'personal_reset',
  'RESET': 'reset',
  'INTRO_RESET': 'intro_reset',
  'AUDIO_RESET': 'audio_reset',
  'SLACK_HOME_RESET': 'slack_home_reset',
  'TODAYS_RESET': 'todays_reset',
  'LIBRARY_RESET': 'library_reset',
  'BANNER_RESET': 'banner_reset',
  'CHALLENGE_RESET': 'challenge_reset',
  'LIVESHARE_RESET': 'liveshare_reset',
  'COMMAND_RESET': 'command_reset',
  'POST_DCI_RESET': 'post_dci_reset',
  'NUDGE_RESET': 'nudge_reset',
  'HOME_TODAYS_RESET': 'home_todays_reset',
  'RESET_PAGE_RECOMMENDED_RESET': 'reset_page_recommended_reset',
  'RESET_PAGE_RESET': 'reset_page_reset',
  'RESET_PAGE_TODAYS_RESET': 'reset_page_todays_reset',
  'SEARCH_RESET': 'search_reset',
  'SHARED_RESET': 'shared_reset',
} as const;
export type ResetTypeType = typeof ResetType;
export type ResetTypeValueType = ResetTypeType[keyof ResetTypeType];

export const PageSection = {
  'EXPLORE_MORE': 'explore_more',
  'FAVORITES': 'favorites',
  'TRY_NOW': 'try_now',
  'BOOKMARKS': 'bookmarks',
  'FEATURED_ARTICLE': 'featured_article',
  'POPULAR': 'popular',
  'RECOMMENDED': 'recommended',
} as const;
export type PageSectionType = typeof PageSection;
export type PageSectionValueType = PageSectionType[keyof PageSectionType];

export const Client = {
  'NATIVE_IOS': 'NATIVE_IOS',
  'WEB': 'WEB',
  'CALLS': 'CALLS',
  'MS_TEAMS': 'MS_TEAMS',
  'RN_ANDROID': 'RN_ANDROID',
  'NATIVE_ANROID': 'NATIVE_ANROID',
  'RN_IOS': 'RN_IOS',
  'WEBEX': 'WEBEX',
  'WEARABLES': 'WEARABLES',
  'KEYCLOAK': 'KEYCLOAK',
} as const;
export type ClientType = typeof Client;
export type ClientValueType = ClientType[keyof ClientType];

export const FeatureName = {
  'THRIVE_GUIDE': 'thrive_guide',
  'LEARN': 'learn',
} as const;
export type FeatureNameType = typeof FeatureName;
export type FeatureNameValueType = FeatureNameType[keyof FeatureNameType];

export const TstPlatform = {
  'GENESYS': 'genesys',
  'NICE': 'nice',
  'PARTNER_API': 'partner_api',
  'WEBEX': 'webex',
} as const;
export type TstPlatformType = typeof TstPlatform;
export type TstPlatformValueType = TstPlatformType[keyof TstPlatformType];

export const FeatureType = {
  'NPS': 'nps',
  'LEARN_RECOMMENDED_CONTENT': 'learn_recommended_content',
  'LEARN_POPULAR_CONTENT': 'learn_popular_content',
  'THRIVE_RESET_ONLY': 'thrive_reset_only',
  'LIBRARY': 'library',
  'CONTENT': 'content',
  'CHALLENGE': 'challenge',
  'ACHIEVEMENT': 'achievement',
  'THRIVE_GUIDE': 'thrive_guide',
  'COMMUNITY': 'community',
  'IDENTITY': 'identity',
  'SIGNUP': 'signup',
  'PROFILE': 'profile',
  'JOURNEYS': 'journeys',
  'BENEFITS_CONNECT': 'benefits_connect',
  'GARDEN': 'garden',
  'CHAT': 'chat',
  'ASSESSMENT': 'assessment',
  'SEARCH': 'search',
  'SYSTEM': 'system',
  'WEARABLES': 'wearables',
  'NOTIFICATIONS': 'notifications',
  'INSIGHTS': 'insights',
  'INTENTION_SETTING': 'intention_setting',
} as const;
export type FeatureTypeType = typeof FeatureType;
export type FeatureTypeValueType = FeatureTypeType[keyof FeatureTypeType];

export const FocusAreas = {
  'CONNECT': 'CONNECT',
  'FOCUS': 'FOCUS',
  'FOOD': 'FOOD',
  'MONEY': 'MONEY',
  'MOVE': 'MOVE',
  'RECHARGE': 'RECHARGE',
  'STRESS_MANAGEMENT': 'STRESS_MANAGEMENT',
} as const;
export type FocusAreasType = typeof FocusAreas;
export type FocusAreasValueType = FocusAreasType[keyof FocusAreasType];

export const FeedbackAnswer = {
  'POSITIVE': 'positive',
  'NEGATIVE': 'negative',
} as const;
export type FeedbackAnswerType = typeof FeedbackAnswer;
export type FeedbackAnswerValueType = FeedbackAnswerType[keyof FeedbackAnswerType];

export const ContentFormatType = {
  'TEXT': 'text',
  'AUDIO': 'audio',
  'VIDEO': 'video',
  'PDF': 'pdf',
} as const;
export type ContentFormatTypeType = typeof ContentFormatType;
export type ContentFormatTypeValueType = ContentFormatTypeType[keyof ContentFormatTypeType];

export const TabName = {
  'HOME': 'Home',
  'CHAT': 'Chat',
  'ABOUT': 'About',
} as const;
export type TabNameType = typeof TabName;
export type TabNameValueType = TabNameType[keyof TabNameType];

export const MessageType = {
  'QUESTION': 'question',
  'RECOMMENDATION': 'recommendation',
  'FREE_FORM_TEXT': 'free_form_text',
  'MULTIPLE_CHOICE_RESPONSE': 'multiple_choice_response',
} as const;
export type MessageTypeType = typeof MessageType;
export type MessageTypeValueType = MessageTypeType[keyof MessageTypeType];

export const DciFrequency = {
  'DCI_FREQ_DAILY': 'DCI_FREQ_DAILY',
  'DCI_FREQ_DISABLED': 'DCI_FREQ_DISABLED',
  'DCI_FREQ_PERIODIC': 'DCI_FREQ_PERIODIC',
} as const;
export type DciFrequencyType = typeof DciFrequency;
export type DciFrequencyValueType = DciFrequencyType[keyof DciFrequencyType];

export const RemovalReason = {
  'NOT_RELEVANT': 'not_relevant',
  'NOW_HABIT': 'now_habit',
} as const;
export type RemovalReasonType = typeof RemovalReason;
export type RemovalReasonValueType = RemovalReasonType[keyof RemovalReasonType];

export const TeamType = {
  'INDIVIDUAL': 'individual',
  'TEAM': 'team',
} as const;
export type TeamTypeType = typeof TeamType;
export type TeamTypeValueType = TeamTypeType[keyof TeamTypeType];

let sysAppSource: any;
let sysThriveLocale: any;
let sysSessionId: any;
let sysClient: any;

export function setSystemProperties(
  properties: {appSource: AppSourceValueType; thriveLocale: string;
  sessionId: string; client: ClientValueType}) {
  if (properties.appSource !== null && properties.appSource !== undefined) {
    sysAppSource = properties.appSource;
  }
  if (properties.thriveLocale !== null && properties.thriveLocale !== undefined) {
    sysThriveLocale = properties.thriveLocale;
  }
  if (properties.sessionId !== null && properties.sessionId !== undefined) {
    sysSessionId = properties.sessionId;
  }
  if (properties.client !== null && properties.client !== undefined) {
    sysClient = properties.client;
  }
}

let Segment: any;

export function initAvo(options: {env: AvoEnv; webDebugger?: boolean;
  webDebuggerOptions?: webDebuggerOptions; strict?: boolean; noop?: boolean;
  reportFailureAs?: 'error' | 'warn' | 'log'; inspector?: AvoInspector;
  avoLogger?: AvoLogger}, systemProperties: {appSource: AppSourceValueType;
  thriveLocale: string; sessionId: string; client: ClientValueType},
  destinationOptions: any, SegmentDestination: CustomDestination) {
  if (__AVO_ENV__ !== null) {
    return;
  }
  __AVO_ENV__ = options.env;
  if (options.avoLogger !== undefined) {
    __AVO_LOGGER__ = options.avoLogger;
  }
  if (options.noop === true) {
    __AVO_NOOP__ = true;
  }
  if (__AVO_NOOP__ && __AVO_ENV__ == AvoEnv.Prod) {
    InternalAvoLogger.warn("[avo] ****************************************************");
    InternalAvoLogger.warn("[avo] WARNING Avo cannot be initialized in noop mode in production:");
    InternalAvoLogger.warn("[avo] - Overwriting configuration with noop=false.");
    InternalAvoLogger.warn("[avo] - Please reach out if you want to be able to run Avo in production mode with noop=true");
    InternalAvoLogger.warn("[avo] ****************************************************");
    __AVO_NOOP__ = false;
  }
  if (__AVO_NOOP__) {
    InternalAvoLogger.log("[avo] ****************************************************");
    InternalAvoLogger.log("[avo] Avo is now initialized in noop mode. This means:");
    InternalAvoLogger.log("[avo] - No events will be sent");
    InternalAvoLogger.log("[avo] - No network requests are made");
    InternalAvoLogger.log("[avo] ****************************************************");
  }
  if (options.strict !== undefined) {
    __STRICT__ = options.strict !== false;
  }
  if (options.reportFailureAs !== undefined) {
    __REPORT_FAILURE_AS__ = options.reportFailureAs;
  }
  __WEB_DEBUGGER__ = !__AVO_NOOP__ && ((typeof window !== 'undefined' && (window as any).location.search.indexOf("avo_debug=1") > -1) || (options.webDebugger !== false && __AVO_ENV__ !== AvoEnv.Prod));
  if (!__AVO_NOOP__ && options.inspector !== undefined) {
    __INSPECTOR__ = options.inspector;
  } else if (__AVO_ENV__ !== 'prod') {
    InternalAvoLogger.warn("[avo] Avo Inspector not provided in initAvo() call");
  }

  setSystemProperties(systemProperties);

  destinationOptions = destinationOptions || {};

  if (__WEB_DEBUGGER__ && !__AVO_NOOP__) {
    if (options.webDebuggerOptions?.position) {
      _avo_debugger_send_position(options.webDebuggerOptions.position)
    }

(function() {
  if (typeof (window as any) === 'undefined') { return; }
  let init = function() {
    let iframe: any = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.id = "avo-debugger";
    iframe.src = "https://www.avo.app/_debugger";
    iframe.style = "display: none;";
  };

  if (document.body) {
    init();
  } else {
    document.addEventListener('DOMContentLoaded', init);
  }
})();

  }
  if (!__AVO_NOOP__) {
    if (__AVO_ENV__ === AvoEnv.Prod) {
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
    }

    Segment = SegmentDestination;
    if (__AVO_ENV__ === 'prod') {
      Segment && Segment.make && Segment.make(__AVO_ENV__, null);
    } else if (__AVO_ENV__ === 'dev') {
      Segment && Segment.make && Segment.make(__AVO_ENV__, null);
    } else {
      console[__REPORT_FAILURE_AS__ || 'error']("[avo] No staging key is set for Segment. Head to destination settings in Avo to set a staging key.");
      Segment && Segment.make && Segment.make(__AVO_ENV__, null);
    }
    if (__AVO_ENV__ === AvoEnv.Dev) {
      // debug console in Avo
      _avo_invoke_meta(__AVO_ENV__, 'init', [], 'init');
    }
  }
}

function assertJourneyLevelOptional(journeyLevel: number | null | undefined,
  _label?: string) {
  let messages: AvoAssertMessage[] = [];
  if (journeyLevel !== undefined && journeyLevel !== null) {
    messages = messages.concat(AvoAssert.assertInt("HtZ0qgLqVsP6", _label ? "journey_level" + ': ' + _label : "journey_level", journeyLevel));
    messages = messages.concat(AvoAssert.assertMin("HtZ0qgLqVsP6", _label ? "journey_level" + ": " + _label : "journey_level", 0, journeyLevel));
  }
  return messages;
}

function assertJourneyLevel(journeyLevel: number, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertInt("HtZ0qgLqVsP6", _label ? "journey_level" + ': ' + _label : "journey_level", journeyLevel));
  messages = messages.concat(AvoAssert.assertMin("HtZ0qgLqVsP6", _label ? "journey_level" + ": " + _label : "journey_level", 0, journeyLevel));
  return messages;
}

function assertJourneyDayOptional(journeyDay: number | null | undefined,
  _label?: string) {
  let messages: AvoAssertMessage[] = [];
  if (journeyDay !== undefined && journeyDay !== null) {
    messages = messages.concat(AvoAssert.assertInt("StQnPpWOrG18", _label ? "journey_day" + ': ' + _label : "journey_day", journeyDay));
    messages = messages.concat(AvoAssert.assertMin("StQnPpWOrG18", _label ? "journey_day" + ": " + _label : "journey_day", 0, journeyDay));
  }
  return messages;
}

function assertJourneyDay(journeyDay: number, _label?: string) {
  let messages: AvoAssertMessage[] = [];
  messages = messages.concat(AvoAssert.assertInt("StQnPpWOrG18", _label ? "journey_day" + ': ' + _label : "journey_day", journeyDay));
  messages = messages.concat(AvoAssert.assertMin("StQnPpWOrG18", _label ? "journey_day" + ": " + _label : "journey_day", 0, journeyDay));
  return messages;
}

export function setAvoLogger(avoLogger: AvoLogger | null) {
  __AVO_LOGGER__ = avoLogger;
}

export interface PlantStartedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  plantId: string;
  plantType: string;
}
/**
 * plant_started: Fires when a user adds a plant to their garden.
 *
 * When to trigger this event:
 * 1. feature_type = 'achievement'
activity_type = 'garden_planted'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/ryHA-hhPri/trigger/D_gjORRYu
 * 2. Fires when a user selects to Grow a new plant.
feature_type = 'achievement'
activity_type = 'plant_purchased'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/ryHA-hhPri/trigger/xLNEJq_8w
 * 3. Fires when a user selects a plant to start growing (prompt appears after completing a Journey step).
feature_type = 'achievement'
activity_type = 'plant_started'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/ryHA-hhPri/trigger/kE6Ob2p-u
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.plantId: ID of the plant.
 * @param properties.plantType: The type of plant such as 'plant.community.16'
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/ryHA-hhPri}
 */
export function plantStarted(properties: PlantStartedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "AsQ_hs_MP", name: "plant_id", value: properties.plantId});
  eventPropertiesArray.push({id: "CFLgiMVFr", name: "plant_type", value: properties.plantType});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "ryHA-hhPri", "39e7701b127c99dcb81e7668b6696c22947bc4b8fabc89bf2e084637779661fb", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("plant_started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("ryHA-hhPri", "plant_started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("plant_started", eventProperties, "ryHA-hhPri", "39e7701b127c99dcb81e7668b6696c22947bc4b8fabc89bf2e084637779661fb");
    }
    // destination Segment
    Segment.logEvent("plant_started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ActivityAddedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  challengeId: string;
  dayNumber: number | null | undefined;
  teamId: string | null | undefined;
  teamType: TeamTypeValueType | null | undefined;
  challengeTheme: string | null | undefined;
  challengeType: string | null | undefined;
  activityDate: string;
  durationInMinutes: number;
  stepCount: number;
  numberOfActivities: number;
  manuallyUpdatedSteps: boolean;
  responseText: string;
}
/**
 * activity_added: Triggers when a user logs an activity (i.e. enters the activity information and clicks "Add Activity".
 *
 * When to trigger this event:
 * 1. Fires when a user submits a manual activity entry.
feature_type = 'challenges'
activity_type = 'manual_activity_added'
+ challenge_properties

+ response_text = The activity chosen by the user
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/0XRYTAfjhY/trigger/-Ac-P-nvV
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.challengeId: id of the challenge being completed
 * @param properties.dayNumber: the day number of any event (be it journey level day number, challenge day number etc.)
 * @param properties.teamId: Id of the team the user belongs to. (this is the equivalent of social_group_id)
 * @param properties.teamType: team type for the company challenge events. Values can be 'individual' or 'team'.
 * @param properties.challengeTheme: the type of company challenge (e.g., hydration, movement)
 * @param properties.challengeType: the type of challenge (e.g., journey, biotype, company, group)
 * @param properties.activityDate: The date that the activity is being added/deleted.
 * @param properties.durationInMinutes: The duration in minutes that the activity lasted. For deleting multiple manual activities at once, this would be the sum of all activities that were deleted.
 * @param properties.stepCount: The total Step count for manually added/deleted activities. For multiple additions/deletions, this will be the sum of the steps.
 * @param properties.numberOfActivities: Number of activities that were manually deleted.
 * @param properties.manuallyUpdatedSteps: When a user is adding manual activity, they have an option to edit the calculated step count for their activity (for step-related activities) and duration. If they do edit the number, this will return True. If not, it would be False.
 * @param properties.responseText: The response to an assessment question (e.g. 'Strongly agree|Agree|Neither agree nor disagree|Disagree|Strongly disagree|NULL')
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/0XRYTAfjhY}
 */
export function activityAdded(properties: ActivityAddedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: properties.challengeId});
  properties.dayNumber !== undefined && properties.dayNumber !== null ?
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: properties.dayNumber}) :
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: null});
  properties.teamId !== undefined && properties.teamId !== null ?
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: properties.teamId}) :
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: null});
  properties.teamType !== undefined && properties.teamType !== null ?
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: properties.teamType}) :
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: null});
  properties.challengeTheme !== undefined && properties.challengeTheme !== null ?
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: properties.challengeTheme}) :
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: null});
  properties.challengeType !== undefined && properties.challengeType !== null ?
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: properties.challengeType}) :
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: null});
  eventPropertiesArray.push({id: "Kj-yej1Bw", name: "activity_date", value: properties.activityDate});
  eventPropertiesArray.push({id: "jQ9A1fcOG", name: "duration_in_minutes", value: properties.durationInMinutes});
  eventPropertiesArray.push({id: "GVf0TLVTi", name: "step_count", value: properties.stepCount});
  eventPropertiesArray.push({id: "2lI43Twr1", name: "number_of_activities", value: properties.numberOfActivities});
  eventPropertiesArray.push({id: "wfhaq50Xm", name: "manually_updated_steps", value: properties.manuallyUpdatedSteps});
  eventPropertiesArray.push({id: "32zgCY_PY", name: "response_text", value: properties.responseText});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "0XRYTAfjhY", "12909d7693a8ee79dde7916feff486c1724cb94adff2b709cd948ea3614593f2", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("activity_added", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("0XRYTAfjhY", "activity_added", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("activity_added", eventProperties, "0XRYTAfjhY", "12909d7693a8ee79dde7916feff486c1724cb94adff2b709cd948ea3614593f2");
    }
    // destination Segment
    Segment.logEvent("activity_added", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FeedbackSubmittedProperties {
  feedbackAnswer: FeedbackAnswerValueType;
  feedbackQuestion: string;
  openAnswer: string;
  openQuestion: string;
  conversationId: string;
  messageType: MessageTypeValueType;
  transactionId: string;
  contentId: string | null | undefined;
  contentType: ContentTypeValueType | null | undefined;
  contentTitle: string | null | undefined;
  contentSubtype: ContentSubtypeValueType | null | undefined;
  contentFormatType: ContentFormatTypeValueType | null | undefined;
  contentUrl: string | null | undefined;
  contentSource: ContentSourceValueType | null | undefined;
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  tstPlatform: TstPlatformValueType | null | undefined;
  locale: string;
}
/**
 * feedback_submitted: When a user submits feedback
 *
 * @param properties the properties associatied with this event
 * @param properties.feedbackAnswer: Captures feedback submitted by the user. Currently is only set up to take values 'positive' and 'negative' .
 * @param properties.feedbackQuestion: The feedback question shared with a user. User's response to this question is captured in the feedback_asnwer property
 * @param properties.openAnswer: Open Ended Question Answer (user input in free form text) - used in surveys with open-ended question
 * @param properties.openQuestion: Open Ended question shared with a user. User's response to this question is captured in the open_answer property
 * @param properties.conversationId: For the thrive guide: This is the conversation 'session' id from the thrive guide bot.
 * @param properties.messageType: When the chatbot interacts with the user, it can have two types of text messages: question or a recommendation.

When the user interacts with the chatbot, there are 2 types of messages: free-form text response, multiple choice response
 * @param properties.transactionId: For the a chatbot events, this id will join each conversation with its corresponding BE data.
 * @param properties.contentId: Recommended Content ID including external resources
 * @param properties.contentType: The type of content (reset, video, article etc.) in English
 * @param properties.contentTitle: The title of the content (Resets, Articles, Videos etc.) including external resources as well
 * @param properties.contentSubtype: Secondary type based on the content type:
Articles: role_model | recipe | watch_me_thrive | article

 * @param properties.contentFormatType: The format of the content displayed/consumed.

Articles: audio | video | text
Resets: audio | video
External resources: pdf | audio | video | text
 * @param properties.contentUrl: This is the URL of the content:
- If it is an iframe rendered - it would be the iframe full URL
- If it is an external resource (such as benefit connect / sequoia links) - it would be the external full URL

example:
https://thriveglobal.showpad.com/share/cpCAwJwBjxiFfegfWnIHj
https://login.sequoia.com/
 * @param properties.contentSource: Describes the source of the content or elements within the content (UMG audio for personal resets for example)
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.tstPlatform: The call center platform upon which TST Resets are served
 * @param properties.locale: User's locale
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Ui-3JIvLDU}
 */
export function feedbackSubmitted(properties: FeedbackSubmittedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "frOxH-fc-", name: "feedback_answer", value: properties.feedbackAnswer});
  eventPropertiesArray.push({id: "xO23kcNWB", name: "feedback_question", value: properties.feedbackQuestion});
  eventPropertiesArray.push({id: "K6V8-4Smq", name: "open_answer", value: properties.openAnswer});
  eventPropertiesArray.push({id: "hb-D3dMaN", name: "open_question", value: properties.openQuestion});
  eventPropertiesArray.push({id: "RwgnWaMMW", name: "conversation_id", value: properties.conversationId});
  eventPropertiesArray.push({id: "pcbtFdgiJ", name: "message_type", value: properties.messageType});
  eventPropertiesArray.push({id: "Mi1dgAO5M", name: "transaction_id", value: properties.transactionId});
  properties.contentId !== undefined && properties.contentId !== null ?
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: properties.contentId}) :
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: null});
  properties.contentType !== undefined && properties.contentType !== null ?
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: properties.contentType}) :
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: null});
  properties.contentTitle !== undefined && properties.contentTitle !== null ?
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: properties.contentTitle}) :
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: null});
  properties.contentSubtype !== undefined && properties.contentSubtype !== null ?
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: properties.contentSubtype}) :
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: null});
  properties.contentFormatType !== undefined && properties.contentFormatType !== null ?
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: properties.contentFormatType}) :
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: null});
  properties.contentUrl !== undefined && properties.contentUrl !== null ?
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: properties.contentUrl}) :
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: null});
  properties.contentSource !== undefined && properties.contentSource !== null ?
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: properties.contentSource}) :
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: null});
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.tstPlatform !== undefined && properties.tstPlatform !== null ?
    eventPropertiesArray.push({id: "LSAAiy9CP", name: "tst_platform", value: properties.tstPlatform}) :
    eventPropertiesArray.push({id: "LSAAiy9CP", name: "tst_platform", value: null});
  eventPropertiesArray.push({id: "7Vr2ItMuZvNE", name: "locale", value: properties.locale});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Ui-3JIvLDU", "d924de060d5373e82022c7886ba8b4f97909ed7169b426987f25d7d6c9d1d184", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("feedback_submitted", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Ui-3JIvLDU", "feedback_submitted", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("feedback_submitted", eventProperties, "Ui-3JIvLDU", "d924de060d5373e82022c7886ba8b4f97909ed7169b426987f25d7d6c9d1d184");
    }
    // destination Segment
    Segment.logEvent("feedback_submitted", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ResponseSubmittedProperties {
  conversationId: string | null | undefined;
  messageType: MessageTypeValueType;
  transactionId: string | null | undefined;
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  featureName: FeatureNameValueType;
}
/**
 * response_submitted: When a user submits a response to the app as part of a conversation with thrive guide. Not related to feedback/reactions.
 *
 * When to trigger this event:
 * 1. When a user submits a response to thrive guide bot

feature_type: thrive_guide
activity_type: thrive_guide_user_responded
message_type: free_form_text | multiple_choice_response
conversation_id: <session id for this conversation>
transaction_id: <response transaction unique id>
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/fMMlOOlmvy/trigger/H_ZIAlQoq
 * 2. When a user submits a response to a chatbot
feature_type: chat
activity_type: chatbot_user_responded
message_type: free_form_text | multiple_choice_response
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/fMMlOOlmvy/trigger/K-MFBcAPL
 *
 * @param properties the properties associatied with this event
 * @param properties.conversationId: For the thrive guide: This is the conversation 'session' id from the thrive guide bot.
 * @param properties.messageType: When the chatbot interacts with the user, it can have two types of text messages: question or a recommendation.

When the user interacts with the chatbot, there are 2 types of messages: free-form text response, multiple choice response
 * @param properties.transactionId: For the a chatbot events, this id will join each conversation with its corresponding BE data.
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.featureName: This property tracks which product feature the event would be associated with. Eg: Challenges, Journeys, Thrive Guide etc.
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/fMMlOOlmvy}
 */
export function responseSubmitted(properties: ResponseSubmittedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.conversationId !== undefined && properties.conversationId !== null ?
    eventPropertiesArray.push({id: "RwgnWaMMW", name: "conversation_id", value: properties.conversationId}) :
    eventPropertiesArray.push({id: "RwgnWaMMW", name: "conversation_id", value: null});
  eventPropertiesArray.push({id: "pcbtFdgiJ", name: "message_type", value: properties.messageType});
  properties.transactionId !== undefined && properties.transactionId !== null ?
    eventPropertiesArray.push({id: "Mi1dgAO5M", name: "transaction_id", value: properties.transactionId}) :
    eventPropertiesArray.push({id: "Mi1dgAO5M", name: "transaction_id", value: null});
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "GoBV5T_8bi", name: "feature_name", value: properties.featureName});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "fMMlOOlmvy", "f63b0cdb14f014bcb78e40d0de3c954bea4b1d0b80ec75e95128b3c814eca519", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("response_submitted", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("fMMlOOlmvy", "response_submitted", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("response_submitted", eventProperties, "fMMlOOlmvy", "f63b0cdb14f014bcb78e40d0de3c954bea4b1d0b80ec75e95128b3c814eca519");
    }
    // destination Segment
    Segment.logEvent("response_submitted", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ContentStartedProperties {
  contentId: string | null | undefined;
  contentType: ContentTypeValueType | null | undefined;
  contentTitle: string | null | undefined;
  contentSubtype: ContentSubtypeValueType | null | undefined;
  contentFormatType: ContentFormatTypeValueType | null | undefined;
  contentUrl: string | null | undefined;
  contentSource: ContentSourceValueType | null | undefined;
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  challengeId: string | null | undefined;
  dayNumber: number | null | undefined;
  teamId: string | null | undefined;
  teamType: TeamTypeValueType | null | undefined;
  challengeTheme: string | null | undefined;
  challengeType: string | null | undefined;
  conversationId: string | null | undefined;
  messageType: MessageTypeValueType | null | undefined;
  transactionId: string | null | undefined;
  resetType: ResetTypeValueType | null | undefined;
  tabName: TabNameValueType | null | undefined;
  tstPlatform: TstPlatformValueType | null | undefined;
}
/**
 * content_started: When a user begins consuming a piece of content or a content package (e.g. a Learn course). This can be used for any text, audio, or visual content, as well as signifying a lesson/course/season/etc. completion.

For a text article, this would trigger when the user clicks on the content.
For video/audio content, this would fire when the user selects "Play".
For a course, this would fire when the user first starts their module/lesson/course/etc.

Examples of the start criteria:
- Resets: when a user starts playing a Reset
- Articles: when a user clicks on the article link
- Videos: when a user starts playing a video (journey videos, learn content etc.)
- Learn Module: when a user plays a module
- Learn Lesson: when a user plays a module in a new Lesson
- Learn Course: when a user plays a module in a new Course
 *
 * When to trigger this event:
 * 1. For all different content_types:

feature_type = thrive_guide
activity_type = thrive_guide_recommendation
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/eVX15aznJ
 * 2. When user clicks on the articles in the Library
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/zNfRXaT9W
 * 3. Fires when a user clicks to Play a video article.
feature_type = 'library'
activity_type = 'article_video_started'
content_type = 'article'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/VkOHx-fZ4
 * 4. Fires when a user selects to start an audiobook chapter part.
feature_type = 'library'
activity_type = 'audio_book_chapter_part_started'
content_type = 'audio_book_chapter_part'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/E_F9QX0ts
 * 5. This event fires when a user selects a resource from the company resource tab in Library.
feature_type = 'library'
activity_type = 'company_resource_started'
content_type = 'company_resource'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/8PZtHvFBV
 * 6. Fires when a user first starts a new Learn lesson.
feature_type = 'library'
activity_type = 'course_lesson_started'
content_type = 'lesson'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/VrBpF_30v
 * 7. Fires when a user first starts a new Learn Module.
feature_type = 'library'
activity_type = 'course_module_started'
content_type = 'module'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/BR6YD0rOa
 * 8. Fires when a user first starts a new Learn Course.
feature_type = 'library'
activity_type = 'course_started'
content_type = 'course'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/3mNOuu03c
 * 9. Fires when a user first starts a new Podcast.
feature_type = 'library'
activity_type = 'podcast_started'
content_type = 'podcast'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/lnW6V5DTT
 * 10. Fires when a user first starts a new Podcast Season.
feature_type = 'library'
activity_type = 'podcast_season_started'
content_type = 'podcast_season'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/-8UdR0VLQ
 * 11. Fires when a user first starts a Podcast Episode
feature_type = 'library'
activity_type = 'podcast_episode_started'
content_type = 'podcast_episode'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/tKuiTwJQe
 * 12. Fires when a user first starts a Webinar session video.
feature_type = 'library'
activity_type = 'webinar_session_video_started'
content_type = 'webinar'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/296zs0994
 * 13. Fires when a user starts a Webinar session.
feature_type = 'library'
activity_type = 'webinar_session_started'
content_type = 'webinar'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/s3ISbYPh1
 * 14. Fires when a user starts a related video article.
feature_type = 'library'
activity_type = 'article_started'
content_type = 'article'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/GdVAEpLMp
 * 15. Fires when a user clicks to watch the Reset banner from their library page.
feature_type: content
activity_type: reset_started
content_type: reset

If content_type is reset, reset_type should be populated based on the enums (personal_reset, reset, reset_banner)
reset_type: reset_banner
content_format_type: video | audio
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/43dnlOwVx
 * 16. Fires when a user clicks to start a reset. Video/Audio automatically starts.
feature_type: content
activity_type: reset_started
content_type: reset

If content_type is reset, reset_type should be populated based on the enums (personal_reset, reset, reset_banner)
reset_type: reset
content_format_type: video | audio
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/hyjTgjC6v
 * 17. Fires when a user triggers a Personal Reset play.
feature_type = 'content'
activity_type = 'reset_started'
content_type = 'reset'

If content_type is reset, reset_type should be populated based on the enums (personal_reset, reset, reset_banner)
reset_type: personal_reset
content_format_type: video
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/AGA64ofYB
 * 18. when a user clicks to access the article recommended in a company challenge goal

feature_type: challenge
activity_type: daily_activity_completed

add the content property bundle to get article metadata:
content_id:
content_title:
content_type:

add challenge property bundle:
challenge_id
challenge_name
challenge_type
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/41Vh6LH8m
 * 19. feature_type = journeys
activity_type = journey_video_started
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/095bZLTDJ
 * 20. feature_type = library
activity_type = recommended_content_started
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/IQYRP4gWq
 * 21. feature_type = library
activity_type = popular_content_started
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/Uq1s3qdqr
 * 22. Fires when a user starts playing a reset video from the daily goals company challenge pages

feature_type: challenge
activity_type: daily_activity_completed
content_type: reset

add content properties metadata
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/_3drdxZoB
 * 23. This event fires when a user selects a resource from the company resource tab in Library.
feature_type = 'library'
activity_type = 'company_resource_selected'
content_type = 'company_resource'

content property bundle should have the resource metadata.

Example:
content_id = 33
content_title = Benefits Portal
content_url = https://login.sequoia.com/
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/GlAV6SuQd
 * 24. This event fires when the reset plays after a user clicks to play Today's Reset on the journeys page

feature_type: content
activity_type: today_reset_started
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/MvawGCZGH
 * 25. When a user clicks to start an audio reset

feature_type: content
activity_type: audio_reset_started
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/xLsAFMcBk
 * 26. Fires when a user starts playing a Reset shared via liveShare.
feature_type ='content'
activity_type = 'liveshare_reset_started'
+reset properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/x6iEWRhNd
 * 27. When a user starts their recommended reset in the Journeys tab of the Webex embedded app

feature_type: journeys
activity_type: reset_started
content_type: reset
reset_type: recommended_reset
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/lz8Zo18mp
 * 28. When a user clicks to replay a reset
feature_type: content
activity_type: reset_started
content_type: reset
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/ZIX2KZ_Iz
 * 29. When a user clicks into a recipe from the Library.

feature_type: library
activity_type: recipe_selected
content_type: recipe
+ content properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/NbntPGx_t
 * 30. Fires when a Live share Reset is shared and begins to play for users.
feature_type = 'content'
acitivity_type = 'liveshare_reset_started'
+ content_properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH/trigger/bnFEUT8q7
 *
 * @param properties the properties associatied with this event
 * @param properties.contentId: Recommended Content ID including external resources
 * @param properties.contentType: The type of content (reset, video, article etc.) in English
 * @param properties.contentTitle: The title of the content (Resets, Articles, Videos etc.) including external resources as well
 * @param properties.contentSubtype: Secondary type based on the content type:
Articles: role_model | recipe | watch_me_thrive | article

 * @param properties.contentFormatType: The format of the content displayed/consumed.

Articles: audio | video | text
Resets: audio | video
External resources: pdf | audio | video | text
 * @param properties.contentUrl: This is the URL of the content:
- If it is an iframe rendered - it would be the iframe full URL
- If it is an external resource (such as benefit connect / sequoia links) - it would be the external full URL

example:
https://thriveglobal.showpad.com/share/cpCAwJwBjxiFfegfWnIHj
https://login.sequoia.com/
 * @param properties.contentSource: Describes the source of the content or elements within the content (UMG audio for personal resets for example)
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.challengeId: id of the challenge being completed
 * @param properties.dayNumber: the day number of any event (be it journey level day number, challenge day number etc.)
 * @param properties.teamId: Id of the team the user belongs to. (this is the equivalent of social_group_id)
 * @param properties.teamType: team type for the company challenge events. Values can be 'individual' or 'team'.
 * @param properties.challengeTheme: the type of company challenge (e.g., hydration, movement)
 * @param properties.challengeType: the type of challenge (e.g., journey, biotype, company, group)
 * @param properties.conversationId: For the thrive guide: This is the conversation 'session' id from the thrive guide bot.
 * @param properties.messageType: When the chatbot interacts with the user, it can have two types of text messages: question or a recommendation.

When the user interacts with the chatbot, there are 2 types of messages: free-form text response, multiple choice response
 * @param properties.transactionId: For the a chatbot events, this id will join each conversation with its corresponding BE data.
 * @param properties.resetType: Types of reset
 * @param properties.tabName: The tab on which the Slack or MS Teams event took place. This is limited to Home Chat and About for now
 * @param properties.tstPlatform: The call center platform upon which TST Resets are served
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Zk0g9CjYH}
 */
export function contentStarted(properties: ContentStartedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.contentId !== undefined && properties.contentId !== null ?
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: properties.contentId}) :
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: null});
  properties.contentType !== undefined && properties.contentType !== null ?
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: properties.contentType}) :
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: null});
  properties.contentTitle !== undefined && properties.contentTitle !== null ?
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: properties.contentTitle}) :
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: null});
  properties.contentSubtype !== undefined && properties.contentSubtype !== null ?
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: properties.contentSubtype}) :
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: null});
  properties.contentFormatType !== undefined && properties.contentFormatType !== null ?
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: properties.contentFormatType}) :
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: null});
  properties.contentUrl !== undefined && properties.contentUrl !== null ?
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: properties.contentUrl}) :
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: null});
  properties.contentSource !== undefined && properties.contentSource !== null ?
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: properties.contentSource}) :
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: null});
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.challengeId !== undefined && properties.challengeId !== null ?
    eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: properties.challengeId}) :
    eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: null});
  properties.dayNumber !== undefined && properties.dayNumber !== null ?
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: properties.dayNumber}) :
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: null});
  properties.teamId !== undefined && properties.teamId !== null ?
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: properties.teamId}) :
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: null});
  properties.teamType !== undefined && properties.teamType !== null ?
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: properties.teamType}) :
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: null});
  properties.challengeTheme !== undefined && properties.challengeTheme !== null ?
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: properties.challengeTheme}) :
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: null});
  properties.challengeType !== undefined && properties.challengeType !== null ?
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: properties.challengeType}) :
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: null});
  properties.conversationId !== undefined && properties.conversationId !== null ?
    eventPropertiesArray.push({id: "RwgnWaMMW", name: "conversation_id", value: properties.conversationId}) :
    eventPropertiesArray.push({id: "RwgnWaMMW", name: "conversation_id", value: null});
  properties.messageType !== undefined && properties.messageType !== null ?
    eventPropertiesArray.push({id: "pcbtFdgiJ", name: "message_type", value: properties.messageType}) :
    eventPropertiesArray.push({id: "pcbtFdgiJ", name: "message_type", value: null});
  properties.transactionId !== undefined && properties.transactionId !== null ?
    eventPropertiesArray.push({id: "Mi1dgAO5M", name: "transaction_id", value: properties.transactionId}) :
    eventPropertiesArray.push({id: "Mi1dgAO5M", name: "transaction_id", value: null});
  properties.resetType !== undefined && properties.resetType !== null ?
    eventPropertiesArray.push({id: "A3cfeXxyUCBc", name: "reset_type", value: properties.resetType}) :
    eventPropertiesArray.push({id: "A3cfeXxyUCBc", name: "reset_type", value: null});
  properties.tabName !== undefined && properties.tabName !== null ?
    eventPropertiesArray.push({id: "notiOkO1a", name: "tab_name", value: properties.tabName}) :
    eventPropertiesArray.push({id: "notiOkO1a", name: "tab_name", value: null});
  properties.tstPlatform !== undefined && properties.tstPlatform !== null ?
    eventPropertiesArray.push({id: "LSAAiy9CP", name: "tst_platform", value: properties.tstPlatform}) :
    eventPropertiesArray.push({id: "LSAAiy9CP", name: "tst_platform", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Zk0g9CjYH", "04813ee0dcf94dbfc99a53613129c2e3178ddf7af5503e663b7745800634c83f", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("content_started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Zk0g9CjYH", "content_started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("content_started", eventProperties, "Zk0g9CjYH", "04813ee0dcf94dbfc99a53613129c2e3178ddf7af5503e663b7745800634c83f");
    }
    // destination Segment
    Segment.logEvent("content_started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ContentCompletedProperties {
  contentId: string | null | undefined;
  contentType: ContentTypeValueType | null | undefined;
  contentTitle: string | null | undefined;
  contentSubtype: ContentSubtypeValueType | null | undefined;
  contentFormatType: ContentFormatTypeValueType | null | undefined;
  contentUrl: string | null | undefined;
  contentSource: ContentSourceValueType | null | undefined;
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  challengeId: string | null | undefined;
  dayNumber: number | null | undefined;
  teamId: string | null | undefined;
  teamType: TeamTypeValueType | null | undefined;
  challengeTheme: string | null | undefined;
  challengeType: string | null | undefined;
  conversationId: string | null | undefined;
  messageType: MessageTypeValueType | null | undefined;
  transactionId: string | null | undefined;
  resetType: ResetTypeValueType | null | undefined;
  tstPlatform: TstPlatformValueType | null | undefined;
}
/**
 * content_completed: When a user completes consuming a piece of content or a content package (e.g. a Learn course). This can be used for any text, audio, or visual content, as well as signifying a lesson/course/season/etc. completion.

Examples of the completion criteria:
- Resets: when a user watches >= 80% of a reset. (TST team already has this logic in place)
- Text Articles: when a user hits 75% of the scroll depth of the article
- Videos: when a user watches >= 80% of the video
 *
 * When to trigger this event:
 * 1. Web trigger: when a user reaches/surpasses the minimum threshold of a video article. Includes the following property values:
feature_type = 'library'
activity_type = 'article_video_completed'
content_type = 'article'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/VFKenAaaY/trigger/Cel2ziRdF
 * 2. Fires when a user completes a Lesson, with the following properties:
feature_type = 'library'
activity_type = 'course_lesson_completed'
content_type = 'lesson'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/VFKenAaaY/trigger/6LPzJZNrl
 * 3. Fires when a user completes a Learn module, with the following property values:
feature_type = 'library'
activity_type = 'course_module_completed'
content_type = 'module'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/VFKenAaaY/trigger/TxV-lciVj
 * 4. Fires when a user completes a Learn Course, with the following property values:
feature_type = 'library'
activity_type = 'course_completed'
content_type = 'course'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/VFKenAaaY/trigger/xep5jlfH9
 * 5. This fires when a user completes a podcast episode, with the following property values:
feature_type = 'library'
activity_type = 'podcast_episode_completed'
content_type = 'podcast_episode'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/VFKenAaaY/trigger/rOYFvj3Ur
 * 6. This fires when a user completes the final podcast episode in a podcast, with the following property values:
feature_type = 'library'
activity_type = 'podcast_completed'
content_type = 'podcast'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/VFKenAaaY/trigger/dlgyDcceZ
 * 7. Fires when a user completes the final podcast episode in a podcast season.
feature_type = 'library'
activity_type = 'podcast_season_completed'
content_type = 'podcast_season'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/VFKenAaaY/trigger/e9wJlMm9o
 * 8. Fires when user completes Reset/Personal Reset
feature_type: content
activity_type: reset_completed
content_type: reset | personal_reset

If content_type is reset, reset_type should be populated based on the enums (personal_reset, reset, reset_banner)
reset_type: <reset_type>
content_format_type: video | audio
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/VFKenAaaY/trigger/lA0FStTZk
 * 9. feature_type = journeys
activity_type = journey_video_completed
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/VFKenAaaY/trigger/Qq0haUXsY
 * 10. Fires when a user completes a reset video from the daily goals company challenge pages

feature_type: challenge
activity_type: daily_activity_completed
content_type: reset

include content properties metadata
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/VFKenAaaY/trigger/ZqaB9k4jQ
 * 11. fires when a user completes ANY content that they start from the recommended section or the popular section.

feature_type = library
activity_type = popular_content_completed|
recommended_content_completed
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/VFKenAaaY/trigger/po4oflZap
 * 12. This event fires when a user selects a resource from the company resource tab in Library.
feature_type = 'library'
activity_type = 'company_resource_selected'
content_type = 'company_resource'

content property bundle should have the resource metadata.

Example:
content_id = 33
content_title = Benefits Portal
content_url = https://login.sequoia.com/
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/VFKenAaaY/trigger/lZZk-V803
 * 13. This event fires when Today's Reset video is complete.

feature_type: content
activity_type: today_reset_completed
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/VFKenAaaY/trigger/INxKLaALU
 * 14. When a user has completed 80% of an audio reset

feature_type: content
activity_type: audio_reset_completed
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/VFKenAaaY/trigger/GUGFeRn6s
 * 15. Fires when a user completes (at least 80%) of a Reset video shared via Live Share.
feature_type = 'content'
activity_type = 'liveshare_reset_completed'
+content_properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/VFKenAaaY/trigger/ObqRs70FK
 *
 * @param properties the properties associatied with this event
 * @param properties.contentId: Recommended Content ID including external resources
 * @param properties.contentType: The type of content (reset, video, article etc.) in English
 * @param properties.contentTitle: The title of the content (Resets, Articles, Videos etc.) including external resources as well
 * @param properties.contentSubtype: Secondary type based on the content type:
Articles: role_model | recipe | watch_me_thrive | article

 * @param properties.contentFormatType: The format of the content displayed/consumed.

Articles: audio | video | text
Resets: audio | video
External resources: pdf | audio | video | text
 * @param properties.contentUrl: This is the URL of the content:
- If it is an iframe rendered - it would be the iframe full URL
- If it is an external resource (such as benefit connect / sequoia links) - it would be the external full URL

example:
https://thriveglobal.showpad.com/share/cpCAwJwBjxiFfegfWnIHj
https://login.sequoia.com/
 * @param properties.contentSource: Describes the source of the content or elements within the content (UMG audio for personal resets for example)
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.challengeId: id of the challenge being completed
 * @param properties.dayNumber: the day number of any event (be it journey level day number, challenge day number etc.)
 * @param properties.teamId: Id of the team the user belongs to. (this is the equivalent of social_group_id)
 * @param properties.teamType: team type for the company challenge events. Values can be 'individual' or 'team'.
 * @param properties.challengeTheme: the type of company challenge (e.g., hydration, movement)
 * @param properties.challengeType: the type of challenge (e.g., journey, biotype, company, group)
 * @param properties.conversationId: For the thrive guide: This is the conversation 'session' id from the thrive guide bot.
 * @param properties.messageType: When the chatbot interacts with the user, it can have two types of text messages: question or a recommendation.

When the user interacts with the chatbot, there are 2 types of messages: free-form text response, multiple choice response
 * @param properties.transactionId: For the a chatbot events, this id will join each conversation with its corresponding BE data.
 * @param properties.resetType: Types of reset
 * @param properties.tstPlatform: The call center platform upon which TST Resets are served
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/VFKenAaaY}
 */
export function contentCompleted(properties: ContentCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.contentId !== undefined && properties.contentId !== null ?
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: properties.contentId}) :
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: null});
  properties.contentType !== undefined && properties.contentType !== null ?
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: properties.contentType}) :
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: null});
  properties.contentTitle !== undefined && properties.contentTitle !== null ?
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: properties.contentTitle}) :
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: null});
  properties.contentSubtype !== undefined && properties.contentSubtype !== null ?
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: properties.contentSubtype}) :
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: null});
  properties.contentFormatType !== undefined && properties.contentFormatType !== null ?
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: properties.contentFormatType}) :
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: null});
  properties.contentUrl !== undefined && properties.contentUrl !== null ?
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: properties.contentUrl}) :
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: null});
  properties.contentSource !== undefined && properties.contentSource !== null ?
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: properties.contentSource}) :
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: null});
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.challengeId !== undefined && properties.challengeId !== null ?
    eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: properties.challengeId}) :
    eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: null});
  properties.dayNumber !== undefined && properties.dayNumber !== null ?
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: properties.dayNumber}) :
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: null});
  properties.teamId !== undefined && properties.teamId !== null ?
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: properties.teamId}) :
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: null});
  properties.teamType !== undefined && properties.teamType !== null ?
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: properties.teamType}) :
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: null});
  properties.challengeTheme !== undefined && properties.challengeTheme !== null ?
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: properties.challengeTheme}) :
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: null});
  properties.challengeType !== undefined && properties.challengeType !== null ?
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: properties.challengeType}) :
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: null});
  properties.conversationId !== undefined && properties.conversationId !== null ?
    eventPropertiesArray.push({id: "RwgnWaMMW", name: "conversation_id", value: properties.conversationId}) :
    eventPropertiesArray.push({id: "RwgnWaMMW", name: "conversation_id", value: null});
  properties.messageType !== undefined && properties.messageType !== null ?
    eventPropertiesArray.push({id: "pcbtFdgiJ", name: "message_type", value: properties.messageType}) :
    eventPropertiesArray.push({id: "pcbtFdgiJ", name: "message_type", value: null});
  properties.transactionId !== undefined && properties.transactionId !== null ?
    eventPropertiesArray.push({id: "Mi1dgAO5M", name: "transaction_id", value: properties.transactionId}) :
    eventPropertiesArray.push({id: "Mi1dgAO5M", name: "transaction_id", value: null});
  properties.resetType !== undefined && properties.resetType !== null ?
    eventPropertiesArray.push({id: "A3cfeXxyUCBc", name: "reset_type", value: properties.resetType}) :
    eventPropertiesArray.push({id: "A3cfeXxyUCBc", name: "reset_type", value: null});
  properties.tstPlatform !== undefined && properties.tstPlatform !== null ?
    eventPropertiesArray.push({id: "LSAAiy9CP", name: "tst_platform", value: properties.tstPlatform}) :
    eventPropertiesArray.push({id: "LSAAiy9CP", name: "tst_platform", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "VFKenAaaY", "a4a5ab6d5f670ed85744b7dfdcaec5020811ce47e2449a073987aaac5c3c5c74", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("content_completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("VFKenAaaY", "content_completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("content_completed", eventProperties, "VFKenAaaY", "a4a5ab6d5f670ed85744b7dfdcaec5020811ce47e2449a073987aaac5c3c5c74");
    }
    // destination Segment
    Segment.logEvent("content_completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SurveySubmittedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  isOnboarding: boolean | null | undefined;
  journey: string | null | undefined;
  journeyId: string | null | undefined;
  journeyLevel: number | null | undefined;
  journeyDay: number | null | undefined;
  firstJourney: boolean | null | undefined;
  assessmentId: string | null | undefined;
  assessmentName: string | null | undefined;
  assessmentVariant: string | null | undefined;
  assessmentAttemptId: string | null | undefined;
  assessmentItemId: string | null | undefined;
  responseText: string | null | undefined;
  responseValue: number | null | undefined;
  npsScore: number | null | undefined;
  openAnswer: string | null | undefined;
  dciCount: number | null | undefined;
  tabName: TabNameValueType | null | undefined;
  surveyLocation: string | null | undefined;
  messageQueueId: string | null | undefined;
  dispatchedDate: string | null | undefined;
}
/**
 * survey_submitted: When a user submits a survey (e.g. DCI), or survey response (e.g. Onboarding Assessment).
 *
 * When to trigger this event:
 * 1. When a user is served the NPS survey on the Journeys page.

feature_type: nps
activity_type: nps_submitted
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/O_OK2XJHBJ/trigger/Y0ybPR9fj
 * 2. feature_type = 'journeys'
activity_type = 'dci_submitted'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/O_OK2XJHBJ/trigger/5MNF1tUI0
 * 3. Fires when a user responds to the DCI question during onboarding.
feature_type = 'journeys'
activity_type = 'dci_submitted'
is_onboarding = true
onboarding_attempt_number = 1
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/O_OK2XJHBJ/trigger/3ywdtPYRv
 * 4. Fires when a user responds to an onboarding assessment question in the onboarding assessment survey.
feature_type = 'journeys'
activity_type = 'onboarding_response_submitted'
is_onboarding = true
onboarding_attempt_number = 1
+ assessment bundle
+ assessment_items bundle
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/O_OK2XJHBJ/trigger/T4-DtNqfa
 * 5. When a user responds to their DCI on the Insights tab.
feature_type = 'journeys'
activity_type = 'dci_submitted'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/O_OK2XJHBJ/trigger/4n7oqPMHG
 * 6. Fires when a user clicks "Next" to submit their assessment question response.
feature_type = 'assessment'
activity_type = 'progress_response_submitted'
+assessment and assessment_item properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/O_OK2XJHBJ/trigger/2OQm63SR4
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.isOnboarding: T/F flag for whether an event occurred during onboarding or not.
 * @param properties.journey: Name of the journey
 * @param properties.journeyId: Id of the journey
 * @param properties.journeyLevel: Level of the journey that a user is on
 * @param properties.journeyDay: The day of the journey that a user is on.
 * @param properties.firstJourney: True if journey is the user's first journey selection.
 * @param properties.assessmentId: The ID of the assessment.
 * @param properties.assessmentName: The name of the assessment.
 * @param properties.assessmentVariant: Assessment version. This can be used to compare a baseline/default assessment to a v1, v2, etc. version.
 * @param properties.assessmentAttemptId: Attempt ID for assessments
 * @param properties.assessmentItemId: The ID of the assessment item question (i.e. the 'question_id')
 * @param properties.responseText: The response to an assessment question (e.g. 'Strongly agree|Agree|Neither agree nor disagree|Disagree|Strongly disagree|NULL')
 * @param properties.responseValue: The numerical value for each response given. (e.g. 'Strongly agree = 1|Agree = .75|Neither agree nor disagree = .5|Disagree = .25|Strongly disagree = 0|NULL')
 * @param properties.npsScore: the numerical score of the NPS Survey, asking 'How likely are you to recommend Thrive to a friend or colleague?'
 * @param properties.openAnswer: Open Ended Question Answer (user input in free form text) - used in surveys with open-ended question
 * @param properties.dciCount: This property will be used to count the number of Daily Check-Ins the user has received
 * @param properties.tabName: The tab on which the Slack or MS Teams event took place. This is limited to Home Chat and About for now
 * @param properties.surveyLocation: url where NPS survey was deployed
 * @param properties.messageQueueId: The message queue ID from the MS Teams message queue table (base_ms_teams_message_queue)
 * @param properties.dispatchedDate: The date that a DCI (or other survey) was dispatched. This will be useful for retro-active Check-Ins as it represents the day(s) that the DCI was issued, even if it wasn't answered until the following day(s).
Format: YYYY-MM-DD
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/O_OK2XJHBJ}
 */
export function surveySubmitted(properties: SurveySubmittedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.isOnboarding !== undefined && properties.isOnboarding !== null ?
    eventPropertiesArray.push({id: "RCLdkrcD0", name: "is_onboarding", value: properties.isOnboarding}) :
    eventPropertiesArray.push({id: "RCLdkrcD0", name: "is_onboarding", value: null});
  properties.journey !== undefined && properties.journey !== null ?
    eventPropertiesArray.push({id: "F3BjAMmBpGqj", name: "journey", value: properties.journey}) :
    eventPropertiesArray.push({id: "F3BjAMmBpGqj", name: "journey", value: null});
  properties.journeyId !== undefined && properties.journeyId !== null ?
    eventPropertiesArray.push({id: "lvwXMqzXMp9C", name: "journey_id", value: properties.journeyId}) :
    eventPropertiesArray.push({id: "lvwXMqzXMp9C", name: "journey_id", value: null});
  properties.journeyLevel !== undefined && properties.journeyLevel !== null ?
    eventPropertiesArray.push({id: "HtZ0qgLqVsP6", name: "journey_level", value: properties.journeyLevel}) :
    eventPropertiesArray.push({id: "HtZ0qgLqVsP6", name: "journey_level", value: null});
  properties.journeyDay !== undefined && properties.journeyDay !== null ?
    eventPropertiesArray.push({id: "StQnPpWOrG18", name: "journey_day", value: properties.journeyDay}) :
    eventPropertiesArray.push({id: "StQnPpWOrG18", name: "journey_day", value: null});
  properties.firstJourney !== undefined && properties.firstJourney !== null ?
    eventPropertiesArray.push({id: "ktrvZyV0Q-nn", name: "first_journey", value: properties.firstJourney}) :
    eventPropertiesArray.push({id: "ktrvZyV0Q-nn", name: "first_journey", value: null});
  properties.assessmentId !== undefined && properties.assessmentId !== null ?
    eventPropertiesArray.push({id: "ps_LGjiEp", name: "assessment_id", value: properties.assessmentId}) :
    eventPropertiesArray.push({id: "ps_LGjiEp", name: "assessment_id", value: null});
  properties.assessmentName !== undefined && properties.assessmentName !== null ?
    eventPropertiesArray.push({id: "sHi3O7n8i", name: "assessment_name", value: properties.assessmentName}) :
    eventPropertiesArray.push({id: "sHi3O7n8i", name: "assessment_name", value: null});
  properties.assessmentVariant !== undefined && properties.assessmentVariant !== null ?
    eventPropertiesArray.push({id: "jxvsbVrYg_", name: "assessment_variant", value: properties.assessmentVariant}) :
    eventPropertiesArray.push({id: "jxvsbVrYg_", name: "assessment_variant", value: null});
  properties.assessmentAttemptId !== undefined && properties.assessmentAttemptId !== null ?
    eventPropertiesArray.push({id: "PKvaMlpRV", name: "assessment_attempt_id", value: properties.assessmentAttemptId}) :
    eventPropertiesArray.push({id: "PKvaMlpRV", name: "assessment_attempt_id", value: null});
  properties.assessmentItemId !== undefined && properties.assessmentItemId !== null ?
    eventPropertiesArray.push({id: "169DenIxe", name: "assessment_item_id", value: properties.assessmentItemId}) :
    eventPropertiesArray.push({id: "169DenIxe", name: "assessment_item_id", value: null});
  properties.responseText !== undefined && properties.responseText !== null ?
    eventPropertiesArray.push({id: "32zgCY_PY", name: "response_text", value: properties.responseText}) :
    eventPropertiesArray.push({id: "32zgCY_PY", name: "response_text", value: null});
  properties.responseValue !== undefined && properties.responseValue !== null ?
    eventPropertiesArray.push({id: "y4mTdLB9t", name: "response_value", value: properties.responseValue}) :
    eventPropertiesArray.push({id: "y4mTdLB9t", name: "response_value", value: null});
  properties.npsScore !== undefined && properties.npsScore !== null ?
    eventPropertiesArray.push({id: "MmJIdeGlt", name: "nps_score", value: properties.npsScore}) :
    eventPropertiesArray.push({id: "MmJIdeGlt", name: "nps_score", value: null});
  properties.openAnswer !== undefined && properties.openAnswer !== null ?
    eventPropertiesArray.push({id: "K6V8-4Smq", name: "open_answer", value: properties.openAnswer}) :
    eventPropertiesArray.push({id: "K6V8-4Smq", name: "open_answer", value: null});
  properties.dciCount !== undefined && properties.dciCount !== null ?
    eventPropertiesArray.push({id: "zWit7Jn96", name: "dci_count", value: properties.dciCount}) :
    eventPropertiesArray.push({id: "zWit7Jn96", name: "dci_count", value: null});
  properties.tabName !== undefined && properties.tabName !== null ?
    eventPropertiesArray.push({id: "notiOkO1a", name: "tab_name", value: properties.tabName}) :
    eventPropertiesArray.push({id: "notiOkO1a", name: "tab_name", value: null});
  properties.surveyLocation !== undefined && properties.surveyLocation !== null ?
    eventPropertiesArray.push({id: "1KqutpEnDF", name: "survey_location", value: properties.surveyLocation}) :
    eventPropertiesArray.push({id: "1KqutpEnDF", name: "survey_location", value: null});
  properties.messageQueueId !== undefined && properties.messageQueueId !== null ?
    eventPropertiesArray.push({id: "DXs8pC6ZK", name: "message_queue_id", value: properties.messageQueueId}) :
    eventPropertiesArray.push({id: "DXs8pC6ZK", name: "message_queue_id", value: null});
  properties.dispatchedDate !== undefined && properties.dispatchedDate !== null ?
    eventPropertiesArray.push({id: "RkGOnBS2x", name: "dispatched_date", value: properties.dispatchedDate}) :
    eventPropertiesArray.push({id: "RkGOnBS2x", name: "dispatched_date", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertJourneyLevelOptional(properties.journeyLevel));
    messages = messages.concat(assertJourneyDayOptional(properties.journeyDay));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "O_OK2XJHBJ", "1963ee0282fc462fe8ab95bd768dff547a08d7a13a1c81cba63f56e3ca95f739", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("survey_submitted", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("O_OK2XJHBJ", "survey_submitted", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'survey_submitted': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("survey_submitted", eventProperties, "O_OK2XJHBJ", "1963ee0282fc462fe8ab95bd768dff547a08d7a13a1c81cba63f56e3ca95f739");
    }
    // destination Segment
    Segment.logEvent("survey_submitted", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SurveyServedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  isOnboarding: boolean | null | undefined;
  assessmentId: string | null | undefined;
  assessmentName: string | null | undefined;
  assessmentVariant: string | null | undefined;
  assessmentAttemptId: string | null | undefined;
  userId_: string;
  surveyLocation: string | null | undefined;
  dispatchedDate: string | null | undefined;
  onboardingAttemptNumber: number | null | undefined;
}
/**
 * survey_served: When a user is served a survey
 *
 * When to trigger this event:
 * 1. When a user is served the NPS survey on the Journeys page.

feature_type: nps
activity_type: nps_served
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/8Wx_qfXPnY/trigger/24R-kvyut
 * 2. feature_type = 'journeys'
activity_type = 'dci_served'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/8Wx_qfXPnY/trigger/zpuo4hPOE
 * 3. Fires when a user lands on this page, with the option to begin their onboarding assessment survey.
feature_type = 'assessment'
activity_type = 'onboarding_assessment_served'
is_onboarding = true
onboarding_attempt_number = 1
assessment_variant = 'baseline'|'v1'|'v2'|etc.
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/8Wx_qfXPnY/trigger/1as6n8nnY
 * 4. When the webex embedded app serves a DCI question.

feature_type: journeys
activity_type: dci_served
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/8Wx_qfXPnY/trigger/Y3CpPBboN
 * 5. When a user receives their DCI on the Insights tab.
feature_type = 'journeys'
activity_type = 'dci_served'

Note-- Although this is firing on the Insights tab, the feature_type is still journeys because it's referring to the DCI feature. Our page tracking properties (which we get automatically) will tell us this happened on the Insights page vs. Journeys
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/8Wx_qfXPnY/trigger/FvQYhY8Tr
 * 6. Fires when a user is served a Progress Assessment
feature_type = 'assessment'
activity_type = 'progress_assessment_served'
+ assessment_properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/8Wx_qfXPnY/trigger/Evoa9VFKM
 * 7. Fires when a user lands on this page, with the option to begin their onboarding assessment survey.

feature_type = 'intention_setting'
activity_type = 'onboarding_prompt_served'
is_onboarding = true
onboarding_attempt_number = 1
+ assessment_properties bundle
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/8Wx_qfXPnY/trigger/d33CvngNk
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.isOnboarding: T/F flag for whether an event occurred during onboarding or not.
 * @param properties.assessmentId: The ID of the assessment.
 * @param properties.assessmentName: The name of the assessment.
 * @param properties.assessmentVariant: Assessment version. This can be used to compare a baseline/default assessment to a v1, v2, etc. version.
 * @param properties.assessmentAttemptId: Attempt ID for assessments
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 * @param properties.surveyLocation: url where NPS survey was deployed
 * @param properties.dispatchedDate: The date that a DCI (or other survey) was dispatched. This will be useful for retro-active Check-Ins as it represents the day(s) that the DCI was issued, even if it wasn't answered until the following day(s).
Format: YYYY-MM-DD
 * @param properties.onboardingAttemptNumber: When onboarding a user, we may make several attempts to get them to complete onboarding (i.e. selecting a journey). We will use this property as a flag to know how many attempts we've made to get a user to complete onboarding.
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/8Wx_qfXPnY}
 */
export function surveyServed(properties: SurveyServedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.isOnboarding !== undefined && properties.isOnboarding !== null ?
    eventPropertiesArray.push({id: "RCLdkrcD0", name: "is_onboarding", value: properties.isOnboarding}) :
    eventPropertiesArray.push({id: "RCLdkrcD0", name: "is_onboarding", value: null});
  properties.assessmentId !== undefined && properties.assessmentId !== null ?
    eventPropertiesArray.push({id: "ps_LGjiEp", name: "assessment_id", value: properties.assessmentId}) :
    eventPropertiesArray.push({id: "ps_LGjiEp", name: "assessment_id", value: null});
  properties.assessmentName !== undefined && properties.assessmentName !== null ?
    eventPropertiesArray.push({id: "sHi3O7n8i", name: "assessment_name", value: properties.assessmentName}) :
    eventPropertiesArray.push({id: "sHi3O7n8i", name: "assessment_name", value: null});
  properties.assessmentVariant !== undefined && properties.assessmentVariant !== null ?
    eventPropertiesArray.push({id: "jxvsbVrYg_", name: "assessment_variant", value: properties.assessmentVariant}) :
    eventPropertiesArray.push({id: "jxvsbVrYg_", name: "assessment_variant", value: null});
  properties.assessmentAttemptId !== undefined && properties.assessmentAttemptId !== null ?
    eventPropertiesArray.push({id: "PKvaMlpRV", name: "assessment_attempt_id", value: properties.assessmentAttemptId}) :
    eventPropertiesArray.push({id: "PKvaMlpRV", name: "assessment_attempt_id", value: null});
  properties.surveyLocation !== undefined && properties.surveyLocation !== null ?
    eventPropertiesArray.push({id: "1KqutpEnDF", name: "survey_location", value: properties.surveyLocation}) :
    eventPropertiesArray.push({id: "1KqutpEnDF", name: "survey_location", value: null});
  properties.dispatchedDate !== undefined && properties.dispatchedDate !== null ?
    eventPropertiesArray.push({id: "RkGOnBS2x", name: "dispatched_date", value: properties.dispatchedDate}) :
    eventPropertiesArray.push({id: "RkGOnBS2x", name: "dispatched_date", value: null});
  properties.onboardingAttemptNumber !== undefined && properties.onboardingAttemptNumber !== null ?
    eventPropertiesArray.push({id: "qN5ovMFjx", name: "onboarding_attempt_number", value: properties.onboardingAttemptNumber}) :
    eventPropertiesArray.push({id: "qN5ovMFjx", name: "onboarding_attempt_number", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "8Wx_qfXPnY", "46534c1a0f0156b0c2dfc12315340ef615a300b929f630e162d449de3b41a92b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("survey_served", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("8Wx_qfXPnY", "survey_served", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("survey_served", eventProperties, "8Wx_qfXPnY", "46534c1a0f0156b0c2dfc12315340ef615a300b929f630e162d449de3b41a92b");
    }
    // destination Segment
    Segment.identify(properties.userId_);
    Segment.logEvent("survey_served", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PromptSelectedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  contentId: string | null | undefined;
  contentType: ContentTypeValueType | null | undefined;
  contentTitle: string | null | undefined;
  contentSubtype: ContentSubtypeValueType | null | undefined;
  contentFormatType: ContentFormatTypeValueType | null | undefined;
  contentUrl: string | null | undefined;
  contentSource: ContentSourceValueType | null | undefined;
  resetId: string | null | undefined;
  resetName: string | null | undefined;
  isOnboarding: boolean | null | undefined;
  microstepId: string | null | undefined;
  microstepBody: string | null | undefined;
  isAutomaticCheckin: boolean | null | undefined;
  microstepName: string | null | undefined;
  checkInDate: string | null | undefined;
  userId_: string;
  displayText: string | null | undefined;
  tabName: TabNameValueType | null | undefined;
  tstPlatform: TstPlatformValueType | null | undefined;
  notificationCount: number | null | undefined;
  nudgeType: string | null | undefined;
}
/**
 * prompt_selected: This event fires when a user clicks/taps/selects a CTA from a prompt.
 *
 * When to trigger this event:
 * 1. Fires when a user selects the Help Me Choose prompt.
feature_type = 'content'
activity_type = 'reset_theme_recommendation_options_served'
content_type = 'reset'
display_text = 'Help me choose'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/pSfu4mcD1
 * 2. Fires when a user selects an option for which type of Reset to watch (opens after Help Me Choose is clicked).
feature_type = 'content'
activity_type = 'reset_recommendation_selected'
content_type = 'reset'
display_text = 'Joy|Surprise Me|etc.'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/13eS3PgT9
 * 3. Fires when a user selects to "Load more" Resets.
feature_type = 'content'
activity_type = 'load_more_resets'
content_type = 'reset'
display_text = 'Load More'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/w7NoO3wZI
 * 4. Fires when a user selects one of the options for how to schedule a Reset.
feature_type = 'content'
activity_type = 'reset_schedule_completed'
content_type = 'reset'
display_text = 'Google|Outlook|Office 365'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/it7F-CShK
 * 5. Fires when a user selects the prompt to "Schedule a Reset"
feature_type = 'content'
activity_type = 'reset_schedule_opened'
content_type = 'reset'
display_text = 'Schedule a Reset'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/Pkg6_GsBS
 * 6. Fires when a user selects to contact support
feature_type: profile
activity_type: contact_support_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/nonUWLaQA
 * 7. Fires when a user selects to enable their Daily Check In Notifications
feature_type: profile
activity_type: pulse_notifications_enabled
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/9DYF68SXk
 * 8. Fires when a user selects to open their device settings to enable notifications.
feature_type: profile
activity_type: open_device_settings_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/Mc6xUQh8F
 * 9. Fires when a user selects to disable their Daily Check In notifications
feature_type: profile
activity_type: pulse_notifications_disabled
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/KX1v-sZjR
 * 10. Fires when a user selects to resend their verification email.
feature_type: profile
activity_type: resend_verification_email_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/qTkLZBjK0
 * 11. Fires when a user selects to view the terms and conditions
feature_type: profile
activity_type: terms_and_conditions_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/dtx3ShrXi
 * 12. Fires when a user selects "Contact Us" after reaching the Community Builder prize.
feature_type = 'achievement'
activity_type= 'achievement_reward_email_sent'
display_text = 'Contact Us'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/nMdLZCghk
 * 13. Fires when a user selects on their activity count to view more details.
feature_type = 'challenge'
activity_type = 'challenge_activity_viewed'
display_text = 'Activity Count'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/kKsdnXALQ
 * 14. Fires when a user selects the Privacy notice for connecting their biometrics device.
feature_type = 'challenge'
activity_type = 'device_privacy_info_selected'
display_text = 'Privacy Info'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/o8swqel-w
 * 15. Fires when a user selects to manage their connected biometrics devices.
feature_type = 'challenge'
activity_type = 'manage_devices_selected'
display_text = 'Manage connected apps'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/7z3Ybd9cO
 * 16. Fires when a user selects the "Connect" or "How to connect Apple Health" button for their well-being apps.
feature_type = 'challenge'
activity_type = 'connect_device_selected'
display_text = 'How to connect Apple Health' OR 'Connect'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/p9-wpeTn5
 * 17. Fires when a user selects the "Connect Apple Health" or "Connect a well-being app" button for their well-being apps.
feature_type = 'challenge'
activity_type = 'connect_device_selected'
display_text = 'Connect Apple Health' OR 'Connect a well-being app'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/7cz1hQgmk
 * 18. Fires when a user selects "How to disconnect Apple Health"
feature_type = 'challenge'
activity_type = 'disconnect_device_selected'
display_text = 'How to disconnect Apple Health'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/j-8a8QW50
 * 19. Fires when a user selects "Disconnect Apple Health"
feature_type = 'challenge'
activity_type = 'disconnect_device_selected'
display_text = 'Disconnect Apple Health'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/8xd1wFxqK
 * 20. This event fires when a user completes the Apple Health/well-being app sync flow and is returned to the Thrive platform.
feature_type = 'challenge'
activity_type = 'wellbeing_app_setup_completed'
display_text = 'Done'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/s-ae79X36
 * 21. Fires when a user selects the Top Strength widget on the Journeys page.
feature_type = 'journeys'
activity_type = 'strength_detail_selected'
display_text = 'Top Strength'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/SqbndZcTW
 * 22. Fires when a user selects to "Show More" Journey Modules
feature_type = 'journeys'
activity_type = 'module_served'
display_text = 'Show More'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/Uo6bfPAkF
 * 23. Triggered when a user updates their timezone and saves their changes
feature_type: profile
activity_type: timezone_updated
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/QUUX4gmo0
 * 24. Triggered when a user updates their language and clicks save changes.
feature_type: profile
activity_type: language_updated
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/vKg8cq-vA
 * 25. Fired when a user clicks "Explore more" after viewing Today's Reset on the Journeys page

feature_type: content
activity_type: today_reset_explore_more_clicked
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/WMiwVdjYx
 * 26. Fires when a user selects the Next/Arrow button on this onboarding intro screen.
feature_type = 'journeys'
activity_type = 'onboarding_empathize_selected'
OR
'onboarding_empathize_skipped'
is_onboarding = true
onboarding_attempt_number = 1
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/YXZDDq5vV
 * 27. Fires when a user selects the Next/Arrow button on this onboarding intro screen.
feature_type = 'journeys'
activity_type = 'onboarding_introduce_selected'
OR
'onboarding_introduce_skipped'
is_onboarding = true
onboarding_attempt_number = 1
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/P5qOFsVEz
 * 28. Fires when a user selects the Next/Arrow button on this onboarding intro screen.
feature_type = 'journeys'
activity_type = 'onboarding_validate_selected'
OR
'onboarding_validate_skipped'
is_onboarding = true
onboarding_attempt_number = 1
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/ZzcGsXjhS
 * 29. Fires when a user selects the Next/Arrow button on this onboarding intro screen.
feature_type = 'journeys'
activity_type = 'onboarding_expectations_selected'
is_onboarding = true
onboarding_attempt_number = 1
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/nqU4BUGiX
 * 30. Fires when a user selects the Next/Arrow button on this onboarding intro screen.
feature_type = 'journeys'
activity_type = 'onboarding_celebrate_selected'
is_onboarding = true
onboarding_attempt_number = 1
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/TsVzeIopD
 * 31. Fires when a user selects the Next/Arrow button on this onboarding intro screen.
feature_type = 'journeys'
activity_type = 'onboarding_reinforce_selected'
is_onboarding = true
onboarding_attempt_number = 1
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/iMYHhnJNE
 * 32. Fires when the user selects "Visit Resets" on the Journeys page.
feature_type: content
activity_type: visit_reset_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/ouhdBJwWv
 * 33. Triggered when a user clicks the link in their company specific banner

feature_type: journeys
activity_type: company_banner_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/Wky9_jLAO
 * 34. When a user selects to delete their Thrive account.
feature_type: profile
activity_type: account_delete_requested
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/AJPTcVv1r
 * 35. When a user cancels their selection to delete their account.
feature_type: profile
activity_type: account_delete_cancelled
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/UAdgA7Kwy
 * 36. When a user confirms they'd like to delete their account.
feature_type: profile
activity_type: account_delete_confirmed
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/Vnkx55EQF
 * 37. When a user selects to watch the next Reset

feature_type: content
activity_type: reset_watch_next_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/KHfTiAF-1
 * 38. Fires when a user selects one of the results from their search.
feature_type = search
activity_type = search_result_selected
content_properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/HTkDp8stX
 * 39. Fires when a user selects one of the results from their search.
feature_type = search
activity_type = search_result_copied
content_properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/Pr-Y7KBmO
 * 40. Fires when a user selects a predetermined search value (tied to a journey).
feature_type = 'search'
activity_type = 'search_prompt_selected'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/0NkTgydXO
 * 41. Fires when a user selects to show more results.
feature_type = 'search'
activity_type = 'show_more_selected'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/a7uvEaBRl
 * 42. Fires when a user selects the Search icon.
feature_type = 'search'
activity_type = 'search_selected'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/W-6Eqnu4P
 * 43. When a user clicks to share a reset after watching it.

feature_type: content
activity_type: reset_shared OR audio_reset_shared OR personal_reset_shared
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/5hdCtiSD0
 * 44. Fires when a user selects to water icon to water their plant (by completing an activity like microstep checkin, watch a reset, etc.).
feature_type = 'achievement'
activity_type = 'water_plant_selected'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/WRqJLUNyx
 * 45. Fires when a user selects one of the options available to water their plant.
feature_type = 'achievement'
activity_type = 'watch_reset_selected' OR 'complete_microstep_selected'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/rjEe_uw18
 * 46. When a user clicks to watch the "Next Up" reset after viewing a reset.

feature_type: content
activity_type: reset_watch_next_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/zuQ0x_NBC3
 * 47. Fires when a user selects "Remind me" on a microstep card to set up notification reminders.
feature_type = 'journeys'
activity_type = 'microstep_reminder_selected'
+ microstep_properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/WrlkfTV1B
 * 48. When a user clicks to replay a reset.

feature_type: content
activity_type: replay_reset
content_id:
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/V3OYKN-P7
 * 49. When a user selects to copy the share link for a Reset

feature_type:content
activity_type: reset_shared
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/oj27iQerE
 * 50. Fires when a user opens their notification list via the notification icon.

feature_type: notifications
activity_type: notifications_opened
notification_count: []
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/Ch6nNGL-V
 * 51. Fires when a user selects to watch the reset presented by smart nudge
feature_type = 'content'
activity_type = 'reset_selected'
content_type = 'reset'

SHOULD ALSO FIRE STANDARD content_started EVENT FOR RESET
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/DCOZ5SHH4
 * 52. Fires when a user selects to defer their smart nudge reset
feature_type = 'content'
activity_type =  'reset_deferred'
content_type = 'reset'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/JCEY8LtFk
 * 53. When a user clicks to open their achievements drop-down

feature_type: achievement
activity_type: achievements_opened
notification_count: []
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/UEuR21rM-
 * 54. When a user selects to view more badges from their achievements page.

feature_type: achievement
activity_type: view_more_badges_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/Q6WySE92z
 * 55. When a user selects a badge to view more details

feature_type: achievement
activity_type: badge_details_viewed
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/rpIT-9ZzE
 * 56. When a user clicks to make progress on a badge from their achievements menu/page.

feature_type: achievement
activity_type: make_badge_progress_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/qDqPgk3sO
 * 57. When a user selects to be reminded about a microstep from the hub

feature_type: library
activity_type: microstep_reminder_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/FpZLl6-Kd
 * 58. When a user selects to be reminded about a microstep from the hub, choosing Google or Outlook

feature_type: library
activity_type: microstep_reminder_set
display_text: [google / outlook]
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/6mHeF-xQA
 * 59. When a user selects a timeline via the dropdown for their Microstep activity date range

feature_type: library
activity_type: microstep_hub_dates_selected
display_text: [7 day activity | 14 day activity | 30 day activity]
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/_O68-AoS_
 * 60. When a user clicks to expand Microstep options in the Explore More section of the Microstep Hub browser.

feature_type: library
activity_type: microstep_expand_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/R3o2yCL58
 * 61. When a user selects to be reminded about a microstep from the hub

feature_type: library
activity_type: microstep_reminder_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/9tYLjuES_
 * 62. When a user selects to be reminded about a microstep from the hub, choosing Google or Outlook

feature_type: library
activity_type: microstep_reminder_set
display_text: [google / outlook]
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/SZVRckwOB
 * 63. Fires when a user selects the Continue to your Journey button on the Insights page.
feature_type = 'journeys'
activity_type = 'navigate_to_journey_selected'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/HK9ZoAHWn
 * 64. Fires when a user selects "Take me there" to complete their Microstep(s) from yesterday.
feature_type = 'notifications'
activity_type = 'journey_microstep_notification_selected'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/PjzssY7W0
 * 65. Fires when a user selects "Go to Challenges' from their notifications.
feature_type = 'notifications'
activity_type = 'challenge_goal_notification_selected'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/UVrktyGvu
 * 66. When a user clicks to share a Reset they just watched.

features: content
activity_type: share_reset_selected
**We will distinguish this from sharing from the Reset menu by using page_path
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/xkMH6J2ku
 * 67. When a user clicks to share a Reset from the homescreen

feature_type: content
activity_type: share_reset_selected
**We will distinguish this from sharing from the Reset menu by using page_path
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/SfkD10tTn
 * 68. When a user clicks to replay a reset.

feature_type: content
activity_type: replay_reset_selected
content_type: reset
reset_type: replay_reset
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/wWmlHdMFa
 * 69. Fires when a user selects "Maybe later" on the Progress Assessment prompt.
feature_type = 'assessment'
activity_type = 'progress_assessment_deferred'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/3wZ0m8lVj0
 * 70. Fires when a user selects "Skip to main content" button.
feature_type = 'profile'
activity_type = 'accessible_skip_nav_selected'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/nb9u3ZMUn
 * 71. When a user selects to "not now" to  browser notifications.

feature_type: notifications
activity_type: browser_notifications_deferred
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/8OYp907ra
 * 72. When a user selects "Learn how to manage this setting" to update their browser notification settings.

feature_type: notifications
activity_type: manage_browser_notifications_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/Llq_zLGDy
 * 73. When a user is chooses to not allow browser notifications from their browser

feature_type: notifications
activity_type: browser_notifications_declined
utm_source: browser_notification
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/B_D3zQozR
 * 74. When a user clicks the "Get started now" button during the Intention Setting flow.

feature_type: intention_setting
activity_type: get_started_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/d9g4oVZZd
 * 75. When the user changes their timezone via the intention setting buttons in the top-right of screen.

feature_type: intention_setting
activity_type: timezone_updated

If this occurs during the New User flow, we should also capture:

is_onboarding = true
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/YGERC_3r5
 * 76. When a user clicks the "Not now" button in the Intention Setting section of the Home tab.

feature_type: intention_setting
activity_type: not_now_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/_Lm51M451
 * 77. When a user selects the "Try it now" button from the Intention Setting section of the Home tab.

feature_type: intention_setting
activity_type: try_it_now_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/AQZNDWxLa
 * 78. When a user selects the 'close' button in the top-right of the Intention Setting flow. Note - this option only exists for existing users (not new/onboarding users).

feature_type: intention_setting
activity_type: close_button_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/YqnlbGBBq
 * 79. When a user clicks the Edit Intention button.

feature_type: intention_setting
activity_type: edit_intention_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/udcBITwda
 * 80. When a user clicks on the edit button in the Your Intention banner on the Home tab.

feature_type: intention_setting
activity_type: intention_banner_edit_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/7crfP9mDt
 * 81. When the user clicks the X (edit) button after setting their intention.

feature_type = intention_setting
activity_type = clear_intention_selected

If the user is a New User, also capture:

is_onboarding = true
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/Zba5F7A4v
 * 82. When the user selects to change their language (should only fire if the language is changed).

feature_type = intention_setting
activity_type = language_set

If this occurs during the New User flow, we should also capture:

is_onboarding = true
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/QM37_A_FW
 * 83. When the user clicks on the Support button at the top of the page during Intention Setting.

feature_type = intention_setting
activity_type = support_selected

If this occurs during the New User flow, we should also capture:

is_onboarding = true
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/nbifRb71E
 * 84. When a user toggles to turn ON their auto-play within the Reset player.

feature_type: content
activity_type: reset_autoplay_toggled_on
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/uijWaCwhE
 * 85. When a user toggles to turn ON their auto-play within the Reset player.

feature_type: content
activity_type: reset_autoplay_toggled_off
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/8lJHS-RgQ
 * 86. When a user clicks to play their next Reset within the autoplay dialog box in the Reset player.

feature_type: content
activity_type: play_next_reset_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/406cbs4TN
 * 87. Fires when the user clicks "Agree and get started" on the Agreement page (Privacy Policy and Terms of service).

feature_type: profile
activity_type: agree_and_get_started_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/3gHwewrGJ
 * 88. Fires when a user selects "Start a Reset poll".
feature_type = 'content'
activity_type = 'reset_poll_created'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o/trigger/Oxg20ybHY
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.contentId: Recommended Content ID including external resources
 * @param properties.contentType: The type of content (reset, video, article etc.) in English
 * @param properties.contentTitle: The title of the content (Resets, Articles, Videos etc.) including external resources as well
 * @param properties.contentSubtype: Secondary type based on the content type:
Articles: role_model | recipe | watch_me_thrive | article

 * @param properties.contentFormatType: The format of the content displayed/consumed.

Articles: audio | video | text
Resets: audio | video
External resources: pdf | audio | video | text
 * @param properties.contentUrl: This is the URL of the content:
- If it is an iframe rendered - it would be the iframe full URL
- If it is an external resource (such as benefit connect / sequoia links) - it would be the external full URL

example:
https://thriveglobal.showpad.com/share/cpCAwJwBjxiFfegfWnIHj
https://login.sequoia.com/
 * @param properties.contentSource: Describes the source of the content or elements within the content (UMG audio for personal resets for example)
 * @param properties.resetId: unique identifier for the reset that needs to be done for that daily goal
 * @param properties.resetName: Name of the reset
 * @param properties.isOnboarding: T/F flag for whether an event occurred during onboarding or not.
 * @param properties.microstepId: The Microstep ID
 * @param properties.microstepBody: The body of the Microstep that tells us what Microstep is being checked into.
 * @param properties.isAutomaticCheckin: Flag to distinguish between automatic Microstep Check-Ins and manual ones.
 * @param properties.microstepName: Name of the checked in microstep
 * @param properties.checkInDate: The date the automated checkin occurred. (YYYY-DD-MM format)
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 * @param properties.displayText: The text on any selectable/clickable element.
 * @param properties.tabName: The tab on which the Slack or MS Teams event took place. This is limited to Home Chat and About for now
 * @param properties.tstPlatform: The call center platform upon which TST Resets are served
 * @param properties.notificationCount: The number of notifications/achievements displayed when a user opens their notifications/achievements drop-down
 * @param properties.nudgeType: The type of notification
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9GkUGb-i4o}
 */
export function promptSelected(properties: PromptSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.contentId !== undefined && properties.contentId !== null ?
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: properties.contentId}) :
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: null});
  properties.contentType !== undefined && properties.contentType !== null ?
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: properties.contentType}) :
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: null});
  properties.contentTitle !== undefined && properties.contentTitle !== null ?
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: properties.contentTitle}) :
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: null});
  properties.contentSubtype !== undefined && properties.contentSubtype !== null ?
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: properties.contentSubtype}) :
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: null});
  properties.contentFormatType !== undefined && properties.contentFormatType !== null ?
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: properties.contentFormatType}) :
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: null});
  properties.contentUrl !== undefined && properties.contentUrl !== null ?
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: properties.contentUrl}) :
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: null});
  properties.contentSource !== undefined && properties.contentSource !== null ?
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: properties.contentSource}) :
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: null});
  properties.resetId !== undefined && properties.resetId !== null ?
    eventPropertiesArray.push({id: "1QmenQkbcsOL", name: "reset_id", value: properties.resetId}) :
    eventPropertiesArray.push({id: "1QmenQkbcsOL", name: "reset_id", value: null});
  properties.resetName !== undefined && properties.resetName !== null ?
    eventPropertiesArray.push({id: "Ib5rtoVJ2Kqm", name: "reset_name", value: properties.resetName}) :
    eventPropertiesArray.push({id: "Ib5rtoVJ2Kqm", name: "reset_name", value: null});
  properties.isOnboarding !== undefined && properties.isOnboarding !== null ?
    eventPropertiesArray.push({id: "RCLdkrcD0", name: "is_onboarding", value: properties.isOnboarding}) :
    eventPropertiesArray.push({id: "RCLdkrcD0", name: "is_onboarding", value: null});
  properties.microstepId !== undefined && properties.microstepId !== null ?
    eventPropertiesArray.push({id: "fBtUkEzBban-", name: "microstep_id", value: properties.microstepId}) :
    eventPropertiesArray.push({id: "fBtUkEzBban-", name: "microstep_id", value: null});
  properties.microstepBody !== undefined && properties.microstepBody !== null ?
    eventPropertiesArray.push({id: "YMHMzP_Nb", name: "microstep_body", value: properties.microstepBody}) :
    eventPropertiesArray.push({id: "YMHMzP_Nb", name: "microstep_body", value: null});
  properties.isAutomaticCheckin !== undefined && properties.isAutomaticCheckin !== null ?
    eventPropertiesArray.push({id: "Ftso3m8Ty", name: "is_automatic_checkin", value: properties.isAutomaticCheckin}) :
    eventPropertiesArray.push({id: "Ftso3m8Ty", name: "is_automatic_checkin", value: null});
  properties.microstepName !== undefined && properties.microstepName !== null ?
    eventPropertiesArray.push({id: "sWRRlPTJjjSi", name: "microstep_name", value: properties.microstepName}) :
    eventPropertiesArray.push({id: "sWRRlPTJjjSi", name: "microstep_name", value: null});
  properties.checkInDate !== undefined && properties.checkInDate !== null ?
    eventPropertiesArray.push({id: "oxawdlke-", name: "check_in_date", value: properties.checkInDate}) :
    eventPropertiesArray.push({id: "oxawdlke-", name: "check_in_date", value: null});
  properties.displayText !== undefined && properties.displayText !== null ?
    eventPropertiesArray.push({id: "1YLolCiLg", name: "display_text", value: properties.displayText}) :
    eventPropertiesArray.push({id: "1YLolCiLg", name: "display_text", value: null});
  properties.tabName !== undefined && properties.tabName !== null ?
    eventPropertiesArray.push({id: "notiOkO1a", name: "tab_name", value: properties.tabName}) :
    eventPropertiesArray.push({id: "notiOkO1a", name: "tab_name", value: null});
  properties.tstPlatform !== undefined && properties.tstPlatform !== null ?
    eventPropertiesArray.push({id: "LSAAiy9CP", name: "tst_platform", value: properties.tstPlatform}) :
    eventPropertiesArray.push({id: "LSAAiy9CP", name: "tst_platform", value: null});
  properties.notificationCount !== undefined && properties.notificationCount !== null ?
    eventPropertiesArray.push({id: "GrGLn0lVb4", name: "notification_count", value: properties.notificationCount}) :
    eventPropertiesArray.push({id: "GrGLn0lVb4", name: "notification_count", value: null});
  properties.nudgeType !== undefined && properties.nudgeType !== null ?
    eventPropertiesArray.push({id: "5gHzHN48a", name: "nudge_type", value: properties.nudgeType}) :
    eventPropertiesArray.push({id: "5gHzHN48a", name: "nudge_type", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "9GkUGb-i4o", "5be3bca56f6924c38b9055f9ddce91e1c564e50a8378e1f3f962508729c933ee", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("prompt_selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("9GkUGb-i4o", "prompt_selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("prompt_selected", eventProperties, "9GkUGb-i4o", "5be3bca56f6924c38b9055f9ddce91e1c564e50a8378e1f3f962508729c933ee");
    }
    // destination Segment
    Segment.identify(properties.userId_);
    Segment.logEvent("prompt_selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ContentDownloadedProperties {
  contentId: string | null | undefined;
  contentType: ContentTypeValueType | null | undefined;
  contentTitle: string | null | undefined;
  contentSubtype: ContentSubtypeValueType | null | undefined;
  contentFormatType: ContentFormatTypeValueType | null | undefined;
  contentUrl: string | null | undefined;
  contentSource: ContentSourceValueType | null | undefined;
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  webinarId: string | null | undefined;
  webinarTitle: string | null | undefined;
}
/**
 * content_downloaded: Fires when a user clicks to download (usually PDF) content.
 *
 * When to trigger this event:
 * 1. Fires when a user selects "Download" of a PDF file, with the following properties:
feature_type = 'library'
activity_type = 'ebook_downloaded'
content_type = 'ebook'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Db8VLKS6km/trigger/BEgHsz7Gw
 * 2. Fires when a user clicks on the Resources links within a Learn Course.
feature_type = 'library'
activity_type = 'course_resource_downloaded'
content_type = 'course'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Db8VLKS6km/trigger/HNDyO8pnT
 * 3. Fires when a user selects to Start Activity Worksheet in a Webinar, with the following property values:
feature_type = 'library'
activity_type = 'webinar_resource_clicked'
content_type = 'webinar'
content_id = '<webinar_id>'
content_title = '<webinar_title>'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Db8VLKS6km/trigger/2NBoeSeku
 *
 * @param properties the properties associatied with this event
 * @param properties.contentId: Recommended Content ID including external resources
 * @param properties.contentType: The type of content (reset, video, article etc.) in English
 * @param properties.contentTitle: The title of the content (Resets, Articles, Videos etc.) including external resources as well
 * @param properties.contentSubtype: Secondary type based on the content type:
Articles: role_model | recipe | watch_me_thrive | article

 * @param properties.contentFormatType: The format of the content displayed/consumed.

Articles: audio | video | text
Resets: audio | video
External resources: pdf | audio | video | text
 * @param properties.contentUrl: This is the URL of the content:
- If it is an iframe rendered - it would be the iframe full URL
- If it is an external resource (such as benefit connect / sequoia links) - it would be the external full URL

example:
https://thriveglobal.showpad.com/share/cpCAwJwBjxiFfegfWnIHj
https://login.sequoia.com/
 * @param properties.contentSource: Describes the source of the content or elements within the content (UMG audio for personal resets for example)
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.webinarId: Webinar ID
 * @param properties.webinarTitle: The Webinar Title
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Db8VLKS6km}
 */
export function contentDownloaded(properties: ContentDownloadedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.contentId !== undefined && properties.contentId !== null ?
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: properties.contentId}) :
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: null});
  properties.contentType !== undefined && properties.contentType !== null ?
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: properties.contentType}) :
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: null});
  properties.contentTitle !== undefined && properties.contentTitle !== null ?
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: properties.contentTitle}) :
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: null});
  properties.contentSubtype !== undefined && properties.contentSubtype !== null ?
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: properties.contentSubtype}) :
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: null});
  properties.contentFormatType !== undefined && properties.contentFormatType !== null ?
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: properties.contentFormatType}) :
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: null});
  properties.contentUrl !== undefined && properties.contentUrl !== null ?
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: properties.contentUrl}) :
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: null});
  properties.contentSource !== undefined && properties.contentSource !== null ?
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: properties.contentSource}) :
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: null});
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.webinarId !== undefined && properties.webinarId !== null ?
    eventPropertiesArray.push({id: "atpDSisLVLkx", name: "webinar_id", value: properties.webinarId}) :
    eventPropertiesArray.push({id: "atpDSisLVLkx", name: "webinar_id", value: null});
  properties.webinarTitle !== undefined && properties.webinarTitle !== null ?
    eventPropertiesArray.push({id: "L0p9Pr3kzcVX", name: "webinar_title", value: properties.webinarTitle}) :
    eventPropertiesArray.push({id: "L0p9Pr3kzcVX", name: "webinar_title", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Db8VLKS6km", "96369ea4e219b7fb26e65ce45f2db97710b873dc825292362e52f475a913a3e6", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("content_downloaded", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Db8VLKS6km", "content_downloaded", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("content_downloaded", eventProperties, "Db8VLKS6km", "96369ea4e219b7fb26e65ce45f2db97710b873dc825292362e52f475a913a3e6");
    }
    // destination Segment
    Segment.logEvent("content_downloaded", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ContentSharedProperties {
  contentId: string | null | undefined;
  contentType: ContentTypeValueType | null | undefined;
  contentTitle: string | null | undefined;
  contentSubtype: ContentSubtypeValueType | null | undefined;
  contentFormatType: ContentFormatTypeValueType | null | undefined;
  contentUrl: string | null | undefined;
  contentSource: ContentSourceValueType | null | undefined;
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  resetType: ResetTypeValueType | null | undefined;
  pageSection: PageSectionValueType;
}
/**
 * content_shared: When a user shares content (via link copy, social media links, etc.).
 *
 * When to trigger this event:
 * 1. Fires when a user clicks to add a Learn Course certificate to their Linkedin, with the following property values:
feature_type = 'library'
activity_type = 'linkedin_certificate_added'
content_type = 'course'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/BWAF8mtaTw/trigger/3cc0rR0fU
 * 2. Fires when a user clicks to add a Learn Lesson certificate to their Linkedin, with the following property values:
feature_type = 'library'
activity_type = 'linkedin_certificate_added'
content_type = 'lesson'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/BWAF8mtaTw/trigger/3C_NI5c5D
 * 3. Fires when a user clicks the share link on a Learn Course widget.
feature_type = 'library'
activity_type = 'url_copied'
content_type = 'course'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/BWAF8mtaTw/trigger/-OEYo8dT7
 * 4. When a user shares a personal reset with everyone in their company.

content_type: reset
feature_type: content
activity_type: reset_shared
reset_type: personal_reset

add content property bundle:
content_id: <reset id>
content_title: <reset name>
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/BWAF8mtaTw/trigger/9PUF7VHMO
 * 5. Fires when a user clicks the share link on a Library content widget.
feature_type = 'library'
activity_type = 'url_copied'
page_section = ['recommended', 'bookmarks', 'popular', 'featured_article]
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/BWAF8mtaTw/trigger/4WdFfOlvW
 * 6. When a user clicks the link to share after the Reset plays in the Reset Player.

feature_type: content
activity_type: reset_shared
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/BWAF8mtaTw/trigger/Xlj_2BEc8
 *
 * @param properties the properties associatied with this event
 * @param properties.contentId: Recommended Content ID including external resources
 * @param properties.contentType: The type of content (reset, video, article etc.) in English
 * @param properties.contentTitle: The title of the content (Resets, Articles, Videos etc.) including external resources as well
 * @param properties.contentSubtype: Secondary type based on the content type:
Articles: role_model | recipe | watch_me_thrive | article

 * @param properties.contentFormatType: The format of the content displayed/consumed.

Articles: audio | video | text
Resets: audio | video
External resources: pdf | audio | video | text
 * @param properties.contentUrl: This is the URL of the content:
- If it is an iframe rendered - it would be the iframe full URL
- If it is an external resource (such as benefit connect / sequoia links) - it would be the external full URL

example:
https://thriveglobal.showpad.com/share/cpCAwJwBjxiFfegfWnIHj
https://login.sequoia.com/
 * @param properties.contentSource: Describes the source of the content or elements within the content (UMG audio for personal resets for example)
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.resetType: Types of reset
 * @param properties.pageSection: Describes the part of the page the feature exists on. For example, on the Microstep Hub, microsteps completed from the 'Explore more' section have value 'explore_more'
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/BWAF8mtaTw}
 */
export function contentShared(properties: ContentSharedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.contentId !== undefined && properties.contentId !== null ?
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: properties.contentId}) :
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: null});
  properties.contentType !== undefined && properties.contentType !== null ?
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: properties.contentType}) :
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: null});
  properties.contentTitle !== undefined && properties.contentTitle !== null ?
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: properties.contentTitle}) :
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: null});
  properties.contentSubtype !== undefined && properties.contentSubtype !== null ?
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: properties.contentSubtype}) :
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: null});
  properties.contentFormatType !== undefined && properties.contentFormatType !== null ?
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: properties.contentFormatType}) :
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: null});
  properties.contentUrl !== undefined && properties.contentUrl !== null ?
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: properties.contentUrl}) :
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: null});
  properties.contentSource !== undefined && properties.contentSource !== null ?
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: properties.contentSource}) :
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: null});
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.resetType !== undefined && properties.resetType !== null ?
    eventPropertiesArray.push({id: "A3cfeXxyUCBc", name: "reset_type", value: properties.resetType}) :
    eventPropertiesArray.push({id: "A3cfeXxyUCBc", name: "reset_type", value: null});
  eventPropertiesArray.push({id: "BJLmtLwLl", name: "page_section", value: properties.pageSection});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "BWAF8mtaTw", "a09ac9cc84ad8c05f033cde8221e7f9c349924d2f8c88af0eae0d8f8060397b0", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("content_shared", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("BWAF8mtaTw", "content_shared", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("content_shared", eventProperties, "BWAF8mtaTw", "a09ac9cc84ad8c05f033cde8221e7f9c349924d2f8c88af0eae0d8f8060397b0");
    }
    // destination Segment
    Segment.logEvent("content_shared", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface MicrostepRemovedProperties {
  microstepId: string;
  microstepBody: string | null | undefined;
  isAutomaticCheckin: boolean;
  microstepName: string;
  checkInDate: string;
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  removalReason: RemovalReasonValueType;
}
/**
 * microstep_removed: This event fires when a user removes a microstep from their profile.
 *
 * When to trigger this event:
 * 1. When a user removes a microstep from their profile
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/bT3Fo6YKC/trigger/s0G0HwqSG
 * 2. When a user removes a microstep from their saved microsteps from the Microsteps Hub

feature_type: library
activity_type: microstep_management
removal_reason: [not_relevant/now_habit]
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/bT3Fo6YKC/trigger/r-V73Bid-
 *
 * @param properties the properties associatied with this event
 * @param properties.microstepId: The Microstep ID
 * @param properties.microstepBody: The body of the Microstep that tells us what Microstep is being checked into.
 * @param properties.isAutomaticCheckin: Flag to distinguish between automatic Microstep Check-Ins and manual ones.
 * @param properties.microstepName: Name of the checked in microstep
 * @param properties.checkInDate: The date the automated checkin occurred. (YYYY-DD-MM format)
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.removalReason: User-given reason for the removal of the content from their favorites or library.
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/bT3Fo6YKC}
 */
export function microstepRemoved(properties: MicrostepRemovedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "fBtUkEzBban-", name: "microstep_id", value: properties.microstepId});
  properties.microstepBody !== undefined && properties.microstepBody !== null ?
    eventPropertiesArray.push({id: "YMHMzP_Nb", name: "microstep_body", value: properties.microstepBody}) :
    eventPropertiesArray.push({id: "YMHMzP_Nb", name: "microstep_body", value: null});
  eventPropertiesArray.push({id: "Ftso3m8Ty", name: "is_automatic_checkin", value: properties.isAutomaticCheckin});
  eventPropertiesArray.push({id: "sWRRlPTJjjSi", name: "microstep_name", value: properties.microstepName});
  eventPropertiesArray.push({id: "oxawdlke-", name: "check_in_date", value: properties.checkInDate});
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "w9Ho9Bhmj_fo", name: "removal_reason", value: properties.removalReason});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "bT3Fo6YKC", "a59f08f5ebe4b1d50c4590db72d4aa70e69516e5e3bca45428b5c4cc5599a50a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("microstep_removed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("bT3Fo6YKC", "microstep_removed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("microstep_removed", eventProperties, "bT3Fo6YKC", "a59f08f5ebe4b1d50c4590db72d4aa70e69516e5e3bca45428b5c4cc5599a50a");
    }
    // destination Segment
    Segment.logEvent("microstep_removed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface MicrostepAddedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  microstepId: string;
  tabName: TabNameValueType | null | undefined;
}
/**
 * microstep_added: When a user adds a microstep to their profile
 *
 * When to trigger this event:
 * 1. When a user clicks on the + to add a microstep to their profilee
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/HICRrhOHYK/trigger/7FQNMZ2pj
 * 2. When a user saves a Microstep from the Microstep Hub

feature_type: library
activity_type: microstep_management
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/HICRrhOHYK/trigger/qDEWovsHJ
 * 3. When a user saves a Microstep from the Microstep Hub

feature_type: library
activity_type: microstep_management
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/HICRrhOHYK/trigger/KD3UKIIe4
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.microstepId: The Microstep ID
 * @param properties.tabName: The tab on which the Slack or MS Teams event took place. This is limited to Home Chat and About for now
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/HICRrhOHYK}
 */
export function microstepAdded(properties: MicrostepAddedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "fBtUkEzBban-", name: "microstep_id", value: properties.microstepId});
  properties.tabName !== undefined && properties.tabName !== null ?
    eventPropertiesArray.push({id: "notiOkO1a", name: "tab_name", value: properties.tabName}) :
    eventPropertiesArray.push({id: "notiOkO1a", name: "tab_name", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "HICRrhOHYK", "9002d0461bacd8b3abab5f0d690476d40f1373b06e35cfb4a6d9ddc158374d84", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("microstep_added", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("HICRrhOHYK", "microstep_added", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("microstep_added", eventProperties, "HICRrhOHYK", "9002d0461bacd8b3abab5f0d690476d40f1373b06e35cfb4a6d9ddc158374d84");
    }
    // destination Segment
    Segment.logEvent("microstep_added", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface MicrostepCheckinCompletedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  microstepId: string;
  microstepBody: string | null | undefined;
  isAutomaticCheckin: boolean | null | undefined;
  microstepName: string;
  checkInDate: string | null | undefined;
  challengeId: string | null | undefined;
  dayNumber: number | null | undefined;
  teamId: string | null | undefined;
  teamType: TeamTypeValueType | null | undefined;
  challengeTheme: string | null | undefined;
  challengeType: string | null | undefined;
  journey: string | null | undefined;
  journeyId: string | null | undefined;
  journeyLevel: number | null | undefined;
  journeyDay: number | null | undefined;
  firstJourney: boolean | null | undefined;
  isOnboarding: boolean | null | undefined;
  tabName: TabNameValueType | null | undefined;
  pageSection: PageSectionValueType | null | undefined;
}
/**
 * microstep_checkin_completed: When a user clicks to check into a microstep.
 *
 * When to trigger this event:
 * 1. When a user clicks to check into a microstep

Properties to include:
feature_type: library
activity_type: microstep_management
microstep_id:
microstep_name:
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/bESOLLcxa7/trigger/Sjz89C92V
 * 2. When a user checks into a Challenge Microstep

Properties to include:
feature_type: challenge
activity_type: challenge_microstep
challenge_id:
challenge_name:
microstep_id:
microstep_name:
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/bESOLLcxa7/trigger/ybI7Btvyf
 * 3. feature_type = 'journeys'
activity_type = 'journey_microstep'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/bESOLLcxa7/trigger/FKpq8Ha9AJ
 * 4. When a user checks/does a microstep checkin from the company/group challenge

feature_type:challenge
activity_type:daily_activity
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/bESOLLcxa7/trigger/QQM30eiZ6
 * 5. Fires when a biometrics users checks into a microstep automatically by reaching their step count.
feature_type = 'challenges'
activity_type = 'challenge_microstep'
is_automatic_checkin = true
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/bESOLLcxa7/trigger/eYy3gFgeo
 * 6. feature_type = thrive_guide
activity_type = thrive_guide_recommendation
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/bESOLLcxa7/trigger/n1BmpQiKn
 * 7. When a user checks into today's microstep(s) in the Webex embedded app

feature_type: journeys
activity_type: today_microstep_checkin
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/bESOLLcxa7/trigger/SAeuxRgF-
 * 8. When a user checks into microsteps in the microstep hub

feature_type: library
activity_type: microstep_management
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/bESOLLcxa7/trigger/Nvqg1m3t4
 * 9. When a user selects a Microstep from the Microstep browser, this window opens up. This event fires when a user completes a Microstep check in.

feature_type: library
activity_type: microstep_management
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/bESOLLcxa7/trigger/JA4c6Wim0
 * 10. Fires when a user checks in to one of their Yesterday's microstep(s).
feature_type = 'journey'
activity_type = 'yesterday_journey_microstep'
+microstep_properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/bESOLLcxa7/trigger/IsiXe2ySC
 * 11. When a user completes a Microstep by clicking the checkmark button. The 'page_section' property also captures the section the Microstep is contained in.

feature_type: 'library'
activity_type: 'microstep_management'
page_section:
	red = 'try_now'
	green = 'favorites'
	blue = 'explore_more'

Microstep checkins from the "Favorites" tab should also contain page section = favorites
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/bESOLLcxa7/trigger/5aGukusIy
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.microstepId: The Microstep ID
 * @param properties.microstepBody: The body of the Microstep that tells us what Microstep is being checked into.
 * @param properties.isAutomaticCheckin: Flag to distinguish between automatic Microstep Check-Ins and manual ones.
 * @param properties.microstepName: Name of the checked in microstep
 * @param properties.checkInDate: The date the automated checkin occurred. (YYYY-DD-MM format)
 * @param properties.challengeId: id of the challenge being completed
 * @param properties.dayNumber: the day number of any event (be it journey level day number, challenge day number etc.)
 * @param properties.teamId: Id of the team the user belongs to. (this is the equivalent of social_group_id)
 * @param properties.teamType: team type for the company challenge events. Values can be 'individual' or 'team'.
 * @param properties.challengeTheme: the type of company challenge (e.g., hydration, movement)
 * @param properties.challengeType: the type of challenge (e.g., journey, biotype, company, group)
 * @param properties.journey: Name of the journey
 * @param properties.journeyId: Id of the journey
 * @param properties.journeyLevel: Level of the journey that a user is on
 * @param properties.journeyDay: The day of the journey that a user is on.
 * @param properties.firstJourney: True if journey is the user's first journey selection.
 * @param properties.isOnboarding: T/F flag for whether an event occurred during onboarding or not.
 * @param properties.tabName: The tab on which the Slack or MS Teams event took place. This is limited to Home Chat and About for now
 * @param properties.pageSection: Describes the part of the page the feature exists on. For example, on the Microstep Hub, microsteps completed from the 'Explore more' section have value 'explore_more'
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/bESOLLcxa7}
 */
export function microstepCheckinCompleted(
  properties: MicrostepCheckinCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "fBtUkEzBban-", name: "microstep_id", value: properties.microstepId});
  properties.microstepBody !== undefined && properties.microstepBody !== null ?
    eventPropertiesArray.push({id: "YMHMzP_Nb", name: "microstep_body", value: properties.microstepBody}) :
    eventPropertiesArray.push({id: "YMHMzP_Nb", name: "microstep_body", value: null});
  properties.isAutomaticCheckin !== undefined && properties.isAutomaticCheckin !== null ?
    eventPropertiesArray.push({id: "Ftso3m8Ty", name: "is_automatic_checkin", value: properties.isAutomaticCheckin}) :
    eventPropertiesArray.push({id: "Ftso3m8Ty", name: "is_automatic_checkin", value: null});
  eventPropertiesArray.push({id: "sWRRlPTJjjSi", name: "microstep_name", value: properties.microstepName});
  properties.checkInDate !== undefined && properties.checkInDate !== null ?
    eventPropertiesArray.push({id: "oxawdlke-", name: "check_in_date", value: properties.checkInDate}) :
    eventPropertiesArray.push({id: "oxawdlke-", name: "check_in_date", value: null});
  properties.challengeId !== undefined && properties.challengeId !== null ?
    eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: properties.challengeId}) :
    eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: null});
  properties.dayNumber !== undefined && properties.dayNumber !== null ?
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: properties.dayNumber}) :
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: null});
  properties.teamId !== undefined && properties.teamId !== null ?
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: properties.teamId}) :
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: null});
  properties.teamType !== undefined && properties.teamType !== null ?
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: properties.teamType}) :
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: null});
  properties.challengeTheme !== undefined && properties.challengeTheme !== null ?
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: properties.challengeTheme}) :
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: null});
  properties.challengeType !== undefined && properties.challengeType !== null ?
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: properties.challengeType}) :
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: null});
  properties.journey !== undefined && properties.journey !== null ?
    eventPropertiesArray.push({id: "F3BjAMmBpGqj", name: "journey", value: properties.journey}) :
    eventPropertiesArray.push({id: "F3BjAMmBpGqj", name: "journey", value: null});
  properties.journeyId !== undefined && properties.journeyId !== null ?
    eventPropertiesArray.push({id: "lvwXMqzXMp9C", name: "journey_id", value: properties.journeyId}) :
    eventPropertiesArray.push({id: "lvwXMqzXMp9C", name: "journey_id", value: null});
  properties.journeyLevel !== undefined && properties.journeyLevel !== null ?
    eventPropertiesArray.push({id: "HtZ0qgLqVsP6", name: "journey_level", value: properties.journeyLevel}) :
    eventPropertiesArray.push({id: "HtZ0qgLqVsP6", name: "journey_level", value: null});
  properties.journeyDay !== undefined && properties.journeyDay !== null ?
    eventPropertiesArray.push({id: "StQnPpWOrG18", name: "journey_day", value: properties.journeyDay}) :
    eventPropertiesArray.push({id: "StQnPpWOrG18", name: "journey_day", value: null});
  properties.firstJourney !== undefined && properties.firstJourney !== null ?
    eventPropertiesArray.push({id: "ktrvZyV0Q-nn", name: "first_journey", value: properties.firstJourney}) :
    eventPropertiesArray.push({id: "ktrvZyV0Q-nn", name: "first_journey", value: null});
  properties.isOnboarding !== undefined && properties.isOnboarding !== null ?
    eventPropertiesArray.push({id: "RCLdkrcD0", name: "is_onboarding", value: properties.isOnboarding}) :
    eventPropertiesArray.push({id: "RCLdkrcD0", name: "is_onboarding", value: null});
  properties.tabName !== undefined && properties.tabName !== null ?
    eventPropertiesArray.push({id: "notiOkO1a", name: "tab_name", value: properties.tabName}) :
    eventPropertiesArray.push({id: "notiOkO1a", name: "tab_name", value: null});
  properties.pageSection !== undefined && properties.pageSection !== null ?
    eventPropertiesArray.push({id: "BJLmtLwLl", name: "page_section", value: properties.pageSection}) :
    eventPropertiesArray.push({id: "BJLmtLwLl", name: "page_section", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertJourneyLevelOptional(properties.journeyLevel));
    messages = messages.concat(assertJourneyDayOptional(properties.journeyDay));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "bESOLLcxa7", "352f368a1997f13f739cebb2fc2035dec710b6226d7c35c0023a1065e9e24f24", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("microstep_checkin_completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("bESOLLcxa7", "microstep_checkin_completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'microstep_checkin_completed': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("microstep_checkin_completed", eventProperties, "bESOLLcxa7", "352f368a1997f13f739cebb2fc2035dec710b6226d7c35c0023a1065e9e24f24");
    }
    // destination Segment
    Segment.logEvent("microstep_checkin_completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface MicrostepCheckinRemovedProperties {
  microstepId: string;
  microstepBody: string | null | undefined;
  isAutomaticCheckin: boolean;
  microstepName: string;
  checkInDate: string;
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
}
/**
 * microstep_checkin_removed: When a user clicks to undo a microstep check in.
 *
 * When to trigger this event:
 * 1. When a user clicks again to undo a microstep check in
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/JzDoX0q7Vd/trigger/ereLhFvOc
 *
 * @param properties the properties associatied with this event
 * @param properties.microstepId: The Microstep ID
 * @param properties.microstepBody: The body of the Microstep that tells us what Microstep is being checked into.
 * @param properties.isAutomaticCheckin: Flag to distinguish between automatic Microstep Check-Ins and manual ones.
 * @param properties.microstepName: Name of the checked in microstep
 * @param properties.checkInDate: The date the automated checkin occurred. (YYYY-DD-MM format)
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/JzDoX0q7Vd}
 */
export function microstepCheckinRemoved(
  properties: MicrostepCheckinRemovedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "fBtUkEzBban-", name: "microstep_id", value: properties.microstepId});
  properties.microstepBody !== undefined && properties.microstepBody !== null ?
    eventPropertiesArray.push({id: "YMHMzP_Nb", name: "microstep_body", value: properties.microstepBody}) :
    eventPropertiesArray.push({id: "YMHMzP_Nb", name: "microstep_body", value: null});
  eventPropertiesArray.push({id: "Ftso3m8Ty", name: "is_automatic_checkin", value: properties.isAutomaticCheckin});
  eventPropertiesArray.push({id: "sWRRlPTJjjSi", name: "microstep_name", value: properties.microstepName});
  eventPropertiesArray.push({id: "oxawdlke-", name: "check_in_date", value: properties.checkInDate});
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "JzDoX0q7Vd", "f2c74d983cef1265a714ea1c56405165a5f0edc2ab6eb0f7f8f3b81a826d5931", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("microstep_checkin_removed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("JzDoX0q7Vd", "microstep_checkin_removed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("microstep_checkin_removed", eventProperties, "JzDoX0q7Vd", "f2c74d983cef1265a714ea1c56405165a5f0edc2ab6eb0f7f8f3b81a826d5931");
    }
    // destination Segment
    Segment.logEvent("microstep_checkin_removed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ReactionSubmittedProperties {
  feedbackAnswer: FeedbackAnswerValueType | null | undefined;
  feedbackQuestion: string | null | undefined;
  openAnswer: string | null | undefined;
  openQuestion: string | null | undefined;
  contentId: string | null | undefined;
  contentType: ContentTypeValueType | null | undefined;
  contentTitle: string | null | undefined;
  contentSubtype: ContentSubtypeValueType | null | undefined;
  contentFormatType: ContentFormatTypeValueType | null | undefined;
  contentUrl: string | null | undefined;
  contentSource: ContentSourceValueType | null | undefined;
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  conversationId: string | null | undefined;
  messageType: MessageTypeValueType | null | undefined;
  transactionId: string | null | undefined;
  resetType: ResetTypeValueType | null | undefined;
  tabName: TabNameValueType | null | undefined;
}
/**
 * reaction_submitted: When a user reacts to a piece of content or something from their social feed.
 *
 * When to trigger this event:
 * 1. When a user clicks to "cheer" (clap) a reset
feature_type: content
activity_type: clap_submitted
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/N1F3v4K2_z/trigger/WZYnJyazm
 * 2. When a user clicks the heart to favorite a reset.
feature_type: content
activity_type: favorite_submitted
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/N1F3v4K2_z/trigger/iMXc_TjiF
 * 3.
When a user clicks the clap icon to like an article
feature_type: library
content_type: article
activity_type: clap_submitted

Include the content properties:
content_id: <article id>
content_title: <article title>
content_subtype: role_model | recipe | watch_me_thrive | article
content_format_type: audio | video | text
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/N1F3v4K2_z/trigger/YTFN3ZlML
 * 4. When a user clicks the clap icon to like a podcast episode
feature_type: library
content_type: podcast_episode
activity_type: clap_submitted

Include the content properties:
content_id: <podcast episode id>
content_title: <podcast episode title>
content_format_type: audio
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/N1F3v4K2_z/trigger/LOHO1S_5A
 * 5. When a user successfully submitted a feedback at the end of a lesson.
feature_type: library
content_type: lesson
activity_type: feedback_submitted

Include content properties:
content_id: <lesson id>
content_title: <lesson name>
content_format_type: audio | video

Include feedback properties:
feedback_answer:
feedback_question:
open_question:
open_answer:
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/N1F3v4K2_z/trigger/RnISXrn5v
 * 6. When a user selected "cheer" on a reset

feature_type: content
activity_type: clap_submitted
content_type: reset
reset_type: personal_reset | reset_banner | reset

add content properties bundle:
content_id: <reset_id>
content_title: <reset_name>
content_format_type: audio | video
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/N1F3v4K2_z/trigger/QcfjPu1Zw
 * 7. When a user selected "heart" on a reset

feature_type: content
activity_type: favorite_submitted
content_type: reset
reset_type: personal_reset | reset_banner | reset

add content properties bundle:
content_id: <reset_id>
content_title: <reset_name>
content_format_type: audio | video
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/N1F3v4K2_z/trigger/5oS9nfdAm
 * 8. Fired when a user reacts to activity in their challenge feed in a group challenge.
feature_type: challenge
activity_type: clap_submitted
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/N1F3v4K2_z/trigger/I63rjnaVS
 * 9. feature_type = 'community'
activity_type = 'clap_submitted'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/N1F3v4K2_z/trigger/25OUcn0B7
 * 10. feature_type = library
activity_type = recommended_content_served
feedback_answer = 'positive/negative'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/N1F3v4K2_z/trigger/FDwkevI06
 * 11. When a user reacted to a Thrive Guide bot response

feature_type: thrive_guide
activity_type: feedback_submitted

Add conversation property bundle metadata
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/N1F3v4K2_z/trigger/cfwiT9Cc7
 * 12. Fires when a user selects the Thumbs Up/Thumbs Down reaction on a microstep.
feature_type = 'journeys'
activity_type = 'microstep_like_submitted'|'microstep_dislike_submitted'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/N1F3v4K2_z/trigger/pINfh25tD
 * 13. When a user dislikes a microstep

feature_type: library
activity_type: microstep_reaction
feedback_answer: negative
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/N1F3v4K2_z/trigger/MLsQmS-2M
 * 14. When a user likes a microstep

feature_type: library
activity_type: microstep_reaction
feedback_answer: positive
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/N1F3v4K2_z/trigger/JSBazHdkp
 * 15. When a user clicks the thumbs up after the Reset in the Reset Player.

feature_type: content
activity_type: reset_like_submitted
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/N1F3v4K2_z/trigger/07Bnz0u8M
 * 16. When a user clicks the thumbs down after the Reset in the Reset Player.

feature_type: content
activity_type: reset_dislike_submitted
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/N1F3v4K2_z/trigger/JRgHlIGXI
 * 17. When a user clicks the heart to favorite a Reset after the Reset plays in the Reset Player.

feature_type: content
activity_type: favorite_submitted
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/N1F3v4K2_z/trigger/YFe9ala19
 *
 * @param properties the properties associatied with this event
 * @param properties.feedbackAnswer: Captures feedback submitted by the user. Currently is only set up to take values 'positive' and 'negative' .
 * @param properties.feedbackQuestion: The feedback question shared with a user. User's response to this question is captured in the feedback_asnwer property
 * @param properties.openAnswer: Open Ended Question Answer (user input in free form text) - used in surveys with open-ended question
 * @param properties.openQuestion: Open Ended question shared with a user. User's response to this question is captured in the open_answer property
 * @param properties.contentId: Recommended Content ID including external resources
 * @param properties.contentType: The type of content (reset, video, article etc.) in English
 * @param properties.contentTitle: The title of the content (Resets, Articles, Videos etc.) including external resources as well
 * @param properties.contentSubtype: Secondary type based on the content type:
Articles: role_model | recipe | watch_me_thrive | article

 * @param properties.contentFormatType: The format of the content displayed/consumed.

Articles: audio | video | text
Resets: audio | video
External resources: pdf | audio | video | text
 * @param properties.contentUrl: This is the URL of the content:
- If it is an iframe rendered - it would be the iframe full URL
- If it is an external resource (such as benefit connect / sequoia links) - it would be the external full URL

example:
https://thriveglobal.showpad.com/share/cpCAwJwBjxiFfegfWnIHj
https://login.sequoia.com/
 * @param properties.contentSource: Describes the source of the content or elements within the content (UMG audio for personal resets for example)
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.conversationId: For the thrive guide: This is the conversation 'session' id from the thrive guide bot.
 * @param properties.messageType: When the chatbot interacts with the user, it can have two types of text messages: question or a recommendation.

When the user interacts with the chatbot, there are 2 types of messages: free-form text response, multiple choice response
 * @param properties.transactionId: For the a chatbot events, this id will join each conversation with its corresponding BE data.
 * @param properties.resetType: Types of reset
 * @param properties.tabName: The tab on which the Slack or MS Teams event took place. This is limited to Home Chat and About for now
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/N1F3v4K2_z}
 */
export function reactionSubmitted(properties: ReactionSubmittedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.feedbackAnswer !== undefined && properties.feedbackAnswer !== null ?
    eventPropertiesArray.push({id: "frOxH-fc-", name: "feedback_answer", value: properties.feedbackAnswer}) :
    eventPropertiesArray.push({id: "frOxH-fc-", name: "feedback_answer", value: null});
  properties.feedbackQuestion !== undefined && properties.feedbackQuestion !== null ?
    eventPropertiesArray.push({id: "xO23kcNWB", name: "feedback_question", value: properties.feedbackQuestion}) :
    eventPropertiesArray.push({id: "xO23kcNWB", name: "feedback_question", value: null});
  properties.openAnswer !== undefined && properties.openAnswer !== null ?
    eventPropertiesArray.push({id: "K6V8-4Smq", name: "open_answer", value: properties.openAnswer}) :
    eventPropertiesArray.push({id: "K6V8-4Smq", name: "open_answer", value: null});
  properties.openQuestion !== undefined && properties.openQuestion !== null ?
    eventPropertiesArray.push({id: "hb-D3dMaN", name: "open_question", value: properties.openQuestion}) :
    eventPropertiesArray.push({id: "hb-D3dMaN", name: "open_question", value: null});
  properties.contentId !== undefined && properties.contentId !== null ?
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: properties.contentId}) :
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: null});
  properties.contentType !== undefined && properties.contentType !== null ?
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: properties.contentType}) :
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: null});
  properties.contentTitle !== undefined && properties.contentTitle !== null ?
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: properties.contentTitle}) :
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: null});
  properties.contentSubtype !== undefined && properties.contentSubtype !== null ?
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: properties.contentSubtype}) :
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: null});
  properties.contentFormatType !== undefined && properties.contentFormatType !== null ?
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: properties.contentFormatType}) :
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: null});
  properties.contentUrl !== undefined && properties.contentUrl !== null ?
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: properties.contentUrl}) :
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: null});
  properties.contentSource !== undefined && properties.contentSource !== null ?
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: properties.contentSource}) :
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: null});
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.conversationId !== undefined && properties.conversationId !== null ?
    eventPropertiesArray.push({id: "RwgnWaMMW", name: "conversation_id", value: properties.conversationId}) :
    eventPropertiesArray.push({id: "RwgnWaMMW", name: "conversation_id", value: null});
  properties.messageType !== undefined && properties.messageType !== null ?
    eventPropertiesArray.push({id: "pcbtFdgiJ", name: "message_type", value: properties.messageType}) :
    eventPropertiesArray.push({id: "pcbtFdgiJ", name: "message_type", value: null});
  properties.transactionId !== undefined && properties.transactionId !== null ?
    eventPropertiesArray.push({id: "Mi1dgAO5M", name: "transaction_id", value: properties.transactionId}) :
    eventPropertiesArray.push({id: "Mi1dgAO5M", name: "transaction_id", value: null});
  properties.resetType !== undefined && properties.resetType !== null ?
    eventPropertiesArray.push({id: "A3cfeXxyUCBc", name: "reset_type", value: properties.resetType}) :
    eventPropertiesArray.push({id: "A3cfeXxyUCBc", name: "reset_type", value: null});
  properties.tabName !== undefined && properties.tabName !== null ?
    eventPropertiesArray.push({id: "notiOkO1a", name: "tab_name", value: properties.tabName}) :
    eventPropertiesArray.push({id: "notiOkO1a", name: "tab_name", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "N1F3v4K2_z", "5aa26dc3de8b385b9e31a16052b4adeb8510a7696c0d71ee39dc8ca51245d65c", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("reaction_submitted", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("N1F3v4K2_z", "reaction_submitted", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("reaction_submitted", eventProperties, "N1F3v4K2_z", "5aa26dc3de8b385b9e31a16052b4adeb8510a7696c0d71ee39dc8ca51245d65c");
    }
    // destination Segment
    Segment.logEvent("reaction_submitted", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SubmissionStartedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  contentId: string | null | undefined;
  contentType: ContentTypeValueType | null | undefined;
  contentTitle: string | null | undefined;
  contentSubtype: ContentSubtypeValueType | null | undefined;
  contentFormatType: ContentFormatTypeValueType | null | undefined;
  contentUrl: string | null | undefined;
  contentSource: ContentSourceValueType | null | undefined;
  challengeId: string | null | undefined;
  dayNumber: number | null | undefined;
  teamId: string | null | undefined;
  teamType: TeamTypeValueType | null | undefined;
  challengeTheme: string | null | undefined;
  challengeType: string | null | undefined;
}
/**
 * submission_started:  When a user begins a submission process as part of a product feature such as role model stories.
Not related to user's sentiment about the product such as survey feedback / reaction to a feature /  conversations with Thrive Guide.
 *
 * When to trigger this event:
 * 1. Web trigger: when a user opens up the story submission form within the Role Models Library.

Includes the following property values:
feature_type = 'library'
activity_type = 'story_submission_form_opened'
content_type = 'role_model'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/m9YEler1_R/trigger/PC4R5zmWg
 * 2. Web trigger: When a user successfully uploaded a photo on the story submission form.

Includes the following property values:
feature_type = 'library'
activity_type = 'story_photo_uploaded'
content_type = <content_type_enums>

If this form was opened after a user completed a content, these properties should be populated as such:
content_id = <content uuid from Strapi based on the content type such as lesson_id>
content_title = <content title based on the content type such as lesson_name>
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/m9YEler1_R/trigger/8Aflysii5
 * 3. Web trigger: When the story submission form loads after a user clicked on "share success story" at the end of a lesson.

Includes the following property values:
feature_type = 'library'
activity_type = 'story_submission_form_opened'
content_type = 'lesson'

If this form was opened after a user completed a content, these properties should be populated as such:
content_id = <content uuid from Strapi based on the content type such as lesson_id>
content_title = <content title based on the content type such as lesson_name>
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/m9YEler1_R/trigger/2GSKaLh1h
 * 4. Web trigger: When a user successfully uploaded a photo on the story submission form after completing a content.

Includes the following property values:
feature_type = 'library'
activity_type = 'story_photo_uploaded'
content_type = <content_type_enums>

If this form was opened after a user completed a content, these properties should be populated as such:
content_id = <content uuid from Strapi based on the content type such as lesson_id>
content_title = <content title based on the content type such as lesson_name>
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/m9YEler1_R/trigger/JDt7638Jd
 * 5. Web trigger: When a user provided consent on the story submission form

Includes the following property values:
feature_type = 'library'
activity_type = 'story_consent_given'
content_type = <content_type_enums>

If this form was opened after a user completed a content, these properties should be populated as such:
content_id = <content uuid from Strapi based on the content type such as lesson_id>
content_title = <content title based on the content type such as lesson_name>
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/m9YEler1_R/trigger/3uxuOepT7
 * 6. When a user adds an answer to their challenge story submission
feature_type: challenge
activity_type: story_answer_added

**Need to complete challenge to provide screenshot
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/m9YEler1_R/trigger/DsvNu9WOM
 * 7. Fired when a user cancels their story submission
feature_type: challenge
activity_type: story_cancelled

**need to finish challenge to get screenshot
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/m9YEler1_R/trigger/XNkEgUet6
 * 8. Fired when a user adds a photo to their story
feature_type: challenge
activity_type: story_photo_uploaded

** Need to complete challenge to get screenshots
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/m9YEler1_R/trigger/JtYouNuN0
 * 9. Fired when a user clicks to start their story
feature_type: challenge
activity_type: story_submission_started

** Need to finish challenge to add screenshots
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/m9YEler1_R/trigger/sbV43oCDQ
 * 10. Fired when a user submits a blank story
feature_type:challenge
activity_type: story_no_data

** Need to complete challenge to get screenshot
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/m9YEler1_R/trigger/kRH0BR697
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.contentId: Recommended Content ID including external resources
 * @param properties.contentType: The type of content (reset, video, article etc.) in English
 * @param properties.contentTitle: The title of the content (Resets, Articles, Videos etc.) including external resources as well
 * @param properties.contentSubtype: Secondary type based on the content type:
Articles: role_model | recipe | watch_me_thrive | article

 * @param properties.contentFormatType: The format of the content displayed/consumed.

Articles: audio | video | text
Resets: audio | video
External resources: pdf | audio | video | text
 * @param properties.contentUrl: This is the URL of the content:
- If it is an iframe rendered - it would be the iframe full URL
- If it is an external resource (such as benefit connect / sequoia links) - it would be the external full URL

example:
https://thriveglobal.showpad.com/share/cpCAwJwBjxiFfegfWnIHj
https://login.sequoia.com/
 * @param properties.contentSource: Describes the source of the content or elements within the content (UMG audio for personal resets for example)
 * @param properties.challengeId: id of the challenge being completed
 * @param properties.dayNumber: the day number of any event (be it journey level day number, challenge day number etc.)
 * @param properties.teamId: Id of the team the user belongs to. (this is the equivalent of social_group_id)
 * @param properties.teamType: team type for the company challenge events. Values can be 'individual' or 'team'.
 * @param properties.challengeTheme: the type of company challenge (e.g., hydration, movement)
 * @param properties.challengeType: the type of challenge (e.g., journey, biotype, company, group)
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/m9YEler1_R}
 */
export function submissionStarted(properties: SubmissionStartedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.contentId !== undefined && properties.contentId !== null ?
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: properties.contentId}) :
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: null});
  properties.contentType !== undefined && properties.contentType !== null ?
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: properties.contentType}) :
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: null});
  properties.contentTitle !== undefined && properties.contentTitle !== null ?
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: properties.contentTitle}) :
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: null});
  properties.contentSubtype !== undefined && properties.contentSubtype !== null ?
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: properties.contentSubtype}) :
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: null});
  properties.contentFormatType !== undefined && properties.contentFormatType !== null ?
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: properties.contentFormatType}) :
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: null});
  properties.contentUrl !== undefined && properties.contentUrl !== null ?
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: properties.contentUrl}) :
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: null});
  properties.contentSource !== undefined && properties.contentSource !== null ?
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: properties.contentSource}) :
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: null});
  properties.challengeId !== undefined && properties.challengeId !== null ?
    eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: properties.challengeId}) :
    eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: null});
  properties.dayNumber !== undefined && properties.dayNumber !== null ?
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: properties.dayNumber}) :
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: null});
  properties.teamId !== undefined && properties.teamId !== null ?
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: properties.teamId}) :
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: null});
  properties.teamType !== undefined && properties.teamType !== null ?
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: properties.teamType}) :
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: null});
  properties.challengeTheme !== undefined && properties.challengeTheme !== null ?
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: properties.challengeTheme}) :
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: null});
  properties.challengeType !== undefined && properties.challengeType !== null ?
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: properties.challengeType}) :
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "m9YEler1_R", "575ec2693debb906b824cd197729cee7a258436b8f9cc2e5837b48fe75b0d6a9", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("submission_started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("m9YEler1_R", "submission_started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("submission_started", eventProperties, "m9YEler1_R", "575ec2693debb906b824cd197729cee7a258436b8f9cc2e5837b48fe75b0d6a9");
    }
    // destination Segment
    Segment.logEvent("submission_started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SubmissionCompletedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  contentId: string | null | undefined;
  contentType: ContentTypeValueType | null | undefined;
  contentTitle: string | null | undefined;
  contentSubtype: ContentSubtypeValueType | null | undefined;
  contentFormatType: ContentFormatTypeValueType | null | undefined;
  contentUrl: string | null | undefined;
  contentSource: ContentSourceValueType | null | undefined;
  challengeId: string | null | undefined;
  dayNumber: number | null | undefined;
  teamId: string | null | undefined;
  teamType: TeamTypeValueType | null | undefined;
  challengeTheme: string | null | undefined;
  challengeType: string | null | undefined;
}
/**
 * submission_completed: When a user successfully submitted their response as part of a product feature such as role model stories. Not related to user's sentiment about the product such as survey feedback / reaction to a feature / conversations with Thrive Guide.
 *
 * When to trigger this event:
 * 1. Web trigger: When a user successfully submitted a story

Includes the following property values:
feature_type = 'library'
activity_type = 'story_submission_completed'
content_type = <content_type_enums>

If this form was opened after a user completed a content, these properties should be populated as such:
content_id = <content uuid from Strapi based on the content type such as lesson_id>
content_title = <content title based on the content type such as lesson_name>

If this form was submitted after completing a challenge, challenge properties should be populated:
feature_type: challenge
activity_type: story_submission_completed
challenge_id:
challenge_name:
...
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9SiCaefyzD/trigger/xSJ7UD_ul
 * 2. Fired when a user submits their story
feature_type: challenge
activity_type: story_submission_completed

** Need to complete challenge to get screenshots
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9SiCaefyzD/trigger/ms7I8CJ9D
 * 3. When a user successfully submitted a story after completing a challenge

feature_type: challenge
activity_type: story_submission_completed
challenge_id:
challenge_name:

(trigger image missing since you need to complete a challenge to submit a story, but the form is similar to a regular story submission form)
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9SiCaefyzD/trigger/USsjUHRfp
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.contentId: Recommended Content ID including external resources
 * @param properties.contentType: The type of content (reset, video, article etc.) in English
 * @param properties.contentTitle: The title of the content (Resets, Articles, Videos etc.) including external resources as well
 * @param properties.contentSubtype: Secondary type based on the content type:
Articles: role_model | recipe | watch_me_thrive | article

 * @param properties.contentFormatType: The format of the content displayed/consumed.

Articles: audio | video | text
Resets: audio | video
External resources: pdf | audio | video | text
 * @param properties.contentUrl: This is the URL of the content:
- If it is an iframe rendered - it would be the iframe full URL
- If it is an external resource (such as benefit connect / sequoia links) - it would be the external full URL

example:
https://thriveglobal.showpad.com/share/cpCAwJwBjxiFfegfWnIHj
https://login.sequoia.com/
 * @param properties.contentSource: Describes the source of the content or elements within the content (UMG audio for personal resets for example)
 * @param properties.challengeId: id of the challenge being completed
 * @param properties.dayNumber: the day number of any event (be it journey level day number, challenge day number etc.)
 * @param properties.teamId: Id of the team the user belongs to. (this is the equivalent of social_group_id)
 * @param properties.teamType: team type for the company challenge events. Values can be 'individual' or 'team'.
 * @param properties.challengeTheme: the type of company challenge (e.g., hydration, movement)
 * @param properties.challengeType: the type of challenge (e.g., journey, biotype, company, group)
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/9SiCaefyzD}
 */
export function submissionCompleted(properties: SubmissionCompletedProperties
  ) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.contentId !== undefined && properties.contentId !== null ?
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: properties.contentId}) :
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: null});
  properties.contentType !== undefined && properties.contentType !== null ?
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: properties.contentType}) :
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: null});
  properties.contentTitle !== undefined && properties.contentTitle !== null ?
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: properties.contentTitle}) :
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: null});
  properties.contentSubtype !== undefined && properties.contentSubtype !== null ?
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: properties.contentSubtype}) :
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: null});
  properties.contentFormatType !== undefined && properties.contentFormatType !== null ?
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: properties.contentFormatType}) :
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: null});
  properties.contentUrl !== undefined && properties.contentUrl !== null ?
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: properties.contentUrl}) :
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: null});
  properties.contentSource !== undefined && properties.contentSource !== null ?
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: properties.contentSource}) :
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: null});
  properties.challengeId !== undefined && properties.challengeId !== null ?
    eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: properties.challengeId}) :
    eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: null});
  properties.dayNumber !== undefined && properties.dayNumber !== null ?
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: properties.dayNumber}) :
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: null});
  properties.teamId !== undefined && properties.teamId !== null ?
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: properties.teamId}) :
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: null});
  properties.teamType !== undefined && properties.teamType !== null ?
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: properties.teamType}) :
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: null});
  properties.challengeTheme !== undefined && properties.challengeTheme !== null ?
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: properties.challengeTheme}) :
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: null});
  properties.challengeType !== undefined && properties.challengeType !== null ?
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: properties.challengeType}) :
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "9SiCaefyzD", "a57543fd0c4a16b30d039e395afa4d28e3213f4753f9bae847cbfed935c1bd66", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("submission_completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("9SiCaefyzD", "submission_completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("submission_completed", eventProperties, "9SiCaefyzD", "a57543fd0c4a16b30d039e395afa4d28e3213f4753f9bae847cbfed935c1bd66");
    }
    // destination Segment
    Segment.logEvent("submission_completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ChallengeGoalCompletedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  challengeId: string;
  dayNumber: number | null | undefined;
  teamId: string | null | undefined;
  teamType: TeamTypeValueType | null | undefined;
  challengeTheme: string | null | undefined;
  challengeType: string | null | undefined;
  challengeGoalId: string;
}
/**
 * challenge_goal_completed: Fires when all activities in a given day have been completed as part of a challenge
 *
 * When to trigger this event:
 * 1. Fires when all activities in a given day have been completed by the user as part of a challenge.
feature_type = 'challenge'
activity_type = 'daily_goals_completed'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/zjdfJr55p4/trigger/eCDf1cmhO
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.challengeId: id of the challenge being completed
 * @param properties.dayNumber: the day number of any event (be it journey level day number, challenge day number etc.)
 * @param properties.teamId: Id of the team the user belongs to. (this is the equivalent of social_group_id)
 * @param properties.teamType: team type for the company challenge events. Values can be 'individual' or 'team'.
 * @param properties.challengeTheme: the type of company challenge (e.g., hydration, movement)
 * @param properties.challengeType: the type of challenge (e.g., journey, biotype, company, group)
 * @param properties.challengeGoalId: The ID for the daily challenge goal.
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/zjdfJr55p4}
 */
export function challengeGoalCompleted(
  properties: ChallengeGoalCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: properties.challengeId});
  properties.dayNumber !== undefined && properties.dayNumber !== null ?
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: properties.dayNumber}) :
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: null});
  properties.teamId !== undefined && properties.teamId !== null ?
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: properties.teamId}) :
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: null});
  properties.teamType !== undefined && properties.teamType !== null ?
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: properties.teamType}) :
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: null});
  properties.challengeTheme !== undefined && properties.challengeTheme !== null ?
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: properties.challengeTheme}) :
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: null});
  properties.challengeType !== undefined && properties.challengeType !== null ?
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: properties.challengeType}) :
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: null});
  eventPropertiesArray.push({id: "ewpzRIElM", name: "challenge_goal_id", value: properties.challengeGoalId});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "zjdfJr55p4", "d679e1ff02f3d72a46e038cf538d98960d1c93552f1dfcd372247795d993d22c", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("challenge_goal_completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("zjdfJr55p4", "challenge_goal_completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("challenge_goal_completed", eventProperties, "zjdfJr55p4", "d679e1ff02f3d72a46e038cf538d98960d1c93552f1dfcd372247795d993d22c");
    }
    // destination Segment
    Segment.logEvent("challenge_goal_completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ChallengeStartedProperties {
  challengeId: string;
  dayNumber: number | null | undefined;
  teamId: string | null | undefined;
  teamType: TeamTypeValueType | null | undefined;
  challengeTheme: string | null | undefined;
  challengeType: string | null | undefined;
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  inviteSource: string | null | undefined;
  tabName: TabNameValueType | null | undefined;
}
/**
 * challenge_started: When a user starts a challenge.
 *
 * When to trigger this event:
 * 1. When a user joins a challenge

Properties:
feature_type: challenge
activity_type: challenge_started
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/WAl95_Nr77/trigger/6vG-ZQC1U
 * 2. Web: Fires when a user successfully completes signup (finishes onboarding flow, and will also fire when switch teams or solo)


feature_type: challenge
activity_type: challenge_started
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/WAl95_Nr77/trigger/RbzrknV4S
 *
 * @param properties the properties associatied with this event
 * @param properties.challengeId: id of the challenge being completed
 * @param properties.dayNumber: the day number of any event (be it journey level day number, challenge day number etc.)
 * @param properties.teamId: Id of the team the user belongs to. (this is the equivalent of social_group_id)
 * @param properties.teamType: team type for the company challenge events. Values can be 'individual' or 'team'.
 * @param properties.challengeTheme: the type of company challenge (e.g., hydration, movement)
 * @param properties.challengeType: the type of challenge (e.g., journey, biotype, company, group)
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.inviteSource: company challenges: how a user was invited
 * @param properties.tabName: The tab on which the Slack or MS Teams event took place. This is limited to Home Chat and About for now
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/WAl95_Nr77}
 */
export function challengeStarted(properties: ChallengeStartedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: properties.challengeId});
  properties.dayNumber !== undefined && properties.dayNumber !== null ?
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: properties.dayNumber}) :
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: null});
  properties.teamId !== undefined && properties.teamId !== null ?
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: properties.teamId}) :
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: null});
  properties.teamType !== undefined && properties.teamType !== null ?
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: properties.teamType}) :
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: null});
  properties.challengeTheme !== undefined && properties.challengeTheme !== null ?
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: properties.challengeTheme}) :
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: null});
  properties.challengeType !== undefined && properties.challengeType !== null ?
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: properties.challengeType}) :
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: null});
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.inviteSource !== undefined && properties.inviteSource !== null ?
    eventPropertiesArray.push({id: "heKrMZwQl", name: "invite_source", value: properties.inviteSource}) :
    eventPropertiesArray.push({id: "heKrMZwQl", name: "invite_source", value: null});
  properties.tabName !== undefined && properties.tabName !== null ?
    eventPropertiesArray.push({id: "notiOkO1a", name: "tab_name", value: properties.tabName}) :
    eventPropertiesArray.push({id: "notiOkO1a", name: "tab_name", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "WAl95_Nr77", "786ae57b42dc6a50c402d4ad716cad9ce6a4156d89cd08760c5d14117061796d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("challenge_started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("WAl95_Nr77", "challenge_started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("challenge_started", eventProperties, "WAl95_Nr77", "786ae57b42dc6a50c402d4ad716cad9ce6a4156d89cd08760c5d14117061796d");
    }
    // destination Segment
    Segment.logEvent("challenge_started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ChallengeSelectedProperties {
  challengeId: string;
  dayNumber: number | null | undefined;
  teamId: string | null | undefined;
  teamType: TeamTypeValueType | null | undefined;
  challengeTheme: string | null | undefined;
  challengeType: string | null | undefined;
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  tabName: TabNameValueType | null | undefined;
}
/**
 * challenge_selected: Fires when a user requests/selected to view a Challenge.
 *
 * When to trigger this event:
 * 1. fires when user clicks into a specific challenge

feature_type = 'challenge'
activity_type = 'challenge_selected'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/ag1bH1Hk3T/trigger/ZgVVzkaEC
 * 2. Fires when a user views any of the pages in the company challenge signup process, either as an initial signup or a reconfiguration to join/leave a team

feature_type: challenge
activity_type:challenge_signup_form_served
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/ag1bH1Hk3T/trigger/V-qpu2YPf
 * 3. fires when user is signing up for a challenge

feature_type: challenge
activity_type:challenge_signup_form_served
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/ag1bH1Hk3T/trigger/cBvxEOohf
 *
 * @param properties the properties associatied with this event
 * @param properties.challengeId: id of the challenge being completed
 * @param properties.dayNumber: the day number of any event (be it journey level day number, challenge day number etc.)
 * @param properties.teamId: Id of the team the user belongs to. (this is the equivalent of social_group_id)
 * @param properties.teamType: team type for the company challenge events. Values can be 'individual' or 'team'.
 * @param properties.challengeTheme: the type of company challenge (e.g., hydration, movement)
 * @param properties.challengeType: the type of challenge (e.g., journey, biotype, company, group)
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.tabName: The tab on which the Slack or MS Teams event took place. This is limited to Home Chat and About for now
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/ag1bH1Hk3T}
 */
export function challengeSelected(properties: ChallengeSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: properties.challengeId});
  properties.dayNumber !== undefined && properties.dayNumber !== null ?
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: properties.dayNumber}) :
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: null});
  properties.teamId !== undefined && properties.teamId !== null ?
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: properties.teamId}) :
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: null});
  properties.teamType !== undefined && properties.teamType !== null ?
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: properties.teamType}) :
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: null});
  properties.challengeTheme !== undefined && properties.challengeTheme !== null ?
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: properties.challengeTheme}) :
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: null});
  properties.challengeType !== undefined && properties.challengeType !== null ?
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: properties.challengeType}) :
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: null});
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.tabName !== undefined && properties.tabName !== null ?
    eventPropertiesArray.push({id: "notiOkO1a", name: "tab_name", value: properties.tabName}) :
    eventPropertiesArray.push({id: "notiOkO1a", name: "tab_name", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "ag1bH1Hk3T", "7d950f3b1011202d0fddc0dd75ec675503e284fc035ab0f6e74f0a70383f1148", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("challenge_selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("ag1bH1Hk3T", "challenge_selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("challenge_selected", eventProperties, "ag1bH1Hk3T", "7d950f3b1011202d0fddc0dd75ec675503e284fc035ab0f6e74f0a70383f1148");
    }
    // destination Segment
    Segment.logEvent("challenge_selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ContentCreationStartedProperties {
  contentId: string | null | undefined;
  contentType: ContentTypeValueType | null | undefined;
  contentTitle: string | null | undefined;
  contentSubtype: ContentSubtypeValueType | null | undefined;
  contentFormatType: ContentFormatTypeValueType | null | undefined;
  contentUrl: string | null | undefined;
  contentSource: ContentSourceValueType | null | undefined;
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  resetType: ResetTypeValueType;
  isOnboarding: boolean;
}
/**
 * content_creation_started: When a user creates a new content on the app
 *
 * When to trigger this event:
 * 1. When user added a media during content creation and clicked "Next"

reset_type = 'personal_reset'
content_type = 'reset'
activity_type = 'media_added'
feature_type = 'content'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/O8DQUofBuC/trigger/Wzt2_YrND
 * 2. When user added an audio during content creation and clicked "Next"

reset_type = 'personal_reset'
content_type = 'reset'
activity_type = 'audio_added'
feature_type = 'content'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/O8DQUofBuC/trigger/q7vs6uf-v
 * 3. When a user clicks on 'Create' to create a personal reset.

reset_type = 'personal_reset'
content_type = 'reset'
activity_type = 'reset_creation_started'
feature_type = 'content'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/O8DQUofBuC/trigger/bD1tvk0oP
 * 4. When a user visits the Reset tab for the first time and selects "Try it Out" to create a new personal reset.

feature_type: content
activity_type: reset_creation_started
content_type: reset
reset_type: personal_reset
is_onboarding: true
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/O8DQUofBuC/trigger/q6qF8MkJu
 * 5. When a user uploads a photo/video/gif to Thrive from their device.

feature_type: content
activity_type: personal_reset_media_uploaded
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/O8DQUofBuC/trigger/XoF5f4vKV
 *
 * @param properties the properties associatied with this event
 * @param properties.contentId: Recommended Content ID including external resources
 * @param properties.contentType: The type of content (reset, video, article etc.) in English
 * @param properties.contentTitle: The title of the content (Resets, Articles, Videos etc.) including external resources as well
 * @param properties.contentSubtype: Secondary type based on the content type:
Articles: role_model | recipe | watch_me_thrive | article

 * @param properties.contentFormatType: The format of the content displayed/consumed.

Articles: audio | video | text
Resets: audio | video
External resources: pdf | audio | video | text
 * @param properties.contentUrl: This is the URL of the content:
- If it is an iframe rendered - it would be the iframe full URL
- If it is an external resource (such as benefit connect / sequoia links) - it would be the external full URL

example:
https://thriveglobal.showpad.com/share/cpCAwJwBjxiFfegfWnIHj
https://login.sequoia.com/
 * @param properties.contentSource: Describes the source of the content or elements within the content (UMG audio for personal resets for example)
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.resetType: Types of reset
 * @param properties.isOnboarding: T/F flag for whether an event occurred during onboarding or not.
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/O8DQUofBuC}
 */
export function contentCreationStarted(
  properties: ContentCreationStartedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.contentId !== undefined && properties.contentId !== null ?
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: properties.contentId}) :
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: null});
  properties.contentType !== undefined && properties.contentType !== null ?
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: properties.contentType}) :
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: null});
  properties.contentTitle !== undefined && properties.contentTitle !== null ?
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: properties.contentTitle}) :
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: null});
  properties.contentSubtype !== undefined && properties.contentSubtype !== null ?
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: properties.contentSubtype}) :
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: null});
  properties.contentFormatType !== undefined && properties.contentFormatType !== null ?
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: properties.contentFormatType}) :
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: null});
  properties.contentUrl !== undefined && properties.contentUrl !== null ?
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: properties.contentUrl}) :
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: null});
  properties.contentSource !== undefined && properties.contentSource !== null ?
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: properties.contentSource}) :
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: null});
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "A3cfeXxyUCBc", name: "reset_type", value: properties.resetType});
  eventPropertiesArray.push({id: "RCLdkrcD0", name: "is_onboarding", value: properties.isOnboarding});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "O8DQUofBuC", "3c5432f5594a48536888a21531f54e8c97f1fab4ad620e1485444ac483680b41", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("content_creation_started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("O8DQUofBuC", "content_creation_started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("content_creation_started", eventProperties, "O8DQUofBuC", "3c5432f5594a48536888a21531f54e8c97f1fab4ad620e1485444ac483680b41");
    }
    // destination Segment
    Segment.logEvent("content_creation_started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ContentCreationCompletedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  contentId: string | null | undefined;
  contentType: ContentTypeValueType | null | undefined;
  contentTitle: string | null | undefined;
  contentSubtype: ContentSubtypeValueType | null | undefined;
  contentFormatType: ContentFormatTypeValueType | null | undefined;
  contentUrl: string | null | undefined;
  contentSource: ContentSourceValueType | null | undefined;
  resetType: ResetTypeValueType;
}
/**
 * content_creation_completed: When a user successfully created a content on the app
 *
 * When to trigger this event:
 * 1. When a user clicked "Finish" at the end of creating a personal reset.

feature_type = 'content'
content_type = 'reset'
activity_type = 'reset_creation_completed'
reset_type = 'personal_reset'

Add content properties bundle to include:
content_id = <reset id>
content_title = <reset name>
content_format_type: video
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Bc6zhlrSnk/trigger/hrV55lfET
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.contentId: Recommended Content ID including external resources
 * @param properties.contentType: The type of content (reset, video, article etc.) in English
 * @param properties.contentTitle: The title of the content (Resets, Articles, Videos etc.) including external resources as well
 * @param properties.contentSubtype: Secondary type based on the content type:
Articles: role_model | recipe | watch_me_thrive | article

 * @param properties.contentFormatType: The format of the content displayed/consumed.

Articles: audio | video | text
Resets: audio | video
External resources: pdf | audio | video | text
 * @param properties.contentUrl: This is the URL of the content:
- If it is an iframe rendered - it would be the iframe full URL
- If it is an external resource (such as benefit connect / sequoia links) - it would be the external full URL

example:
https://thriveglobal.showpad.com/share/cpCAwJwBjxiFfegfWnIHj
https://login.sequoia.com/
 * @param properties.contentSource: Describes the source of the content or elements within the content (UMG audio for personal resets for example)
 * @param properties.resetType: Types of reset
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Bc6zhlrSnk}
 */
export function contentCreationCompleted(
  properties: ContentCreationCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.contentId !== undefined && properties.contentId !== null ?
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: properties.contentId}) :
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: null});
  properties.contentType !== undefined && properties.contentType !== null ?
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: properties.contentType}) :
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: null});
  properties.contentTitle !== undefined && properties.contentTitle !== null ?
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: properties.contentTitle}) :
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: null});
  properties.contentSubtype !== undefined && properties.contentSubtype !== null ?
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: properties.contentSubtype}) :
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: null});
  properties.contentFormatType !== undefined && properties.contentFormatType !== null ?
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: properties.contentFormatType}) :
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: null});
  properties.contentUrl !== undefined && properties.contentUrl !== null ?
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: properties.contentUrl}) :
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: null});
  properties.contentSource !== undefined && properties.contentSource !== null ?
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: properties.contentSource}) :
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: null});
  eventPropertiesArray.push({id: "A3cfeXxyUCBc", name: "reset_type", value: properties.resetType});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Bc6zhlrSnk", "b12227c181015beaaf2f8761e21c77e07afdfceede3f7c24723b68078b2e554c", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("content_creation_completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Bc6zhlrSnk", "content_creation_completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("content_creation_completed", eventProperties, "Bc6zhlrSnk", "b12227c181015beaaf2f8761e21c77e07afdfceede3f7c24723b68078b2e554c");
    }
    // destination Segment
    Segment.logEvent("content_creation_completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ResponseServedProperties {
  conversationId: string;
  messageType: MessageTypeValueType;
  transactionId: string;
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
}
/**
 * response_served: When a response is served to a user.
 *
 * When to trigger this event:
 * 1. When thrive guide serves a response back to the user

feature_type: thrive_guide
activity_type: thrive_guide_recommendation
message_type: <enums>
transaction_id: <response transaction id>
conversation_id: <session id for the conversation>
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Iu2AUvgxBw/trigger/LgdQ3pyQG
 *
 * @param properties the properties associatied with this event
 * @param properties.conversationId: For the thrive guide: This is the conversation 'session' id from the thrive guide bot.
 * @param properties.messageType: When the chatbot interacts with the user, it can have two types of text messages: question or a recommendation.

When the user interacts with the chatbot, there are 2 types of messages: free-form text response, multiple choice response
 * @param properties.transactionId: For the a chatbot events, this id will join each conversation with its corresponding BE data.
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Iu2AUvgxBw}
 */
export function responseServed(properties: ResponseServedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "RwgnWaMMW", name: "conversation_id", value: properties.conversationId});
  eventPropertiesArray.push({id: "pcbtFdgiJ", name: "message_type", value: properties.messageType});
  eventPropertiesArray.push({id: "Mi1dgAO5M", name: "transaction_id", value: properties.transactionId});
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Iu2AUvgxBw", "7b475aefc606162c87167da7e4d8ecfbc9f2c9802a5263406a8742811922a2c6", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("response_served", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Iu2AUvgxBw", "response_served", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("response_served", eventProperties, "Iu2AUvgxBw", "7b475aefc606162c87167da7e4d8ecfbc9f2c9802a5263406a8742811922a2c6");
    }
    // destination Segment
    Segment.logEvent("response_served", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SocialGroupJoinedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
}
/**
 * social_group_joined: When a user joins a social group.
 *
 * When to trigger this event:
 * 1. **Need to work with team to get screen for event

When a user joins a social group.
feature_type: community
activity_type: social_group_joined
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/xsv-UJ250-/trigger/bG6mSy3Ua
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/xsv-UJ250-}
 */
export function socialGroupJoined(properties: SocialGroupJoinedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "xsv-UJ250-", "61792820c8fb8a2c277d58a149423b034b932804620d631a51fc4373391b33e1", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("social_group_joined", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("xsv-UJ250-", "social_group_joined", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("social_group_joined", eventProperties, "xsv-UJ250-", "61792820c8fb8a2c277d58a149423b034b932804620d631a51fc4373391b33e1");
    }
    // destination Segment
    Segment.logEvent("social_group_joined", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ChallengeLeftProperties {
  challengeId: string;
  dayNumber: number | null | undefined;
  teamId: string | null | undefined;
  teamType: TeamTypeValueType | null | undefined;
  challengeTheme: string | null | undefined;
  challengeType: string | null | undefined;
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  dailyGoalsCompleted: string | null | undefined;
}
/**
 * challenge_left: When a user successfully left a challenge they previously joined.
 *
 * When to trigger this event:
 * 1. When a user clicks "leave challenge"

Populate these properties:
feature_type: challenge
activity_type: challenge_left
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/rwsdYxH1EQ/trigger/GCH8nC0WE
 *
 * @param properties the properties associatied with this event
 * @param properties.challengeId: id of the challenge being completed
 * @param properties.dayNumber: the day number of any event (be it journey level day number, challenge day number etc.)
 * @param properties.teamId: Id of the team the user belongs to. (this is the equivalent of social_group_id)
 * @param properties.teamType: team type for the company challenge events. Values can be 'individual' or 'team'.
 * @param properties.challengeTheme: the type of company challenge (e.g., hydration, movement)
 * @param properties.challengeType: the type of challenge (e.g., journey, biotype, company, group)
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.dailyGoalsCompleted: the number of daily_goals_completed for company/group challenges
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/rwsdYxH1EQ}
 */
export function challengeLeft(properties: ChallengeLeftProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: properties.challengeId});
  properties.dayNumber !== undefined && properties.dayNumber !== null ?
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: properties.dayNumber}) :
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: null});
  properties.teamId !== undefined && properties.teamId !== null ?
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: properties.teamId}) :
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: null});
  properties.teamType !== undefined && properties.teamType !== null ?
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: properties.teamType}) :
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: null});
  properties.challengeTheme !== undefined && properties.challengeTheme !== null ?
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: properties.challengeTheme}) :
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: null});
  properties.challengeType !== undefined && properties.challengeType !== null ?
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: properties.challengeType}) :
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: null});
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.dailyGoalsCompleted !== undefined && properties.dailyGoalsCompleted !== null ?
    eventPropertiesArray.push({id: "rxOtl9f_4", name: "daily_goals_completed", value: properties.dailyGoalsCompleted}) :
    eventPropertiesArray.push({id: "rxOtl9f_4", name: "daily_goals_completed", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "rwsdYxH1EQ", "6f749a3cb4f84dc9c9c0616d5d8176578cdaf31cc4a7d2d4c48152e27585ed5d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("challenge_left", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("rwsdYxH1EQ", "challenge_left", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("challenge_left", eventProperties, "rwsdYxH1EQ", "6f749a3cb4f84dc9c9c0616d5d8176578cdaf31cc4a7d2d4c48152e27585ed5d");
    }
    // destination Segment
    Segment.logEvent("challenge_left", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SigninCompletedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  userId_: string;
  method: MethodValueType;
}
/**
 * signin_completed: When a user signs into Thrive.
 *
 * When to trigger this event:
 * 1. When a user signs in via SSO
feature_type: identity
activity_type: user_signin
method: sso
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/ZZF7KCf2C4/trigger/ltDbaGZmg
 * 2. When a user signs in via the magic link
feature_type: identity
activity_type: user_signin
method: magic_link
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/ZZF7KCf2C4/trigger/M9NgQ7bSs
 * 3. When a user signs in with credentials
feature_type: identity
activity_type: user_signin
method: credentials
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/ZZF7KCf2C4/trigger/P2n-3ktoS
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 * @param properties.method: Identity authentication methods
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/ZZF7KCf2C4}
 */
export function signinCompleted(properties: SigninCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "2bb9tCwDwOc3", name: "method", value: properties.method});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "ZZF7KCf2C4", "4dd3986d4461b419900566296aa44080228c2785502d69c3f0b799d3ae8bcc98", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("signin_completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("ZZF7KCf2C4", "signin_completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("signin_completed", eventProperties, "ZZF7KCf2C4", "4dd3986d4461b419900566296aa44080228c2785502d69c3f0b799d3ae8bcc98");
    }
    // destination Segment
    Segment.identify(properties.userId_);
    Segment.logEvent("signin_completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SignupStartedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  method: MethodValueType;
  reason: string;
  userId_: string;
}
/**
 * signup_started: When a user triggers any of the events that make up the signup flow before completion.
 *
 * When to trigger this event:
 * 1. When a user selects their company in the signup process.
feature_type:signup
activity_type:company_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/mSO-Dp6AoO/trigger/fIH_1Q06R
 * 2. When a new referral user selects their signup method
feature_type: signup
activity_type: referral_signup_method_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/mSO-Dp6AoO/trigger/OifPl-XLG
 * 3. When a referred user begins the signup process
feature_type: signup
activity_type: referral_signup_started
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/mSO-Dp6AoO/trigger/MPQxgwY0U
 * 4. When a user requests their username and password
feature_type: signup
activity_type: user_requested_username_password
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/mSO-Dp6AoO/trigger/V5wxpQWXX
 * 5. When a user selects their signup method
feature_type: signup
activity_type: signup_method_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/mSO-Dp6AoO/trigger/MXA-v9sda
 * 6. When a user starts the signup process
feature_type: signup
activity_type: signup_started
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/mSO-Dp6AoO/trigger/8NNW_xzpl
 * 7. When a user selects to choose a different company from the pick list.
feature_type:signup
activity_type: wrong_company_clicked
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/mSO-Dp6AoO/trigger/OLySeB65d
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.method: Identity authentication methods
 * @param properties.reason: The reason for the failure or for serving specific content
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/mSO-Dp6AoO}
 */
export function signupStarted(properties: SignupStartedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "2bb9tCwDwOc3", name: "method", value: properties.method});
  eventPropertiesArray.push({id: "V5egf6WRb7GV", name: "reason", value: properties.reason});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "mSO-Dp6AoO", "753bc28238f76c1ce4b73f538a0757407c856201b08c3d27122b6ad1bfaf4c7e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("signup_started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("mSO-Dp6AoO", "signup_started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("signup_started", eventProperties, "mSO-Dp6AoO", "753bc28238f76c1ce4b73f538a0757407c856201b08c3d27122b6ad1bfaf4c7e");
    }
    // destination Segment
    Segment.identify(properties.userId_);
    Segment.logEvent("signup_started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SignupFailedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  method: MethodValueType;
  reason: string;
  userId_: string;
}
/**
 * signup_failed: When a signup fails
 *
 * When to trigger this event:
 * 1. When a referral signup fails
feature_type: signup
activity_type: referral_signup_failed
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/oOubXjKCMM/trigger/1SonnMqFW
 * 2. When a signup fails
feature_type: signup
activity_type: signup_failed
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/oOubXjKCMM/trigger/mmouMJGYc
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.method: Identity authentication methods
 * @param properties.reason: The reason for the failure or for serving specific content
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/oOubXjKCMM}
 */
export function signupFailed(properties: SignupFailedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "2bb9tCwDwOc3", name: "method", value: properties.method});
  eventPropertiesArray.push({id: "V5egf6WRb7GV", name: "reason", value: properties.reason});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "oOubXjKCMM", "a392f99e61d0b122a424b5045cf3e9eff4b67214527dc7fac3d49aa95836925e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("signup_failed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("oOubXjKCMM", "signup_failed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("signup_failed", eventProperties, "oOubXjKCMM", "a392f99e61d0b122a424b5045cf3e9eff4b67214527dc7fac3d49aa95836925e");
    }
    // destination Segment
    Segment.identify(properties.userId_);
    Segment.logEvent("signup_failed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SignupCompletedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  method: MethodValueType;
  reason: string;
  companyId: string;
}
/**
 * signup_completed: When a user successfully signs up for Thrive
 *
 * When to trigger this event:
 * 1. When a user completes their signup.
feature_type: signup
activity_type: signup_completed
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/xkNPnnd7vu/trigger/GJLZyqbdA
 * 2. When a referral user successfully signs up for Thrive.
feature_type: signup
activity_type: referral_signup_completed
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/xkNPnnd7vu/trigger/3YMcrcfen
 * 3. When a user successfully signs up with credentials
feature_type:signup
activity_type:credentials_signup_completed
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/xkNPnnd7vu/trigger/DaoLPCTiF
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.method: Identity authentication methods
 * @param properties.reason: The reason for the failure or for serving specific content
 * @param properties.companyId: ID of the company.
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/xkNPnnd7vu}
 */
export function signupCompleted(properties: SignupCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "2bb9tCwDwOc3", name: "method", value: properties.method});
  eventPropertiesArray.push({id: "V5egf6WRb7GV", name: "reason", value: properties.reason});
  eventPropertiesArray.push({id: "h_HkYy-iweh5", name: "company_id", value: properties.companyId});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "xkNPnnd7vu", "9ed2abb251977536cffda5b6956d2a7a42a1433f5b077cc9e284ecb26717dc88", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("signup_completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("xkNPnnd7vu", "signup_completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("signup_completed", eventProperties, "xkNPnnd7vu", "9ed2abb251977536cffda5b6956d2a7a42a1433f5b077cc9e284ecb26717dc88");
    }
    // destination Segment
    Segment.logEvent("signup_completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SignoutCompletedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  userId_: string;
}
/**
 * signout_completed: When a user signs out of Thrive
 *
 * When to trigger this event:
 * 1. When a user selects to sign out of Thrive.
feature_type: identity
activity_type: user_signout
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/FEKkgQNlWm/trigger/ZjU4CR5K7
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/FEKkgQNlWm}
 */
export function signoutCompleted(properties: SignoutCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "FEKkgQNlWm", "b98ea31103fa0165917b8340f5cba5269c5defdc4d8d52dce793ab4f62969748", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("signout_completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("FEKkgQNlWm", "signout_completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("signout_completed", eventProperties, "FEKkgQNlWm", "b98ea31103fa0165917b8340f5cba5269c5defdc4d8d52dce793ab4f62969748");
    }
    // destination Segment
    Segment.identify(properties.userId_);
    Segment.logEvent("signout_completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PasswordResetRequestedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  userId_: string;
  status: StatusValueType;
  reason: string;
}
/**
 * password_reset_requested: When the user selects to reset their password
 *
 * When to trigger this event:
 * 1. When a user requests to reset their password
feature_type: identity
activity_type: user_requested_reset_password
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/4L-E-Npm_V/trigger/UlvCtDv0p
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 * @param properties.status: tbd
 * @param properties.reason: The reason for the failure or for serving specific content
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/4L-E-Npm_V}
 */
export function passwordResetRequested(
  properties: PasswordResetRequestedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "75M2PhzkvuaC", name: "status", value: properties.status});
  eventPropertiesArray.push({id: "V5egf6WRb7GV", name: "reason", value: properties.reason});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "4L-E-Npm_V", "69a97d7bef4959f487b278c6442a1f61e5c280d3922f8b71630779352975ac9b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("password_reset_requested", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("4L-E-Npm_V", "password_reset_requested", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("password_reset_requested", eventProperties, "4L-E-Npm_V", "69a97d7bef4959f487b278c6442a1f61e5c280d3922f8b71630779352975ac9b");
    }
    // destination Segment
    Segment.identify(properties.userId_);
    Segment.logEvent("password_reset_requested", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ReferralStartedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  userId_: string;
  companyId: string;
}
/**
 * referral_started: When a user triggers any of the events that make up the referral flow
 *
 * When to trigger this event:
 * 1. When a user selects to copy the referral link.
feature_type: profile
activity_type: referral_link_copied
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/feS0Lj5CD5/trigger/vI5o-8HS1
 * 2. When a user selects to send a referral email(s)
feature_type: profile
activity_type: send_referral_email_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/feS0Lj5CD5/trigger/uw_4NIWK2
 * 3. When a user clicks to show the referral QR code.

feature_type: profile
activity_type: referral_qr_code_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/feS0Lj5CD5/trigger/yqOtenj4o
 * 4. When a user copies the link for their friends and family referral URL.
feature_type: profile
activity_type: friends_and_family_referral_link_copied
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/feS0Lj5CD5/trigger/dPoxEZVQx
 * 5. When a user selects to display their friends and family referral QR code

feature_type: profile
activity_type: friends_and_family_referral_qr_code_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/feS0Lj5CD5/trigger/rxk1VJdgl
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 * @param properties.companyId: ID of the company.
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/feS0Lj5CD5}
 */
export function referralStarted(properties: ReferralStartedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "h_HkYy-iweh5", name: "company_id", value: properties.companyId});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "feS0Lj5CD5", "ce4435b501e238ff4332d0ee47b890d135b9db00c5c55a0ccf14177544bc67ea", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("referral_started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("feS0Lj5CD5", "referral_started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("referral_started", eventProperties, "feS0Lj5CD5", "ce4435b501e238ff4332d0ee47b890d135b9db00c5c55a0ccf14177544bc67ea");
    }
    // destination Segment
    Segment.identify(properties.userId_);
    Segment.logEvent("referral_started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ResourceSelectedProperties {
  journey: string | null | undefined;
  journeyId: string | null | undefined;
  journeyLevel: number | null | undefined;
  journeyDay: number | null | undefined;
  firstJourney: boolean | null | undefined;
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  contentId: string | null | undefined;
  contentType: ContentTypeValueType;
  contentTitle: string | null | undefined;
  contentSubtype: ContentSubtypeValueType | null | undefined;
  contentFormatType: ContentFormatTypeValueType;
  contentUrl: string;
  contentSource: ContentSourceValueType | null | undefined;
  challengeId: string | null | undefined;
  dayNumber: number | null | undefined;
  teamId: string | null | undefined;
  teamType: TeamTypeValueType | null | undefined;
  challengeTheme: string | null | undefined;
  challengeType: string | null | undefined;
  isOnboarding: boolean | null | undefined;
  appUserId: string | null | undefined;
  tabName: TabNameValueType | null | undefined;
  displayText: string | null | undefined;
  nudgeType: string | null | undefined;
}
/**
 * resource_selected: When a user selects a resource (anything that the client provides like benefits, employee messaging).
 *
 * When to trigger this event:
 * 1. feature_type = 'benefits_connect'
activity_type = 'benefits_connect_selected'
+ content properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/dsn6KZ50pe/trigger/MT6_CbxRY
 * 2. When a resource is selected from the Journeys tab in the Webex embedded app

feature_type: journeys
activity_type: journey_resource_selected
+ content properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/dsn6KZ50pe/trigger/zpZd1cqmr
 * 3. When a user clicks into a benefits connect resource from the library tab in the Webex embedded app

feature_type: library
activity_type: library_resource_selected
+ content properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/dsn6KZ50pe/trigger/kiihzY7GL
 * 4. Fires when a user selects "Watch Reset" in their notifications.
feature_type = 'notifications'
activity_type = 'reset_notification_selected'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/dsn6KZ50pe/trigger/o17wvPgTX
 * 5. Fires when a user selects "Not Now" in their notifications.
feature_type = 'notifications'
activity_type = 'reset_notification_deferred'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/dsn6KZ50pe/trigger/dDDXQwNyv
 * 6. Fires when a user clicks on the Challenge goal notification and lands on the Challenge page. This should only fire when a user is directed to this page via clicking the notification link. Since button clicks cannot be tracked in Teams, we have to fire the event on the landing page when the user comes from Teams (likely need to use utm_campaign parameter to distinguish that it came from a challenge_goal_notification)
feature_type = 'notifications'
activity_type = 'challenge_goal_notification_selected'
+challenge_properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/dsn6KZ50pe/trigger/W_WDWmDpg
 * 7. When a user selects to open a browser notification.

feature_type: notifications
activity_type: browser_notification_selected
utm_source: browser_notification
nudge_type: reset
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/dsn6KZ50pe/trigger/tfsQ_rqFR
 * 8. Fires when a user selects "Go to Challenges" from their Challenge nudge on web.
feature_type = 'notifications'
activity_type = 'challenge_goal_notification_selected'
+challenge_properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/dsn6KZ50pe/trigger/1qk92yTXq
 * 9. Fires when a user selects "Join a challenge" from the Web Nudges CTA.
feature_type = 'notifications'
activity_type = 'web_announcement_notification_selected'

Note- we are not requiring challenge properties be sent with this event since a user may be in several challenges at once.
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/dsn6KZ50pe/trigger/X8CU1He14E
 *
 * @param properties the properties associatied with this event
 * @param properties.journey: Name of the journey
 * @param properties.journeyId: Id of the journey
 * @param properties.journeyLevel: Level of the journey that a user is on
 * @param properties.journeyDay: The day of the journey that a user is on.
 * @param properties.firstJourney: True if journey is the user's first journey selection.
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.contentId: Recommended Content ID including external resources
 * @param properties.contentType: The type of content (reset, video, article etc.) in English
 * @param properties.contentTitle: The title of the content (Resets, Articles, Videos etc.) including external resources as well
 * @param properties.contentSubtype: Secondary type based on the content type:
Articles: role_model | recipe | watch_me_thrive | article

 * @param properties.contentFormatType: The format of the content displayed/consumed.

Articles: audio | video | text
Resets: audio | video
External resources: pdf | audio | video | text
 * @param properties.contentUrl: This is the URL of the content:
- If it is an iframe rendered - it would be the iframe full URL
- If it is an external resource (such as benefit connect / sequoia links) - it would be the external full URL

example:
https://thriveglobal.showpad.com/share/cpCAwJwBjxiFfegfWnIHj
https://login.sequoia.com/
 * @param properties.contentSource: Describes the source of the content or elements within the content (UMG audio for personal resets for example)
 * @param properties.challengeId: id of the challenge being completed
 * @param properties.dayNumber: the day number of any event (be it journey level day number, challenge day number etc.)
 * @param properties.teamId: Id of the team the user belongs to. (this is the equivalent of social_group_id)
 * @param properties.teamType: team type for the company challenge events. Values can be 'individual' or 'team'.
 * @param properties.challengeTheme: the type of company challenge (e.g., hydration, movement)
 * @param properties.challengeType: the type of challenge (e.g., journey, biotype, company, group)
 * @param properties.isOnboarding: T/F flag for whether an event occurred during onboarding or not.
 * @param properties.appUserId: MS Teams-, Slack-, Webex-, etc. -specific user IDs.
 * @param properties.tabName: The tab on which the Slack or MS Teams event took place. This is limited to Home Chat and About for now
 * @param properties.displayText: The text on any selectable/clickable element.
 * @param properties.nudgeType: The type of notification
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/dsn6KZ50pe}
 */
export function resourceSelected(properties: ResourceSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.journey !== undefined && properties.journey !== null ?
    eventPropertiesArray.push({id: "F3BjAMmBpGqj", name: "journey", value: properties.journey}) :
    eventPropertiesArray.push({id: "F3BjAMmBpGqj", name: "journey", value: null});
  properties.journeyId !== undefined && properties.journeyId !== null ?
    eventPropertiesArray.push({id: "lvwXMqzXMp9C", name: "journey_id", value: properties.journeyId}) :
    eventPropertiesArray.push({id: "lvwXMqzXMp9C", name: "journey_id", value: null});
  properties.journeyLevel !== undefined && properties.journeyLevel !== null ?
    eventPropertiesArray.push({id: "HtZ0qgLqVsP6", name: "journey_level", value: properties.journeyLevel}) :
    eventPropertiesArray.push({id: "HtZ0qgLqVsP6", name: "journey_level", value: null});
  properties.journeyDay !== undefined && properties.journeyDay !== null ?
    eventPropertiesArray.push({id: "StQnPpWOrG18", name: "journey_day", value: properties.journeyDay}) :
    eventPropertiesArray.push({id: "StQnPpWOrG18", name: "journey_day", value: null});
  properties.firstJourney !== undefined && properties.firstJourney !== null ?
    eventPropertiesArray.push({id: "ktrvZyV0Q-nn", name: "first_journey", value: properties.firstJourney}) :
    eventPropertiesArray.push({id: "ktrvZyV0Q-nn", name: "first_journey", value: null});
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.contentId !== undefined && properties.contentId !== null ?
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: properties.contentId}) :
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: null});
  eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: properties.contentType});
  properties.contentTitle !== undefined && properties.contentTitle !== null ?
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: properties.contentTitle}) :
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: null});
  properties.contentSubtype !== undefined && properties.contentSubtype !== null ?
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: properties.contentSubtype}) :
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: null});
  eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: properties.contentFormatType});
  eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: properties.contentUrl});
  properties.contentSource !== undefined && properties.contentSource !== null ?
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: properties.contentSource}) :
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: null});
  properties.challengeId !== undefined && properties.challengeId !== null ?
    eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: properties.challengeId}) :
    eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: null});
  properties.dayNumber !== undefined && properties.dayNumber !== null ?
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: properties.dayNumber}) :
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: null});
  properties.teamId !== undefined && properties.teamId !== null ?
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: properties.teamId}) :
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: null});
  properties.teamType !== undefined && properties.teamType !== null ?
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: properties.teamType}) :
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: null});
  properties.challengeTheme !== undefined && properties.challengeTheme !== null ?
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: properties.challengeTheme}) :
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: null});
  properties.challengeType !== undefined && properties.challengeType !== null ?
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: properties.challengeType}) :
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: null});
  properties.isOnboarding !== undefined && properties.isOnboarding !== null ?
    eventPropertiesArray.push({id: "RCLdkrcD0", name: "is_onboarding", value: properties.isOnboarding}) :
    eventPropertiesArray.push({id: "RCLdkrcD0", name: "is_onboarding", value: null});
  properties.appUserId !== undefined && properties.appUserId !== null ?
    eventPropertiesArray.push({id: "0X0Ce2XVufG0", name: "app_user_id", value: properties.appUserId}) :
    eventPropertiesArray.push({id: "0X0Ce2XVufG0", name: "app_user_id", value: null});
  properties.tabName !== undefined && properties.tabName !== null ?
    eventPropertiesArray.push({id: "notiOkO1a", name: "tab_name", value: properties.tabName}) :
    eventPropertiesArray.push({id: "notiOkO1a", name: "tab_name", value: null});
  properties.displayText !== undefined && properties.displayText !== null ?
    eventPropertiesArray.push({id: "1YLolCiLg", name: "display_text", value: properties.displayText}) :
    eventPropertiesArray.push({id: "1YLolCiLg", name: "display_text", value: null});
  properties.nudgeType !== undefined && properties.nudgeType !== null ?
    eventPropertiesArray.push({id: "5gHzHN48a", name: "nudge_type", value: properties.nudgeType}) :
    eventPropertiesArray.push({id: "5gHzHN48a", name: "nudge_type", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertJourneyLevelOptional(properties.journeyLevel));
    messages = messages.concat(assertJourneyDayOptional(properties.journeyDay));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "dsn6KZ50pe", "f749ed8c72fd53ea31920bae1800d7dff337d7e266dcf992e82c3cd3fbf0f3bc", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("resource_selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("dsn6KZ50pe", "resource_selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'resource_selected': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("resource_selected", eventProperties, "dsn6KZ50pe", "f749ed8c72fd53ea31920bae1800d7dff337d7e266dcf992e82c3cd3fbf0f3bc");
    }
    // destination Segment
    Segment.logEvent("resource_selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ResourceServedProperties {
  journey: string | null | undefined;
  journeyId: string | null | undefined;
  journeyLevel: number | null | undefined;
  journeyDay: number | null | undefined;
  firstJourney: boolean | null | undefined;
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  contentId: string;
  contentType: ContentTypeValueType;
  contentTitle: string;
  contentSubtype: ContentSubtypeValueType | null | undefined;
  contentFormatType: ContentFormatTypeValueType;
  contentUrl: string;
  contentSource: ContentSourceValueType | null | undefined;
  challengeId: string | null | undefined;
  dayNumber: number | null | undefined;
  teamId: string | null | undefined;
  teamType: TeamTypeValueType | null | undefined;
  challengeTheme: string | null | undefined;
  challengeType: string | null | undefined;
  appUserId: string | null | undefined;
  nudgeType: string | null | undefined;
}
/**
 * resource_served: When a user views a resource (anything that the client provides e.g. benefits, employee messaging)
 *
 * When to trigger this event:
 * 1. feature_type = 'benefits_connect'
activity_type = 'benefits_connect_viewed'
+ content_properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/FY8goYFu-y/trigger/yNNIHZB79
 * 2. Fires when a user is sent a nudge on Web (i.e. when the red badge is populated on the user's notification bar).
feature_type = 'notifications'
activity_type = '<VARIABLE>_notification_served'

At the moment, we only have Reset notifications, so the activity_type would be 'reset_notification_served'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/FY8goYFu-y/trigger/adPiNO0I6
 * 3. When a user is served a browser notification from Thrive.

feature_type: notifications
activity_type: browser_notification_served
utm_source: browser_notification
nudge_type: reset
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/FY8goYFu-y/trigger/jHNleZEQu
 * 4. Fires when a user receives the "Introducing Web Nudges" notification.
feature_type = 'notifications'
activity_type = 'web_announcement_notification_served'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/FY8goYFu-y/trigger/ClTqKrn7So
 *
 * @param properties the properties associatied with this event
 * @param properties.journey: Name of the journey
 * @param properties.journeyId: Id of the journey
 * @param properties.journeyLevel: Level of the journey that a user is on
 * @param properties.journeyDay: The day of the journey that a user is on.
 * @param properties.firstJourney: True if journey is the user's first journey selection.
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.contentId: Recommended Content ID including external resources
 * @param properties.contentType: The type of content (reset, video, article etc.) in English
 * @param properties.contentTitle: The title of the content (Resets, Articles, Videos etc.) including external resources as well
 * @param properties.contentSubtype: Secondary type based on the content type:
Articles: role_model | recipe | watch_me_thrive | article

 * @param properties.contentFormatType: The format of the content displayed/consumed.

Articles: audio | video | text
Resets: audio | video
External resources: pdf | audio | video | text
 * @param properties.contentUrl: This is the URL of the content:
- If it is an iframe rendered - it would be the iframe full URL
- If it is an external resource (such as benefit connect / sequoia links) - it would be the external full URL

example:
https://thriveglobal.showpad.com/share/cpCAwJwBjxiFfegfWnIHj
https://login.sequoia.com/
 * @param properties.contentSource: Describes the source of the content or elements within the content (UMG audio for personal resets for example)
 * @param properties.challengeId: id of the challenge being completed
 * @param properties.dayNumber: the day number of any event (be it journey level day number, challenge day number etc.)
 * @param properties.teamId: Id of the team the user belongs to. (this is the equivalent of social_group_id)
 * @param properties.teamType: team type for the company challenge events. Values can be 'individual' or 'team'.
 * @param properties.challengeTheme: the type of company challenge (e.g., hydration, movement)
 * @param properties.challengeType: the type of challenge (e.g., journey, biotype, company, group)
 * @param properties.appUserId: MS Teams-, Slack-, Webex-, etc. -specific user IDs.
 * @param properties.nudgeType: The type of notification
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/FY8goYFu-y}
 */
export function resourceServed(properties: ResourceServedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  properties.journey !== undefined && properties.journey !== null ?
    eventPropertiesArray.push({id: "F3BjAMmBpGqj", name: "journey", value: properties.journey}) :
    eventPropertiesArray.push({id: "F3BjAMmBpGqj", name: "journey", value: null});
  properties.journeyId !== undefined && properties.journeyId !== null ?
    eventPropertiesArray.push({id: "lvwXMqzXMp9C", name: "journey_id", value: properties.journeyId}) :
    eventPropertiesArray.push({id: "lvwXMqzXMp9C", name: "journey_id", value: null});
  properties.journeyLevel !== undefined && properties.journeyLevel !== null ?
    eventPropertiesArray.push({id: "HtZ0qgLqVsP6", name: "journey_level", value: properties.journeyLevel}) :
    eventPropertiesArray.push({id: "HtZ0qgLqVsP6", name: "journey_level", value: null});
  properties.journeyDay !== undefined && properties.journeyDay !== null ?
    eventPropertiesArray.push({id: "StQnPpWOrG18", name: "journey_day", value: properties.journeyDay}) :
    eventPropertiesArray.push({id: "StQnPpWOrG18", name: "journey_day", value: null});
  properties.firstJourney !== undefined && properties.firstJourney !== null ?
    eventPropertiesArray.push({id: "ktrvZyV0Q-nn", name: "first_journey", value: properties.firstJourney}) :
    eventPropertiesArray.push({id: "ktrvZyV0Q-nn", name: "first_journey", value: null});
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: properties.contentId});
  eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: properties.contentType});
  eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: properties.contentTitle});
  properties.contentSubtype !== undefined && properties.contentSubtype !== null ?
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: properties.contentSubtype}) :
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: null});
  eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: properties.contentFormatType});
  eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: properties.contentUrl});
  properties.contentSource !== undefined && properties.contentSource !== null ?
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: properties.contentSource}) :
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: null});
  properties.challengeId !== undefined && properties.challengeId !== null ?
    eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: properties.challengeId}) :
    eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: null});
  properties.dayNumber !== undefined && properties.dayNumber !== null ?
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: properties.dayNumber}) :
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: null});
  properties.teamId !== undefined && properties.teamId !== null ?
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: properties.teamId}) :
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: null});
  properties.teamType !== undefined && properties.teamType !== null ?
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: properties.teamType}) :
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: null});
  properties.challengeTheme !== undefined && properties.challengeTheme !== null ?
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: properties.challengeTheme}) :
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: null});
  properties.challengeType !== undefined && properties.challengeType !== null ?
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: properties.challengeType}) :
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: null});
  properties.appUserId !== undefined && properties.appUserId !== null ?
    eventPropertiesArray.push({id: "0X0Ce2XVufG0", name: "app_user_id", value: properties.appUserId}) :
    eventPropertiesArray.push({id: "0X0Ce2XVufG0", name: "app_user_id", value: null});
  properties.nudgeType !== undefined && properties.nudgeType !== null ?
    eventPropertiesArray.push({id: "5gHzHN48a", name: "nudge_type", value: properties.nudgeType}) :
    eventPropertiesArray.push({id: "5gHzHN48a", name: "nudge_type", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertJourneyLevelOptional(properties.journeyLevel));
    messages = messages.concat(assertJourneyDayOptional(properties.journeyDay));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "FY8goYFu-y", "f6fb4258389042e4cc015d442ea5b50043d4eefe7060e09b787696626412459c", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("resource_served", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("FY8goYFu-y", "resource_served", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'resource_served': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("resource_served", eventProperties, "FY8goYFu-y", "f6fb4258389042e4cc015d442ea5b50043d4eefe7060e09b787696626412459c");
    }
    // destination Segment
    Segment.logEvent("resource_served", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface JourneyStartedProperties {
  journey: string;
  journeyId: string;
  journeyLevel: number;
  journeyDay: number;
  firstJourney: boolean | null | undefined;
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  isOnboarding: boolean | null | undefined;
  userId_: string;
  isAutoEnrolled: boolean | null | undefined;
  tabName: TabNameValueType | null | undefined;
}
/**
 * journey_started: When a user starts a journey.

This can either be a new journey or a change from a previous journey.
 *
 * When to trigger this event:
 * 1. feature_type = 'journeys'
activity_type = 'journey_changed'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/V_5XsS9jNn/trigger/dq6fvvk19
 * 2. feature_type = 'journeys'
activity_type = 'new_journey_started'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/V_5XsS9jNn/trigger/PPEvKK13U
 * 3. Fires when a user selects a journey during onboarding.
feature_type = 'journeys'
activity_type = 'new_journey_started'
is_onboarding = true
onboarding_attempt_number = 1

* If a user selects "I'm not sure", they will be suggested a Journey. Once they confirm the suggested Journey, the journey_started event would fire (see next trigger)
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/V_5XsS9jNn/trigger/fV10U90Df
 * 4. Fires when a user confirms a journey during onboarding.
feature_type = 'journeys'
activity_type = 'new_journey_started'
is_onboarding = true
onboarding_attempt_number = 1
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/V_5XsS9jNn/trigger/janSRLQ8B
 * 5. Fires when a user selects a recommended journey during onboarding.
feature_type = 'journeys'
activity_type = 'default_recommended_journey_started'
OR
'recommended_journey_started'
OR
'new_journey_started'
is_onboarding = true
onboarding_attempt_number = 1
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/V_5XsS9jNn/trigger/XdVVi5po_
 *
 * @param properties the properties associatied with this event
 * @param properties.journey: Name of the journey
 * @param properties.journeyId: Id of the journey
 * @param properties.journeyLevel: Level of the journey that a user is on
 * @param properties.journeyDay: The day of the journey that a user is on.
 * @param properties.firstJourney: True if journey is the user's first journey selection.
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.isOnboarding: T/F flag for whether an event occurred during onboarding or not.
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 * @param properties.isAutoEnrolled: Flag for when a user is auto-enrolled (e.g. into a Journey)
 * @param properties.tabName: The tab on which the Slack or MS Teams event took place. This is limited to Home Chat and About for now
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/V_5XsS9jNn}
 */
export function journeyStarted(properties: JourneyStartedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "F3BjAMmBpGqj", name: "journey", value: properties.journey});
  eventPropertiesArray.push({id: "lvwXMqzXMp9C", name: "journey_id", value: properties.journeyId});
  eventPropertiesArray.push({id: "HtZ0qgLqVsP6", name: "journey_level", value: properties.journeyLevel});
  eventPropertiesArray.push({id: "StQnPpWOrG18", name: "journey_day", value: properties.journeyDay});
  properties.firstJourney !== undefined && properties.firstJourney !== null ?
    eventPropertiesArray.push({id: "ktrvZyV0Q-nn", name: "first_journey", value: properties.firstJourney}) :
    eventPropertiesArray.push({id: "ktrvZyV0Q-nn", name: "first_journey", value: null});
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.isOnboarding !== undefined && properties.isOnboarding !== null ?
    eventPropertiesArray.push({id: "RCLdkrcD0", name: "is_onboarding", value: properties.isOnboarding}) :
    eventPropertiesArray.push({id: "RCLdkrcD0", name: "is_onboarding", value: null});
  properties.isAutoEnrolled !== undefined && properties.isAutoEnrolled !== null ?
    eventPropertiesArray.push({id: "39Xg215d-", name: "is_auto_enrolled", value: properties.isAutoEnrolled}) :
    eventPropertiesArray.push({id: "39Xg215d-", name: "is_auto_enrolled", value: null});
  properties.tabName !== undefined && properties.tabName !== null ?
    eventPropertiesArray.push({id: "notiOkO1a", name: "tab_name", value: properties.tabName}) :
    eventPropertiesArray.push({id: "notiOkO1a", name: "tab_name", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertJourneyLevel(properties.journeyLevel));
    messages = messages.concat(assertJourneyDay(properties.journeyDay));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "V_5XsS9jNn", "d32769fbfd543af8386ae39685782ff72be7359421ca85633218df835f62a5e6", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("journey_started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("V_5XsS9jNn", "journey_started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'journey_started': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("journey_started", eventProperties, "V_5XsS9jNn", "d32769fbfd543af8386ae39685782ff72be7359421ca85633218df835f62a5e6");
    }
    // destination Segment
    Segment.identify(properties.userId_);
    Segment.logEvent("journey_started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface JourneyLevelCompletedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  journey: string;
  journeyId: string;
  journeyLevel: number;
  journeyDay: number;
  firstJourney: boolean | null | undefined;
}
/**
 * journey_level_completed: When a user completes a level of a journey.
 *
 * When to trigger this event:
 * 1. feature_type = 'journeys'
activity_type = 'journey_level_completed'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Rme-h_6TZZ/trigger/mwRmrLfPD
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.journey: Name of the journey
 * @param properties.journeyId: Id of the journey
 * @param properties.journeyLevel: Level of the journey that a user is on
 * @param properties.journeyDay: The day of the journey that a user is on.
 * @param properties.firstJourney: True if journey is the user's first journey selection.
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Rme-h_6TZZ}
 */
export function journeyLevelCompleted(
  properties: JourneyLevelCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "F3BjAMmBpGqj", name: "journey", value: properties.journey});
  eventPropertiesArray.push({id: "lvwXMqzXMp9C", name: "journey_id", value: properties.journeyId});
  eventPropertiesArray.push({id: "HtZ0qgLqVsP6", name: "journey_level", value: properties.journeyLevel});
  eventPropertiesArray.push({id: "StQnPpWOrG18", name: "journey_day", value: properties.journeyDay});
  properties.firstJourney !== undefined && properties.firstJourney !== null ?
    eventPropertiesArray.push({id: "ktrvZyV0Q-nn", name: "first_journey", value: properties.firstJourney}) :
    eventPropertiesArray.push({id: "ktrvZyV0Q-nn", name: "first_journey", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertJourneyLevel(properties.journeyLevel));
    messages = messages.concat(assertJourneyDay(properties.journeyDay));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Rme-h_6TZZ", "722913f547f81c7269554734564fdd14d528487e3584aae1fa20d7af2197c31e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("journey_level_completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Rme-h_6TZZ", "journey_level_completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'journey_level_completed': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("journey_level_completed", eventProperties, "Rme-h_6TZZ", "722913f547f81c7269554734564fdd14d528487e3584aae1fa20d7af2197c31e");
    }
    // destination Segment
    Segment.logEvent("journey_level_completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface AchievementCompletedProperties {
  achievementId: string;
  level: number;
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  source: string;
}
/**
 * achievement_completed: When a user claims an achievement
 *
 * When to trigger this event:
 * 1. Fires when a user selects to claim an achievement.feature_type = 'achievement'
activity_type = 'achievement_claimed'
achievement_id = <achievementId>
level = 7
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/AgpDGJJgs/trigger/QTPas682rg
 *
 * @param properties the properties associatied with this event
 * @param properties.achievementId: id of the achievement being claimed
 * @param properties.level: level of the achievement being claimed
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.source: Shows the source of the external app
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/AgpDGJJgs}
 */
export function achievementCompleted(
  properties: AchievementCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "tDaXN_XQK2Gw", name: "achievement_id", value: properties.achievementId});
  eventPropertiesArray.push({id: "dQ0jZD3klRsM", name: "level", value: properties.level});
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "cca3fFHq8uBC", name: "source", value: properties.source});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "AgpDGJJgs", "031d37b6259e0bb827120c2bf280d4723cb7ef7ec20736d0b0899b9829528266", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("achievement_completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("AgpDGJJgs", "achievement_completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("achievement_completed", eventProperties, "AgpDGJJgs", "031d37b6259e0bb827120c2bf280d4723cb7ef7ec20736d0b0899b9829528266");
    }
    // destination Segment
    Segment.logEvent("achievement_completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PlantAddedProperties {
  challengeId: string;
  dayNumber: number | null | undefined;
  teamId: string | null | undefined;
  teamType: TeamTypeValueType | null | undefined;
  challengeTheme: string | null | undefined;
  challengeType: string | null | undefined;
  plantId: string;
  plantType: string;
  teamName: string | null | undefined;
}
/**
 * plant_added: When a user earns a plant to put into their company challenge garden
 *
 * When to trigger this event:
 * 1. when user completed a goal and added a new plant as part of a challenge

activity_type: daily_activity_completed
feature_type: challenge

It should include the challenge and plant metadata.
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/hZ5tFeyc8/trigger/U5iZq-PD4L
 *
 * @param properties the properties associatied with this event
 * @param properties.challengeId: id of the challenge being completed
 * @param properties.dayNumber: the day number of any event (be it journey level day number, challenge day number etc.)
 * @param properties.teamId: Id of the team the user belongs to. (this is the equivalent of social_group_id)
 * @param properties.teamType: team type for the company challenge events. Values can be 'individual' or 'team'.
 * @param properties.challengeTheme: the type of company challenge (e.g., hydration, movement)
 * @param properties.challengeType: the type of challenge (e.g., journey, biotype, company, group)
 * @param properties.plantId: ID of the plant.
 * @param properties.plantType: The type of plant such as 'plant.community.16'
 * @param properties.teamName: the team name for the company challenge
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/hZ5tFeyc8}
 */
export function plantAdded(properties: PlantAddedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: properties.challengeId});
  properties.dayNumber !== undefined && properties.dayNumber !== null ?
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: properties.dayNumber}) :
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: null});
  properties.teamId !== undefined && properties.teamId !== null ?
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: properties.teamId}) :
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: null});
  properties.teamType !== undefined && properties.teamType !== null ?
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: properties.teamType}) :
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: null});
  properties.challengeTheme !== undefined && properties.challengeTheme !== null ?
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: properties.challengeTheme}) :
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: null});
  properties.challengeType !== undefined && properties.challengeType !== null ?
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: properties.challengeType}) :
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: null});
  eventPropertiesArray.push({id: "AsQ_hs_MP", name: "plant_id", value: properties.plantId});
  eventPropertiesArray.push({id: "CFLgiMVFr", name: "plant_type", value: properties.plantType});
  properties.teamName !== undefined && properties.teamName !== null ?
    eventPropertiesArray.push({id: "G9322ICbi", name: "team_name", value: properties.teamName}) :
    eventPropertiesArray.push({id: "G9322ICbi", name: "team_name", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "hZ5tFeyc8", "c04d126756cd4268c864728515935f95287a49169d0402254e6b0201562d0d9b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("plant_added", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("hZ5tFeyc8", "plant_added", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("plant_added", eventProperties, "hZ5tFeyc8", "c04d126756cd4268c864728515935f95287a49169d0402254e6b0201562d0d9b");
    }
    // destination Segment
    Segment.logEvent("plant_added", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface InviteSentProperties {
  challengeId: string;
  dayNumber: number | null | undefined;
  teamId: string | null | undefined;
  teamType: TeamTypeValueType | null | undefined;
  challengeTheme: string | null | undefined;
  challengeType: string | null | undefined;
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
}
/**
 * invite_sent: Triggers when a user clicks "Send" to invite a user to participate in a Thrive App feature such as challenges.
 *
 * When to trigger this event:
 * 1. Triggers when a user clicks "Send" to invite a user to a challenge.

feature_type: challenge
activity_type: email_sent
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/rVTd4hwT7/trigger/lxg28kBm7v
 * 2. Triggers when a user clicks on "Copy" to invite a user to a challenge.

feature_type: challenge
activity_type: url_copied
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/rVTd4hwT7/trigger/JQRvMElez
 *
 * @param properties the properties associatied with this event
 * @param properties.challengeId: id of the challenge being completed
 * @param properties.dayNumber: the day number of any event (be it journey level day number, challenge day number etc.)
 * @param properties.teamId: Id of the team the user belongs to. (this is the equivalent of social_group_id)
 * @param properties.teamType: team type for the company challenge events. Values can be 'individual' or 'team'.
 * @param properties.challengeTheme: the type of company challenge (e.g., hydration, movement)
 * @param properties.challengeType: the type of challenge (e.g., journey, biotype, company, group)
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/rVTd4hwT7}
 */
export function inviteSent(properties: InviteSentProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: properties.challengeId});
  properties.dayNumber !== undefined && properties.dayNumber !== null ?
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: properties.dayNumber}) :
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: null});
  properties.teamId !== undefined && properties.teamId !== null ?
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: properties.teamId}) :
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: null});
  properties.teamType !== undefined && properties.teamType !== null ?
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: properties.teamType}) :
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: null});
  properties.challengeTheme !== undefined && properties.challengeTheme !== null ?
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: properties.challengeTheme}) :
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: null});
  properties.challengeType !== undefined && properties.challengeType !== null ?
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: properties.challengeType}) :
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: null});
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "rVTd4hwT7", "9754126befd935f2961b1252e6b65d2396299a3e0ee29fe412c68a99178568f3", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("invite_sent", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("rVTd4hwT7", "invite_sent", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("invite_sent", eventProperties, "rVTd4hwT7", "9754126befd935f2961b1252e6b65d2396299a3e0ee29fe412c68a99178568f3");
    }
    // destination Segment
    Segment.logEvent("invite_sent", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface DciSelectedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  challengeId: string | null | undefined;
  dayNumber: number | null | undefined;
  teamId: string | null | undefined;
  teamType: TeamTypeValueType | null | undefined;
  challengeTheme: string | null | undefined;
  challengeType: string | null | undefined;
  dailyCheckInId: string;
}
/**
 * dci_selected: Fires when a user clicks on a daily check-in
 *
 * When to trigger this event:
 * 1. Fires when a user clicks on a company challenge check-in goal


feature_type: challenge
activity_type: daily_activity_completed
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/FeXfMvSHD-/trigger/gbMaN38Hy
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.challengeId: id of the challenge being completed
 * @param properties.dayNumber: the day number of any event (be it journey level day number, challenge day number etc.)
 * @param properties.teamId: Id of the team the user belongs to. (this is the equivalent of social_group_id)
 * @param properties.teamType: team type for the company challenge events. Values can be 'individual' or 'team'.
 * @param properties.challengeTheme: the type of company challenge (e.g., hydration, movement)
 * @param properties.challengeType: the type of challenge (e.g., journey, biotype, company, group)
 * @param properties.dailyCheckInId: The survey question ID
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/FeXfMvSHD-}
 */
export function dciSelected(properties: DciSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.challengeId !== undefined && properties.challengeId !== null ?
    eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: properties.challengeId}) :
    eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: null});
  properties.dayNumber !== undefined && properties.dayNumber !== null ?
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: properties.dayNumber}) :
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: null});
  properties.teamId !== undefined && properties.teamId !== null ?
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: properties.teamId}) :
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: null});
  properties.teamType !== undefined && properties.teamType !== null ?
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: properties.teamType}) :
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: null});
  properties.challengeTheme !== undefined && properties.challengeTheme !== null ?
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: properties.challengeTheme}) :
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: null});
  properties.challengeType !== undefined && properties.challengeType !== null ?
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: properties.challengeType}) :
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: null});
  eventPropertiesArray.push({id: "xa-5OADo2eC_", name: "daily_check_in_id", value: properties.dailyCheckInId});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "FeXfMvSHD-", "7628d05caa9991c2df7f31af773a82a2c9937a8fbdcefc93e228c53ab1fa7b37", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("dci_selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("FeXfMvSHD-", "dci_selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("dci_selected", eventProperties, "FeXfMvSHD-", "7628d05caa9991c2df7f31af773a82a2c9937a8fbdcefc93e228c53ab1fa7b37");
    }
    // destination Segment
    Segment.logEvent("dci_selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ContentServedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  contentId: string | null | undefined;
  contentType: ContentTypeValueType | null | undefined;
  contentTitle: string | null | undefined;
  contentSubtype: ContentSubtypeValueType | null | undefined;
  contentFormatType: ContentFormatTypeValueType | null | undefined;
  contentUrl: string | null | undefined;
  contentSource: ContentSourceValueType | null | undefined;
  tstPlatform: TstPlatformValueType | null | undefined;
}
/**
 * content_served: When a piece of content is served to the user.
 *
 * When to trigger this event:
 * 1. For each of the pieces of content served:

feature_type = 'library'
activity_type = 'recommended_content_served'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/wo0Qec9f8h/trigger/if6I2YhzX
 * 2. feature_type = 'library'
activity_type = 'popular_content_served'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/wo0Qec9f8h/trigger/cyh72uKiw
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.contentId: Recommended Content ID including external resources
 * @param properties.contentType: The type of content (reset, video, article etc.) in English
 * @param properties.contentTitle: The title of the content (Resets, Articles, Videos etc.) including external resources as well
 * @param properties.contentSubtype: Secondary type based on the content type:
Articles: role_model | recipe | watch_me_thrive | article

 * @param properties.contentFormatType: The format of the content displayed/consumed.

Articles: audio | video | text
Resets: audio | video
External resources: pdf | audio | video | text
 * @param properties.contentUrl: This is the URL of the content:
- If it is an iframe rendered - it would be the iframe full URL
- If it is an external resource (such as benefit connect / sequoia links) - it would be the external full URL

example:
https://thriveglobal.showpad.com/share/cpCAwJwBjxiFfegfWnIHj
https://login.sequoia.com/
 * @param properties.contentSource: Describes the source of the content or elements within the content (UMG audio for personal resets for example)
 * @param properties.tstPlatform: The call center platform upon which TST Resets are served
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/wo0Qec9f8h}
 */
export function contentServed(properties: ContentServedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.contentId !== undefined && properties.contentId !== null ?
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: properties.contentId}) :
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: null});
  properties.contentType !== undefined && properties.contentType !== null ?
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: properties.contentType}) :
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: null});
  properties.contentTitle !== undefined && properties.contentTitle !== null ?
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: properties.contentTitle}) :
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: null});
  properties.contentSubtype !== undefined && properties.contentSubtype !== null ?
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: properties.contentSubtype}) :
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: null});
  properties.contentFormatType !== undefined && properties.contentFormatType !== null ?
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: properties.contentFormatType}) :
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: null});
  properties.contentUrl !== undefined && properties.contentUrl !== null ?
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: properties.contentUrl}) :
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: null});
  properties.contentSource !== undefined && properties.contentSource !== null ?
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: properties.contentSource}) :
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: null});
  properties.tstPlatform !== undefined && properties.tstPlatform !== null ?
    eventPropertiesArray.push({id: "LSAAiy9CP", name: "tst_platform", value: properties.tstPlatform}) :
    eventPropertiesArray.push({id: "LSAAiy9CP", name: "tst_platform", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "wo0Qec9f8h", "b32fd83ce5b2311cdbfe1efab54fd7af35f3dcfe3b0154d0eef87fbff0990c24", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("content_served", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("wo0Qec9f8h", "content_served", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("content_served", eventProperties, "wo0Qec9f8h", "b32fd83ce5b2311cdbfe1efab54fd7af35f3dcfe3b0154d0eef87fbff0990c24");
    }
    // destination Segment
    Segment.logEvent("content_served", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PageViewedProperties {
  subscriptionId: string;
  subscriptionType: string;
  license: string;
  isLoggedIn: string;
  previousPath: string;
  companyId: string | null | undefined;
  userId_: string;
}
/**
 * Page Viewed: This is the page calls event. The name is pre-determined on Segment.
https://segment.com/docs/connections/spec/page/

It does not show up as an event on tracks table, but all the page views are loaded into `pages` table.

Triggers on every page load in the app.
Source code: https://github.com/thriveglobal/thrive-web-root/blob/staging/src/utils/segment.ts#L33
 *
 * When to trigger this event:
 * 1. fires on all pages - this is just one example of a page load
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/LqhDtai4fp/trigger/l6qCuLWO6
 *
 * @param properties the properties associatied with this event
 * @param properties.subscriptionId: Subscription ID that a company has
 * @param properties.subscriptionType: The type of subscription a user has
 * @param properties.license: The type of license a company has with Thrive
 * @param properties.isLoggedIn: True if user is logged in. Otherwise null.
 * @param properties.previousPath: Previous Micro Frontend the user came from
 * @param properties.companyId: ID of the company.
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/LqhDtai4fp}
 */
export function pageViewed(properties: PageViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "lhNCuk9Xd8A1", name: "subscription_id", value: properties.subscriptionId});
  eventPropertiesArray.push({id: "j2e-0ySYTB", name: "subscription_type", value: properties.subscriptionType});
  eventPropertiesArray.push({id: "gNhhk8W44l", name: "license", value: properties.license});
  eventPropertiesArray.push({id: "ioZ2j5wBem", name: "is_logged_in", value: properties.isLoggedIn});
  eventPropertiesArray.push({id: "CuLJJR3OpL", name: "previous_path", value: properties.previousPath});
  properties.companyId !== undefined && properties.companyId !== null ?
    eventPropertiesArray.push({id: "h_HkYy-iweh5", name: "company_id", value: properties.companyId}) :
    eventPropertiesArray.push({id: "h_HkYy-iweh5", name: "company_id", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "LqhDtai4fp", "c10d66734e86bff8f4d3bb3c006c9d489c61800ee59fb18f756fe332d50a2738", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("Page Viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("LqhDtai4fp", "Page Viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("Page Viewed", eventProperties, "LqhDtai4fp", "c10d66734e86bff8f4d3bb3c006c9d489c61800ee59fb18f756fe332d50a2738");
    }
    // destination Segment
    Segment.identify(properties.userId_);
    Segment.logEvent("Page Viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ContentSelectedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  contentId: string | null | undefined;
  contentType: ContentTypeValueType | null | undefined;
  contentTitle: string | null | undefined;
  contentSubtype: ContentSubtypeValueType | null | undefined;
  contentFormatType: ContentFormatTypeValueType | null | undefined;
  contentUrl: string | null | undefined;
  contentSource: ContentSourceValueType | null | undefined;
}
/**
 * content_selected: When a user selects a content.

This might fire simultaneously with content_started for a text article/recipe, but for podcasts, videos etc., this will fire when the user clicks on the content, and content started will fire when the user actually starts the content.
 *
 * When to trigger this event:
 * 1. feature_type = library
activity_type = recommended_content_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/KA82czQVRs/trigger/jMjV6jrkU
 * 2. feature_type = library
activity_type = popular_content_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/KA82czQVRs/trigger/zh8RlOA1h
 * 3. Event fires when user clicks on Today's Reset on the Journeys page
feature_type: content
activity_type: today_reset_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/KA82czQVRs/trigger/0LlfwISpr
 * 4. When a user clicks to begin an audio reset

feature_type: content
activity_type: audio_reset_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/KA82czQVRs/trigger/xzXx3UbZQ
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.contentId: Recommended Content ID including external resources
 * @param properties.contentType: The type of content (reset, video, article etc.) in English
 * @param properties.contentTitle: The title of the content (Resets, Articles, Videos etc.) including external resources as well
 * @param properties.contentSubtype: Secondary type based on the content type:
Articles: role_model | recipe | watch_me_thrive | article

 * @param properties.contentFormatType: The format of the content displayed/consumed.

Articles: audio | video | text
Resets: audio | video
External resources: pdf | audio | video | text
 * @param properties.contentUrl: This is the URL of the content:
- If it is an iframe rendered - it would be the iframe full URL
- If it is an external resource (such as benefit connect / sequoia links) - it would be the external full URL

example:
https://thriveglobal.showpad.com/share/cpCAwJwBjxiFfegfWnIHj
https://login.sequoia.com/
 * @param properties.contentSource: Describes the source of the content or elements within the content (UMG audio for personal resets for example)
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/KA82czQVRs}
 */
export function contentSelected(properties: ContentSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.contentId !== undefined && properties.contentId !== null ?
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: properties.contentId}) :
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: null});
  properties.contentType !== undefined && properties.contentType !== null ?
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: properties.contentType}) :
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: null});
  properties.contentTitle !== undefined && properties.contentTitle !== null ?
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: properties.contentTitle}) :
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: null});
  properties.contentSubtype !== undefined && properties.contentSubtype !== null ?
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: properties.contentSubtype}) :
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: null});
  properties.contentFormatType !== undefined && properties.contentFormatType !== null ?
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: properties.contentFormatType}) :
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: null});
  properties.contentUrl !== undefined && properties.contentUrl !== null ?
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: properties.contentUrl}) :
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: null});
  properties.contentSource !== undefined && properties.contentSource !== null ?
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: properties.contentSource}) :
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "KA82czQVRs", "738da3c7a2f1ca590aa41108af55d533df1fb180b7f7cc2fbed73093f3f81734", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("content_selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("KA82czQVRs", "content_selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("content_selected", eventProperties, "KA82czQVRs", "738da3c7a2f1ca590aa41108af55d533df1fb180b7f7cc2fbed73093f3f81734");
    }
    // destination Segment
    Segment.logEvent("content_selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SurveySubscribedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  isOnboarding: boolean;
  userId_: string;
  dciFrequency: DciFrequencyValueType;
  tabName: TabNameValueType | null | undefined;
  onboardingAttemptNumber: number | null | undefined;
}
/**
 * survey_subscribed: Fires when a user selects "Consent & get started" and is therefore subscribed to DCI (this event cannot be tracked upon the user selecting the button, and must therefore fire when the user reaches the landing page of this CTA).
 *
 * When to trigger this event:
 * 1. (Onboarding trigger 2/2)
When a user selects "Consent & get started", they will be subscribed to DCI. This event cannot be tracked upon the user selecting the button, and must therefore fire when the user reaches the landing page of this CTA.
feature_type = 'journeys'
activity_type = 'dci_subscribed'
is_onboarding = true
onboarding_attempt_number = 1
dci_frequency = <DCI_FREQ_DISABLED|DCI_FREQ_DAILY>
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/ErvXR2vwfW/trigger/S8CghOadd
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.isOnboarding: T/F flag for whether an event occurred during onboarding or not.
 * @param properties.userId_: The value used to identify the user. Make sure it's a unique sequence of characters used to identify the user.
 * @param properties.dciFrequency: A string value of the DCI frequency type
 * @param properties.tabName: The tab on which the Slack or MS Teams event took place. This is limited to Home Chat and About for now
 * @param properties.onboardingAttemptNumber: When onboarding a user, we may make several attempts to get them to complete onboarding (i.e. selecting a journey). We will use this property as a flag to know how many attempts we've made to get a user to complete onboarding.
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/ErvXR2vwfW}
 */
export function surveySubscribed(properties: SurveySubscribedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "RCLdkrcD0", name: "is_onboarding", value: properties.isOnboarding});
  eventPropertiesArray.push({id: "rBDw-pNco", name: "dci_frequency", value: properties.dciFrequency});
  properties.tabName !== undefined && properties.tabName !== null ?
    eventPropertiesArray.push({id: "notiOkO1a", name: "tab_name", value: properties.tabName}) :
    eventPropertiesArray.push({id: "notiOkO1a", name: "tab_name", value: null});
  properties.onboardingAttemptNumber !== undefined && properties.onboardingAttemptNumber !== null ?
    eventPropertiesArray.push({id: "qN5ovMFjx", name: "onboarding_attempt_number", value: properties.onboardingAttemptNumber}) :
    eventPropertiesArray.push({id: "qN5ovMFjx", name: "onboarding_attempt_number", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "ErvXR2vwfW", "ba41cad383c12642af89bdd76467d9eabaee83ca470f5f54b6c078119cc8572d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("survey_subscribed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("ErvXR2vwfW", "survey_subscribed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("survey_subscribed", eventProperties, "ErvXR2vwfW", "ba41cad383c12642af89bdd76467d9eabaee83ca470f5f54b6c078119cc8572d");
    }
    // destination Segment
    Segment.identify(properties.userId_);
    Segment.logEvent("survey_subscribed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface OnboardingCompletedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  isOnboarding: boolean;
  journey: string;
  journeyId: string;
  journeyLevel: number;
  journeyDay: number;
  firstJourney: boolean | null | undefined;
  onboardingAttemptNumber: number | null | undefined;
}
/**
 * onboarding_completed: Fires when a user meets the requirement to complete onboarding (i.e. selecting a Journey).
 *
 * When to trigger this event:
 * 1. Fires after a user selects a Journey and lands on this screen.
feature_type = 'journeys'
activity_type = 'onboarding_completed'
is_onboarding = true
onboarding_attempt_number = 1
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/skJGjjs5fa/trigger/EFNMpLeab
 * 2. Fires when a NEW USER clicks "See my personalized plan" during intention setting/onboarding.

This event should not fire for existing users who are going through the intention setting flow post-onboarding.

feature_type: intention_setting
activity_type = 'onboarding_completed'
is_onboarding = true
onboarding_attempt_number = 1
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/skJGjjs5fa/trigger/Pb_YmIU80
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.isOnboarding: T/F flag for whether an event occurred during onboarding or not.
 * @param properties.journey: Name of the journey
 * @param properties.journeyId: Id of the journey
 * @param properties.journeyLevel: Level of the journey that a user is on
 * @param properties.journeyDay: The day of the journey that a user is on.
 * @param properties.firstJourney: True if journey is the user's first journey selection.
 * @param properties.onboardingAttemptNumber: When onboarding a user, we may make several attempts to get them to complete onboarding (i.e. selecting a journey). We will use this property as a flag to know how many attempts we've made to get a user to complete onboarding.
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/skJGjjs5fa}
 */
export function onboardingCompleted(properties: OnboardingCompletedProperties
  ) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "RCLdkrcD0", name: "is_onboarding", value: properties.isOnboarding});
  eventPropertiesArray.push({id: "F3BjAMmBpGqj", name: "journey", value: properties.journey});
  eventPropertiesArray.push({id: "lvwXMqzXMp9C", name: "journey_id", value: properties.journeyId});
  eventPropertiesArray.push({id: "HtZ0qgLqVsP6", name: "journey_level", value: properties.journeyLevel});
  eventPropertiesArray.push({id: "StQnPpWOrG18", name: "journey_day", value: properties.journeyDay});
  properties.firstJourney !== undefined && properties.firstJourney !== null ?
    eventPropertiesArray.push({id: "ktrvZyV0Q-nn", name: "first_journey", value: properties.firstJourney}) :
    eventPropertiesArray.push({id: "ktrvZyV0Q-nn", name: "first_journey", value: null});
  properties.onboardingAttemptNumber !== undefined && properties.onboardingAttemptNumber !== null ?
    eventPropertiesArray.push({id: "qN5ovMFjx", name: "onboarding_attempt_number", value: properties.onboardingAttemptNumber}) :
    eventPropertiesArray.push({id: "qN5ovMFjx", name: "onboarding_attempt_number", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertJourneyLevel(properties.journeyLevel));
    messages = messages.concat(assertJourneyDay(properties.journeyDay));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "skJGjjs5fa", "ecff5dac2dcea6a9034aa3463590d8244322dbb16b410402a86536af055ee48d", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("onboarding_completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("skJGjjs5fa", "onboarding_completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'onboarding_completed': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("onboarding_completed", eventProperties, "skJGjjs5fa", "ecff5dac2dcea6a9034aa3463590d8244322dbb16b410402a86536af055ee48d");
    }
    // destination Segment
    Segment.logEvent("onboarding_completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SurveyStartedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  isOnboarding: boolean;
  assessmentId: string;
  assessmentName: string;
  assessmentVariant: string;
  assessmentAttemptId: string;
  onboardingAttemptNumber: number | null | undefined;
  responseText: string | null | undefined;
}
/**
 * survey_started: Fires when a user initiates the process of completing a survey.
 *
 * When to trigger this event:
 * 1. Fires when a user selects "Get started" to begin their onboarding assessment survey.
feature_type = 'assessment'
activity_type = 'onboarding_assessment_started'
is_onboarding = true
onboarding_attempt_number = 1
+ assessment_properties bundle
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/4R2Y7IIZKn/trigger/x9vAd4eRc
 * 2. Fires when a user selects "Take Assessment" on the Progress Assessment prompt.
feature_type = 'assessment'
activity_type = 'progress_assessment_started'
+assessment_properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/4R2Y7IIZKn/trigger/LZTDKi9_s
 * 3. Fires when a user selects "Get started" to begin their onboarding intention setting survey.

feature_type = 'intention_setting'
activity_type = 'onboarding_started'

If this is a New User,

is_onboarding = true
onboarding_attempt_number = 1
+ assessment_properties bundle
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/4R2Y7IIZKn/trigger/DmxOp7zVr
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.isOnboarding: T/F flag for whether an event occurred during onboarding or not.
 * @param properties.assessmentId: The ID of the assessment.
 * @param properties.assessmentName: The name of the assessment.
 * @param properties.assessmentVariant: Assessment version. This can be used to compare a baseline/default assessment to a v1, v2, etc. version.
 * @param properties.assessmentAttemptId: Attempt ID for assessments
 * @param properties.onboardingAttemptNumber: When onboarding a user, we may make several attempts to get them to complete onboarding (i.e. selecting a journey). We will use this property as a flag to know how many attempts we've made to get a user to complete onboarding.
 * @param properties.responseText: The response to an assessment question (e.g. 'Strongly agree|Agree|Neither agree nor disagree|Disagree|Strongly disagree|NULL')
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/4R2Y7IIZKn}
 */
export function surveyStarted(properties: SurveyStartedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "RCLdkrcD0", name: "is_onboarding", value: properties.isOnboarding});
  eventPropertiesArray.push({id: "ps_LGjiEp", name: "assessment_id", value: properties.assessmentId});
  eventPropertiesArray.push({id: "sHi3O7n8i", name: "assessment_name", value: properties.assessmentName});
  eventPropertiesArray.push({id: "jxvsbVrYg_", name: "assessment_variant", value: properties.assessmentVariant});
  eventPropertiesArray.push({id: "PKvaMlpRV", name: "assessment_attempt_id", value: properties.assessmentAttemptId});
  properties.onboardingAttemptNumber !== undefined && properties.onboardingAttemptNumber !== null ?
    eventPropertiesArray.push({id: "qN5ovMFjx", name: "onboarding_attempt_number", value: properties.onboardingAttemptNumber}) :
    eventPropertiesArray.push({id: "qN5ovMFjx", name: "onboarding_attempt_number", value: null});
  properties.responseText !== undefined && properties.responseText !== null ?
    eventPropertiesArray.push({id: "32zgCY_PY", name: "response_text", value: properties.responseText}) :
    eventPropertiesArray.push({id: "32zgCY_PY", name: "response_text", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "4R2Y7IIZKn", "8926b3b053eeb34a2d4457891777cff6dbe65a1b6b2f0d3f076c2912bf459118", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("survey_started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("4R2Y7IIZKn", "survey_started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("survey_started", eventProperties, "4R2Y7IIZKn", "8926b3b053eeb34a2d4457891777cff6dbe65a1b6b2f0d3f076c2912bf459118");
    }
    // destination Segment
    Segment.logEvent("survey_started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SurveyCompletedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  isOnboarding: boolean;
  assessmentId: string;
  assessmentName: string;
  assessmentVariant: string;
  assessmentAttemptId: string;
}
/**
 * survey_completed: Fires when a user completes the process of submitting a survey.
 *
 * When to trigger this event:
 * 1. Fires when a user responds to the final onboarding assessment question to complete their onboarding assessment survey.
feature_type = 'assessment'
activity_type = 'onboarding_assessment_completed'
is_onboarding = true
onboarding_attempt_number = 1
assessment_variant = 'baseline'|'v1'|'v2'|etc.
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/-a_pOEW20i/trigger/RigMSbzsl
 * 2. Fires when a user completes their Progress Assessment
feature_type = 'assessment'
activity_type = 'progress_assessment_completed'
+ assessment properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/-a_pOEW20i/trigger/lUP93aJKc
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.isOnboarding: T/F flag for whether an event occurred during onboarding or not.
 * @param properties.assessmentId: The ID of the assessment.
 * @param properties.assessmentName: The name of the assessment.
 * @param properties.assessmentVariant: Assessment version. This can be used to compare a baseline/default assessment to a v1, v2, etc. version.
 * @param properties.assessmentAttemptId: Attempt ID for assessments
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/-a_pOEW20i}
 */
export function surveyCompleted(properties: SurveyCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "RCLdkrcD0", name: "is_onboarding", value: properties.isOnboarding});
  eventPropertiesArray.push({id: "ps_LGjiEp", name: "assessment_id", value: properties.assessmentId});
  eventPropertiesArray.push({id: "sHi3O7n8i", name: "assessment_name", value: properties.assessmentName});
  eventPropertiesArray.push({id: "jxvsbVrYg_", name: "assessment_variant", value: properties.assessmentVariant});
  eventPropertiesArray.push({id: "PKvaMlpRV", name: "assessment_attempt_id", value: properties.assessmentAttemptId});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "-a_pOEW20i", "df4a3a5bc641e97b5e0e8ed7067be66bf87270ff0bb01fcee21a140b07dd0778", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("survey_completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("-a_pOEW20i", "survey_completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("survey_completed", eventProperties, "-a_pOEW20i", "df4a3a5bc641e97b5e0e8ed7067be66bf87270ff0bb01fcee21a140b07dd0778");
    }
    // destination Segment
    Segment.logEvent("survey_completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface GardenViewedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  plantId: string | null | undefined;
  plantType: string | null | undefined;
}
/**
 * garden_viewed: Fires when a user visits their garden.
 *
 * When to trigger this event:
 * 1. Fires when a user selects the "View your Garden" prompt on the Journeys screen (post onboarding).
feature_type = 'achievement'
activity_type = 'plant_viewed'
+ plant properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/FHSRN97tbK/trigger/Yb4s53vzx
 * 2. Fires whenever a user selects a prompt that takes them to the plant shop.
feature_type = 'achievement'
activity_type = 'plant_shop_viewed'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/FHSRN97tbK/trigger/n561buCkQ
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.plantId: ID of the plant.
 * @param properties.plantType: The type of plant such as 'plant.community.16'
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/FHSRN97tbK}
 */
export function gardenViewed(properties: GardenViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.plantId !== undefined && properties.plantId !== null ?
    eventPropertiesArray.push({id: "AsQ_hs_MP", name: "plant_id", value: properties.plantId}) :
    eventPropertiesArray.push({id: "AsQ_hs_MP", name: "plant_id", value: null});
  properties.plantType !== undefined && properties.plantType !== null ?
    eventPropertiesArray.push({id: "CFLgiMVFr", name: "plant_type", value: properties.plantType}) :
    eventPropertiesArray.push({id: "CFLgiMVFr", name: "plant_type", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "FHSRN97tbK", "512aae644bef2e49e3dc80312f4543f992e04a8aef7f0d6a8f0f114c23ea0bbf", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("garden_viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("FHSRN97tbK", "garden_viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("garden_viewed", eventProperties, "FHSRN97tbK", "512aae644bef2e49e3dc80312f4543f992e04a8aef7f0d6a8f0f114c23ea0bbf");
    }
    // destination Segment
    Segment.logEvent("garden_viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface AchievementViewedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  achievementId: string;
  level: number;
}
/**
 * achievement_viewed: Fires when a user selects the prompt to complete an achievement badge/token.
 *
 * When to trigger this event:
 * 1. Fires when a user selects any of the achievement levels/badges to complete an achievement.
feature_type = 'achievement'
activity_type = 'microstep_viewed'
OR activity_type = 'dci_viewed'
OR activity_type = 'reset_viewed'
OR activity_type = 'journey_viewed'
+ achievement properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/MqA97Xf7NE/trigger/KjEfVPGAG
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.achievementId: id of the achievement being claimed
 * @param properties.level: level of the achievement being claimed
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/MqA97Xf7NE}
 */
export function achievementViewed(properties: AchievementViewedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "tDaXN_XQK2Gw", name: "achievement_id", value: properties.achievementId});
  eventPropertiesArray.push({id: "dQ0jZD3klRsM", name: "level", value: properties.level});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "MqA97Xf7NE", "e7669aa7ff0df5a95ea8eab3509883f149402e8657758f5a274d2db511f6adef", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("achievement_viewed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("MqA97Xf7NE", "achievement_viewed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("achievement_viewed", eventProperties, "MqA97Xf7NE", "e7669aa7ff0df5a95ea8eab3509883f149402e8657758f5a274d2db511f6adef");
    }
    // destination Segment
    Segment.logEvent("achievement_viewed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SigninFailedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  method: MethodValueType;
  reason: string;
}
/**
 * signin_failed: When a user signin fails
 *
 * When to trigger this event:
 * 1. When a user signin fails

feature_type:
activity_type: signin_failed
status: failed
reason: <reason>
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/zuZdfHzngx/trigger/aA7_o-zyV
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.method: Identity authentication methods
 * @param properties.reason: The reason for the failure or for serving specific content
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/zuZdfHzngx}
 */
export function signinFailed(properties: SigninFailedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "2bb9tCwDwOc3", name: "method", value: properties.method});
  eventPropertiesArray.push({id: "V5egf6WRb7GV", name: "reason", value: properties.reason});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "zuZdfHzngx", "1d3c7cba7b1a4e665c2b43fa2e50ce6ae024c58ef7ccbd1434bda5f3e0ed885a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("signin_failed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("zuZdfHzngx", "signin_failed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("signin_failed", eventProperties, "zuZdfHzngx", "1d3c7cba7b1a4e665c2b43fa2e50ce6ae024c58ef7ccbd1434bda5f3e0ed885a");
    }
    // destination Segment
    Segment.logEvent("signin_failed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface PromptServedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  utmSource: string | null | undefined;
}
/**
 * prompt_served: When a prompt is served to a user.
 *
 * When to trigger this event:
 * 1. When a user is served the company-specific banner

feature_type: journeys
activity_type: company_banner_viewed
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/zbl7ZcLPKG/trigger/9vU9Def98
 * 2. Fires when a user hovers/focuses on the "Skip to Main content" button.
feature_type = 'profile'
activity_type = 'accessible_skip_nav_served'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/zbl7ZcLPKG/trigger/g58QAuugG
 * 3. When a user is served the notification that gives them the option to enable browser notifications.

feature_type: notifications
activity_type: allow_browser_notifications_served
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/zbl7ZcLPKG/trigger/WtqNdkgMk
 * 4. When a user is served the option to allow browser notifications from their browser

feature_type: notifications
activity_type: allow_desktop_notifications_served
utm_source: browser_notification
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/zbl7ZcLPKG/trigger/4-bwfOTjw
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.utmSource: no description
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/zbl7ZcLPKG}
 */
export function promptServed(properties: PromptServedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.utmSource !== undefined && properties.utmSource !== null ?
    eventPropertiesArray.push({id: "a9ractdymVEs", name: "utm_source", value: properties.utmSource}) :
    eventPropertiesArray.push({id: "a9ractdymVEs", name: "utm_source", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "zbl7ZcLPKG", "0d390657e63d923a18e12ae45a430a910c7596026a41321e44de20e9c12c3552", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("prompt_served", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("zbl7ZcLPKG", "prompt_served", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("prompt_served", eventProperties, "zbl7ZcLPKG", "0d390657e63d923a18e12ae45a430a910c7596026a41321e44de20e9c12c3552");
    }
    // destination Segment
    Segment.logEvent("prompt_served", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface RecommendationServedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  journey: string | null | undefined;
  journeyId: string | null | undefined;
  journeyLevel: number | null | undefined;
  journeyDay: number | null | undefined;
  firstJourney: boolean | null | undefined;
  assessmentId: string;
  assessmentName: string;
  assessmentVariant: string;
  assessmentAttemptId: string | null | undefined;
}
/**
 * recommendation_served: Fires when a user is served a recommendation.
 *
 * When to trigger this event:
 * 1. Fires when a user is recommended a Journey after completing their onboarding assessment.
feature_type = 'assessment'
activity_type = 'recommended_journey_served'|'default_recommended_journey_served'
+journey_properties
+assessment_properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/ebfycMdlN8/trigger/_dVdQE_wC
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.journey: Name of the journey
 * @param properties.journeyId: Id of the journey
 * @param properties.journeyLevel: Level of the journey that a user is on
 * @param properties.journeyDay: The day of the journey that a user is on.
 * @param properties.firstJourney: True if journey is the user's first journey selection.
 * @param properties.assessmentId: The ID of the assessment.
 * @param properties.assessmentName: The name of the assessment.
 * @param properties.assessmentVariant: Assessment version. This can be used to compare a baseline/default assessment to a v1, v2, etc. version.
 * @param properties.assessmentAttemptId: Attempt ID for assessments
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/ebfycMdlN8}
 */
export function recommendationServed(
  properties: RecommendationServedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.journey !== undefined && properties.journey !== null ?
    eventPropertiesArray.push({id: "F3BjAMmBpGqj", name: "journey", value: properties.journey}) :
    eventPropertiesArray.push({id: "F3BjAMmBpGqj", name: "journey", value: null});
  properties.journeyId !== undefined && properties.journeyId !== null ?
    eventPropertiesArray.push({id: "lvwXMqzXMp9C", name: "journey_id", value: properties.journeyId}) :
    eventPropertiesArray.push({id: "lvwXMqzXMp9C", name: "journey_id", value: null});
  properties.journeyLevel !== undefined && properties.journeyLevel !== null ?
    eventPropertiesArray.push({id: "HtZ0qgLqVsP6", name: "journey_level", value: properties.journeyLevel}) :
    eventPropertiesArray.push({id: "HtZ0qgLqVsP6", name: "journey_level", value: null});
  properties.journeyDay !== undefined && properties.journeyDay !== null ?
    eventPropertiesArray.push({id: "StQnPpWOrG18", name: "journey_day", value: properties.journeyDay}) :
    eventPropertiesArray.push({id: "StQnPpWOrG18", name: "journey_day", value: null});
  properties.firstJourney !== undefined && properties.firstJourney !== null ?
    eventPropertiesArray.push({id: "ktrvZyV0Q-nn", name: "first_journey", value: properties.firstJourney}) :
    eventPropertiesArray.push({id: "ktrvZyV0Q-nn", name: "first_journey", value: null});
  eventPropertiesArray.push({id: "ps_LGjiEp", name: "assessment_id", value: properties.assessmentId});
  eventPropertiesArray.push({id: "sHi3O7n8i", name: "assessment_name", value: properties.assessmentName});
  eventPropertiesArray.push({id: "jxvsbVrYg_", name: "assessment_variant", value: properties.assessmentVariant});
  properties.assessmentAttemptId !== undefined && properties.assessmentAttemptId !== null ?
    eventPropertiesArray.push({id: "PKvaMlpRV", name: "assessment_attempt_id", value: properties.assessmentAttemptId}) :
    eventPropertiesArray.push({id: "PKvaMlpRV", name: "assessment_attempt_id", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    messages = messages.concat(assertJourneyLevelOptional(properties.journeyLevel));
    messages = messages.concat(assertJourneyDayOptional(properties.journeyDay));
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "ebfycMdlN8", "35723efa7fb0bd02333b5e6b82fec162887a53206642b26ffd5b4c4cfdd15357", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("recommendation_served", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("ebfycMdlN8", "recommendation_served", messages, eventPropertiesArray, userPropertiesArray, []);
    }
    // @ts-ignore
    if (__AVO_ENV__ !== AvoEnv.Prod && (__STRICT__ === null || __STRICT__)) {
      // throw exception if messages is not empty
      if (messages.length !== 0) {
        throw new Error("Error sending event 'recommendation_served': " + messages[0]!.message)
      }
    } else {
      messages.forEach(function(m) {
        console[__REPORT_FAILURE_AS__ || 'error']("[avo] " + m.message);
      });
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("recommendation_served", eventProperties, "ebfycMdlN8", "35723efa7fb0bd02333b5e6b82fec162887a53206642b26ffd5b4c4cfdd15357");
    }
    // destination Segment
    Segment.logEvent("recommendation_served", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SearchStartedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  term: string;
  query: string;
}
/**
 * search_started: Fires when a user triggers a term search.
 *
 * When to trigger this event:
 * 1. Fires when a user enters a search value/term.
feature_type = 'search'
activity_type = 'term_searched'
+ term values
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/90G5YOXO3k/trigger/PMYR2ikW_
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.term: Suggested query term
 * @param properties.query: Search term that was used
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/90G5YOXO3k}
 */
export function searchStarted(properties: SearchStartedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "-gKSa-qOnjyO", name: "term", value: properties.term});
  eventPropertiesArray.push({id: "AVQOoz4UzjV0", name: "query", value: properties.query});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "90G5YOXO3k", "26614564ef3d960864ffcb5b5c999cca9433aea79e432be538a1ca56adbfdf57", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("search_started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("90G5YOXO3k", "search_started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("search_started", eventProperties, "90G5YOXO3k", "26614564ef3d960864ffcb5b5c999cca9433aea79e432be538a1ca56adbfdf57");
    }
    // destination Segment
    Segment.logEvent("search_started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ChallengeCompletedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  challengeId: string;
  dayNumber: number | null | undefined;
  teamId: string | null | undefined;
  teamType: TeamTypeValueType | null | undefined;
  challengeTheme: string | null | undefined;
  challengeType: string | null | undefined;
}
/**
 * challenge_completed: When a user completes a challenge
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.challengeId: id of the challenge being completed
 * @param properties.dayNumber: the day number of any event (be it journey level day number, challenge day number etc.)
 * @param properties.teamId: Id of the team the user belongs to. (this is the equivalent of social_group_id)
 * @param properties.teamType: team type for the company challenge events. Values can be 'individual' or 'team'.
 * @param properties.challengeTheme: the type of company challenge (e.g., hydration, movement)
 * @param properties.challengeType: the type of challenge (e.g., journey, biotype, company, group)
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/BNrkDOxteZ}
 */
export function challengeCompleted(properties: ChallengeCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: properties.challengeId});
  properties.dayNumber !== undefined && properties.dayNumber !== null ?
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: properties.dayNumber}) :
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: null});
  properties.teamId !== undefined && properties.teamId !== null ?
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: properties.teamId}) :
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: null});
  properties.teamType !== undefined && properties.teamType !== null ?
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: properties.teamType}) :
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: null});
  properties.challengeTheme !== undefined && properties.challengeTheme !== null ?
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: properties.challengeTheme}) :
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: null});
  properties.challengeType !== undefined && properties.challengeType !== null ?
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: properties.challengeType}) :
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "BNrkDOxteZ", "e458ecb260761b439fa0faaea6ef3ab55619a7b69ef3a392eb82fea4b85da97b", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("challenge_completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("BNrkDOxteZ", "challenge_completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("challenge_completed", eventProperties, "BNrkDOxteZ", "e458ecb260761b439fa0faaea6ef3ab55619a7b69ef3a392eb82fea4b85da97b");
    }
    // destination Segment
    Segment.logEvent("challenge_completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface IssueReportedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  contentId: string | null | undefined;
  contentType: ContentTypeValueType | null | undefined;
  contentTitle: string | null | undefined;
  contentSubtype: ContentSubtypeValueType | null | undefined;
  contentFormatType: ContentFormatTypeValueType | null | undefined;
  contentUrl: string | null | undefined;
  contentSource: ContentSourceValueType | null | undefined;
  tstPlatform: TstPlatformValueType | null | undefined;
}
/**
 * issue_reported: When a user reports an issue
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.contentId: Recommended Content ID including external resources
 * @param properties.contentType: The type of content (reset, video, article etc.) in English
 * @param properties.contentTitle: The title of the content (Resets, Articles, Videos etc.) including external resources as well
 * @param properties.contentSubtype: Secondary type based on the content type:
Articles: role_model | recipe | watch_me_thrive | article

 * @param properties.contentFormatType: The format of the content displayed/consumed.

Articles: audio | video | text
Resets: audio | video
External resources: pdf | audio | video | text
 * @param properties.contentUrl: This is the URL of the content:
- If it is an iframe rendered - it would be the iframe full URL
- If it is an external resource (such as benefit connect / sequoia links) - it would be the external full URL

example:
https://thriveglobal.showpad.com/share/cpCAwJwBjxiFfegfWnIHj
https://login.sequoia.com/
 * @param properties.contentSource: Describes the source of the content or elements within the content (UMG audio for personal resets for example)
 * @param properties.tstPlatform: The call center platform upon which TST Resets are served
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/wRELUIiUYE}
 */
export function issueReported(properties: IssueReportedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.contentId !== undefined && properties.contentId !== null ?
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: properties.contentId}) :
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: null});
  properties.contentType !== undefined && properties.contentType !== null ?
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: properties.contentType}) :
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: null});
  properties.contentTitle !== undefined && properties.contentTitle !== null ?
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: properties.contentTitle}) :
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: null});
  properties.contentSubtype !== undefined && properties.contentSubtype !== null ?
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: properties.contentSubtype}) :
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: null});
  properties.contentFormatType !== undefined && properties.contentFormatType !== null ?
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: properties.contentFormatType}) :
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: null});
  properties.contentUrl !== undefined && properties.contentUrl !== null ?
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: properties.contentUrl}) :
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: null});
  properties.contentSource !== undefined && properties.contentSource !== null ?
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: properties.contentSource}) :
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: null});
  properties.tstPlatform !== undefined && properties.tstPlatform !== null ?
    eventPropertiesArray.push({id: "LSAAiy9CP", name: "tst_platform", value: properties.tstPlatform}) :
    eventPropertiesArray.push({id: "LSAAiy9CP", name: "tst_platform", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "wRELUIiUYE", "f1f56c3c4a715f0e3cdbbebc9d2d8fa3ae917c360c116bd5e7a7e97da69de2c6", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("issue_reported", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("wRELUIiUYE", "issue_reported", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("issue_reported", eventProperties, "wRELUIiUYE", "f1f56c3c4a715f0e3cdbbebc9d2d8fa3ae917c360c116bd5e7a7e97da69de2c6");
    }
    // destination Segment
    Segment.logEvent("issue_reported", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface SendResetProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  contentId: string | null | undefined;
  contentType: ContentTypeValueType | null | undefined;
  contentTitle: string | null | undefined;
  contentSubtype: ContentSubtypeValueType | null | undefined;
  contentFormatType: ContentFormatTypeValueType | null | undefined;
  contentUrl: string | null | undefined;
  contentSource: ContentSourceValueType | null | undefined;
  tstPlatform: TstPlatformValueType | null | undefined;
}
/**
 * send_reset: Triggered when a user directly sends a reset to another's workspace.
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.contentId: Recommended Content ID including external resources
 * @param properties.contentType: The type of content (reset, video, article etc.) in English
 * @param properties.contentTitle: The title of the content (Resets, Articles, Videos etc.) including external resources as well
 * @param properties.contentSubtype: Secondary type based on the content type:
Articles: role_model | recipe | watch_me_thrive | article

 * @param properties.contentFormatType: The format of the content displayed/consumed.

Articles: audio | video | text
Resets: audio | video
External resources: pdf | audio | video | text
 * @param properties.contentUrl: This is the URL of the content:
- If it is an iframe rendered - it would be the iframe full URL
- If it is an external resource (such as benefit connect / sequoia links) - it would be the external full URL

example:
https://thriveglobal.showpad.com/share/cpCAwJwBjxiFfegfWnIHj
https://login.sequoia.com/
 * @param properties.contentSource: Describes the source of the content or elements within the content (UMG audio for personal resets for example)
 * @param properties.tstPlatform: The call center platform upon which TST Resets are served
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/zddWF-D2_v}
 */
export function sendReset(properties: SendResetProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  properties.contentId !== undefined && properties.contentId !== null ?
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: properties.contentId}) :
    eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: null});
  properties.contentType !== undefined && properties.contentType !== null ?
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: properties.contentType}) :
    eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: null});
  properties.contentTitle !== undefined && properties.contentTitle !== null ?
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: properties.contentTitle}) :
    eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: null});
  properties.contentSubtype !== undefined && properties.contentSubtype !== null ?
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: properties.contentSubtype}) :
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: null});
  properties.contentFormatType !== undefined && properties.contentFormatType !== null ?
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: properties.contentFormatType}) :
    eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: null});
  properties.contentUrl !== undefined && properties.contentUrl !== null ?
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: properties.contentUrl}) :
    eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: null});
  properties.contentSource !== undefined && properties.contentSource !== null ?
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: properties.contentSource}) :
    eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: null});
  properties.tstPlatform !== undefined && properties.tstPlatform !== null ?
    eventPropertiesArray.push({id: "LSAAiy9CP", name: "tst_platform", value: properties.tstPlatform}) :
    eventPropertiesArray.push({id: "LSAAiy9CP", name: "tst_platform", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "zddWF-D2_v", "dce466168a8b0917d930cefb4b0f52e9809dc11527fe0f60dd4c4c047b728012", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("send_reset", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("zddWF-D2_v", "send_reset", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("send_reset", eventProperties, "zddWF-D2_v", "dce466168a8b0917d930cefb4b0f52e9809dc11527fe0f60dd4c4c047b728012");
    }
    // destination Segment
    Segment.logEvent("send_reset", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ChallengeGoalAllCompletedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  challengeId: string;
  dayNumber: number | null | undefined;
  teamId: string | null | undefined;
  teamType: TeamTypeValueType | null | undefined;
  challengeTheme: string | null | undefined;
  challengeType: string | null | undefined;
}
/**
 * challenge_goal_all_completed: Fires when all daily challenge goals have been completed
 *
 * When to trigger this event:
 * 1. Fires when all activities in a given day have been completed by the user as part of a challenge.
feature_type = 'challenge'
activity_type = 'daily_goals_all_completed'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/6iRGTLHHli/trigger/tukUKMgVI
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.challengeId: id of the challenge being completed
 * @param properties.dayNumber: the day number of any event (be it journey level day number, challenge day number etc.)
 * @param properties.teamId: Id of the team the user belongs to. (this is the equivalent of social_group_id)
 * @param properties.teamType: team type for the company challenge events. Values can be 'individual' or 'team'.
 * @param properties.challengeTheme: the type of company challenge (e.g., hydration, movement)
 * @param properties.challengeType: the type of challenge (e.g., journey, biotype, company, group)
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/6iRGTLHHli}
 */
export function challengeGoalAllCompleted(
  properties: ChallengeGoalAllCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "Ut9tbn2enR0g", name: "challenge_id", value: properties.challengeId});
  properties.dayNumber !== undefined && properties.dayNumber !== null ?
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: properties.dayNumber}) :
    eventPropertiesArray.push({id: "mFiZDzDsYh-k", name: "day_number", value: null});
  properties.teamId !== undefined && properties.teamId !== null ?
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: properties.teamId}) :
    eventPropertiesArray.push({id: "uWD2RYPvzZaW", name: "team_id", value: null});
  properties.teamType !== undefined && properties.teamType !== null ?
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: properties.teamType}) :
    eventPropertiesArray.push({id: "zU3g2mhhn7", name: "team_type", value: null});
  properties.challengeTheme !== undefined && properties.challengeTheme !== null ?
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: properties.challengeTheme}) :
    eventPropertiesArray.push({id: "vHyuPgL4us", name: "challenge_theme", value: null});
  properties.challengeType !== undefined && properties.challengeType !== null ?
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: properties.challengeType}) :
    eventPropertiesArray.push({id: "Y6tX_Ay63", name: "challenge_type", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "6iRGTLHHli", "db82a630526459c71d91b9ef8d1caf4755e1d1118fc42a8e8a33eacf02f399af", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("challenge_goal_all_completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("6iRGTLHHli", "challenge_goal_all_completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("challenge_goal_all_completed", eventProperties, "6iRGTLHHli", "db82a630526459c71d91b9ef8d1caf4755e1d1118fc42a8e8a33eacf02f399af");
    }
    // destination Segment
    Segment.logEvent("challenge_goal_all_completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface MicrostepServedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  microstepId: string;
  microstepBody: string | null | undefined;
  isAutomaticCheckin: boolean | null | undefined;
  microstepName: string;
  checkInDate: string | null | undefined;
}
/**
 * microstep_served: When a user is served a Microstep
 *
 * When to trigger this event:
 * 1. Fires when a user is exposed to a microstep.
feature_type = 'journeys'
activity_type = See Below
+microstep_properties


For the standard daily experience microstep which happens post DCI: activity_type = 'journey_microstep'
For scheduled microstep reminder comes in: activity_type = 'scheduled_microstep'
For AI recommended microstep in the modal: activity_type = 'recommended_microstep'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/yVq0XAXB_v/trigger/dSjQQvr3Z
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.microstepId: The Microstep ID
 * @param properties.microstepBody: The body of the Microstep that tells us what Microstep is being checked into.
 * @param properties.isAutomaticCheckin: Flag to distinguish between automatic Microstep Check-Ins and manual ones.
 * @param properties.microstepName: Name of the checked in microstep
 * @param properties.checkInDate: The date the automated checkin occurred. (YYYY-DD-MM format)
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/yVq0XAXB_v}
 */
export function microstepServed(properties: MicrostepServedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "fBtUkEzBban-", name: "microstep_id", value: properties.microstepId});
  properties.microstepBody !== undefined && properties.microstepBody !== null ?
    eventPropertiesArray.push({id: "YMHMzP_Nb", name: "microstep_body", value: properties.microstepBody}) :
    eventPropertiesArray.push({id: "YMHMzP_Nb", name: "microstep_body", value: null});
  properties.isAutomaticCheckin !== undefined && properties.isAutomaticCheckin !== null ?
    eventPropertiesArray.push({id: "Ftso3m8Ty", name: "is_automatic_checkin", value: properties.isAutomaticCheckin}) :
    eventPropertiesArray.push({id: "Ftso3m8Ty", name: "is_automatic_checkin", value: null});
  eventPropertiesArray.push({id: "sWRRlPTJjjSi", name: "microstep_name", value: properties.microstepName});
  properties.checkInDate !== undefined && properties.checkInDate !== null ?
    eventPropertiesArray.push({id: "oxawdlke-", name: "check_in_date", value: properties.checkInDate}) :
    eventPropertiesArray.push({id: "oxawdlke-", name: "check_in_date", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "yVq0XAXB_v", "a9bd80c38533708842204f23a021bfe143df4dbe767d7c2e4fd3208bc81e309c", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("microstep_served", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("yVq0XAXB_v", "microstep_served", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("microstep_served", eventProperties, "yVq0XAXB_v", "a9bd80c38533708842204f23a021bfe143df4dbe767d7c2e4fd3208bc81e309c");
    }
    // destination Segment
    Segment.logEvent("microstep_served", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ContentEditedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  resetId: string;
  resetName: string;
  userId: string;
}
/**
 * content_edited: When a user edits personal/customizable content.
 *
 * When to trigger this event:
 * 1. When a user clicks in to edit a Personal Reset

feature_type: content
activity_type: personal_reset_edit_selected
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/L6Opi7N203/trigger/2GbrRw0ik
 * 2. When a user saves their Personal Reset after editing it (not saving a new Personal Reset)

feature_type: content
activity_type: personal_reset_edit_completed
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/L6Opi7N203/trigger/vjanDocf3
 * 3. When a user exits the Reset page and cancels their reset edits.

feature_type: content
activity_type: personal_reset_edit_cancelled
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/L6Opi7N203/trigger/JTzxB7BsV
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.resetId: unique identifier for the reset that needs to be done for that daily goal
 * @param properties.resetName: Name of the reset
 * @param properties.userId: ID of the user.
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/L6Opi7N203}
 */
export function contentEdited(properties: ContentEditedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "1QmenQkbcsOL", name: "reset_id", value: properties.resetId});
  eventPropertiesArray.push({id: "Ib5rtoVJ2Kqm", name: "reset_name", value: properties.resetName});
  eventPropertiesArray.push({id: "_26LlseC-nRA", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "L6Opi7N203", "bb45cb2aa0d0bcd5dbe766ddf5cbb3c8a5c41c67f3f0e81588226a622857a61a", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("content_edited", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("L6Opi7N203", "content_edited", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("content_edited", eventProperties, "L6Opi7N203", "bb45cb2aa0d0bcd5dbe766ddf5cbb3c8a5c41c67f3f0e81588226a622857a61a");
    }
    // destination Segment
    Segment.logEvent("content_edited", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ContentCreationCancelledProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  userId: string;
}
/**
 * content_creation_cancelled: When a user cancels the creation of a new Personal Reset
 *
 * When to trigger this event:
 * 1. When a user cancels the creation of a NEW personal reset.

feature_type: content
activity_type: content_creation_cancelled
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/dxsu6NhS0s/trigger/ZjON936tF
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.userId: ID of the user.
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/dxsu6NhS0s}
 */
export function contentCreationCancelled(
  properties: ContentCreationCancelledProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "_26LlseC-nRA", name: "user_id", value: properties.userId});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "dxsu6NhS0s", "deab22132d2d6284bbdd7da090c38dd3f770ca780f07a373d34dfe87257caf88", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("content_creation_cancelled", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("dxsu6NhS0s", "content_creation_cancelled", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("content_creation_cancelled", eventProperties, "dxsu6NhS0s", "deab22132d2d6284bbdd7da090c38dd3f770ca780f07a373d34dfe87257caf88");
    }
    // destination Segment
    Segment.logEvent("content_creation_cancelled", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface CategorySelectedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  displayText: string;
}
/**
 * category_selected: User selected a button within a category selection bar
 *
 * When to trigger this event:
 * 1. When a user selects 'Browse' on the Microstep Category Selector

feature_type: library
activity_type: microstep_hub_sorting
display_text: browse
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Lp-5VZF91s/trigger/WeZJWexfd
 * 2. When a user selects 'Favorites' on the Microstep Category Selector

feature_type: library
activity_type: microstep_hub_sorting
display_text: favorites
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Lp-5VZF91s/trigger/i732EwIH-
 * 3. When a user selects 'Completed' on the Microstep Category Selector

feature_type: library
activity_type: microstep_hub_sorting
display_text: completed
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Lp-5VZF91s/trigger/DrKKgNdcg
 * 4. When a user clicks the 'View completed Microsteps' button; functions the same as the 'Completed' category selection

feature_type: library
activity_type: microstep_hub_sorting
display_text: view_completed_microsteps
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Lp-5VZF91s/trigger/rMtRQyGr0
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.displayText: The text on any selectable/clickable element.
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/Lp-5VZF91s}
 */
export function categorySelected(properties: CategorySelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "1YLolCiLg", name: "display_text", value: properties.displayText});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "Lp-5VZF91s", "20241e7bded0e22db008da873d0e5ae2da22b054fe698c819a07676458dc9b67", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("category_selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("Lp-5VZF91s", "category_selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("category_selected", eventProperties, "Lp-5VZF91s", "20241e7bded0e22db008da873d0e5ae2da22b054fe698c819a07676458dc9b67");
    }
    // destination Segment
    Segment.logEvent("category_selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ListSortedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  displayText: string;
}
/**
 * list_sorted: User selected a sorting choice within a sorting dropdown.
 *
 * When to trigger this event:
 * 1. When a user selects a sorting option from the sorting dropdown.

feature_type: library
activity_type: microstep_favorites_sorting
display_text: [newest_favorited / oldest_favorited / most_popular / least_popular]
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/mLKttOS8A0/trigger/66MPYDDhs
 * 2. feature_type: library
activity_type: microstep_browse_sorting
display_text: [most_popular / least_popular]
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/mLKttOS8A0/trigger/7rcAQYYEv
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.displayText: The text on any selectable/clickable element.
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/mLKttOS8A0}
 */
export function listSorted(properties: ListSortedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "1YLolCiLg", name: "display_text", value: properties.displayText});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "mLKttOS8A0", "26c61a8cb90f1e39bcdd8ff73ef02f0c5dca6f19fd55de243205e9790f7f1dba", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("list_sorted", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("mLKttOS8A0", "list_sorted", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("list_sorted", eventProperties, "mLKttOS8A0", "26c61a8cb90f1e39bcdd8ff73ef02f0c5dca6f19fd55de243205e9790f7f1dba");
    }
    // destination Segment
    Segment.logEvent("list_sorted", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FilterSelectedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  displayText: string;
}
/**
 * filter_selected: When a user selects a filter for topics on content, microsteps, etc.
 *
 * When to trigger this event:
 * 1. When a user clicks the Topic Filter and selects a topic filtering type.

feature_type: library
activity_type: microstep_topic_filtering
display_text: [all_topics / sleep / food / movement / stress_management / connection / focus / money]
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/gUV50h6dMU/trigger/N2DYEnQZI
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.displayText: The text on any selectable/clickable element.
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/gUV50h6dMU}
 */
export function filterSelected(properties: FilterSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "1YLolCiLg", name: "display_text", value: properties.displayText});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "gUV50h6dMU", "e02215be7ae8ad23a1505474b0f72dd4b52370c014d601bb0c44fc3e64e6be58", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("filter_selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("gUV50h6dMU", "filter_selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("filter_selected", eventProperties, "gUV50h6dMU", "e02215be7ae8ad23a1505474b0f72dd4b52370c014d601bb0c44fc3e64e6be58");
    }
    // destination Segment
    Segment.logEvent("filter_selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface NotificationSubscribedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  nudgeType: string;
}
/**
 * notification_subscribed: When a user subscribes to any notification.
 *
 * When to trigger this event:
 * 1. Fires when a user toggles their Reset notifications On
feature_type = 'notifications'
activity_type = 'reset_notification_subscribed'
notification_type = 'reset'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/YYd59aaix5/trigger/tEBe64Es9
 * 2. When a user is served the option to allow browser notifications from their browser

feature_type: notifications
activity_type: desktop_notifications_subscribed
utm_source: browser_notification
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/YYd59aaix5/trigger/xo9DYKl8g
 * 3. When a user selects the option to allow browser notifications in app

feature_type: notifications
activity_type: browser_notifications_subscribed
utm_source: browser_notification
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/YYd59aaix5/trigger/b0KTn1i-R
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.nudgeType: The type of notification
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/YYd59aaix5}
 */
export function notificationSubscribed(
  properties: NotificationSubscribedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "5gHzHN48a", name: "nudge_type", value: properties.nudgeType});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "YYd59aaix5", "11d3382433ff2f3b8e6632d04af5a591a39c33b3c43bc65b92c640ad16ee827c", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("notification_subscribed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("YYd59aaix5", "notification_subscribed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("notification_subscribed", eventProperties, "YYd59aaix5", "11d3382433ff2f3b8e6632d04af5a591a39c33b3c43bc65b92c640ad16ee827c");
    }
    // destination Segment
    Segment.logEvent("notification_subscribed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface NotificationUnsubscribedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  nudgeType: string;
}
/**
 * notification_unsubscribed: When a user unsubscribes to any notification.
 *
 * When to trigger this event:
 * 1. Fires when a user toggles their Reset notifications Off
feature_type = 'notifications'
activity_type = 'reset_notification_unsubscribed'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/LxbpzNC72/trigger/xcHEbMklCC
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.nudgeType: The type of notification
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/LxbpzNC72}
 */
export function notificationUnsubscribed(
  properties: NotificationUnsubscribedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "5gHzHN48a", name: "nudge_type", value: properties.nudgeType});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "LxbpzNC72", "f7f6a02f194a30af62afba99f89dcd4b2b037a76870e16eb810d6279903ffc29", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("notification_unsubscribed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("LxbpzNC72", "notification_unsubscribed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("notification_unsubscribed", eventProperties, "LxbpzNC72", "f7f6a02f194a30af62afba99f89dcd4b2b037a76870e16eb810d6279903ffc29");
    }
    // destination Segment
    Segment.logEvent("notification_unsubscribed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FeatureFlaggedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  isEnabled: boolean;
}
/**
 * feature_flagged: This event is to be used to flag features for phased rollouts.
 *
 * When to trigger this event:
 * 1. Fires when a user lands on the Insights page and has the DCI unlocked on Day 0.

feature_type = 'insights'
activity_type = 'insights_new_day_zero_served'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/tWwP5R_ESV/trigger/BLtmsr7ec
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.isEnabled: Property for flagging whether a feature flag is enabled or not
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/tWwP5R_ESV}
 */
export function featureFlagged(properties: FeatureFlaggedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "yF0DQdbNo", name: "is_enabled", value: properties.isEnabled});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "tWwP5R_ESV", "72cddabf84037f3f8690ffb6f6e88087f735336afa2183bc3e3e0aa0ffc8e313", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("feature_flagged", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("tWwP5R_ESV", "feature_flagged", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("feature_flagged", eventProperties, "tWwP5R_ESV", "72cddabf84037f3f8690ffb6f6e88087f735336afa2183bc3e3e0aa0ffc8e313");
    }
    // destination Segment
    Segment.logEvent("feature_flagged", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface IntentionSelectedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  responseText: string;
  isOnboarding: boolean | null | undefined;
}
/**
 * intention_selected: When a user selects or inputs an intention during the Intention Setting process.
 *
 * When to trigger this event:
 * 1. When a user selects one of the pre-determined intention selections on the intention selection screen.

feature_type: intention_setting
activity_type: pre_selected_response
response_text: ['I want to have more energy',
'I want to stop waking up at night',
'Feel less stressed at work',
'I want to eat healthier and increase movement',
'Spend more time with family',
'I don't know where to start',
'Choose for me']

If this occurs during the New User flow, we should also capture:

is_onboarding = true
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/chISuCbBnX/trigger/1mFPFccMG
 * 2. When a user inputs their own intention on the intention write-in screen and then clicks Next.

feature_type: intention_setting
activity_type: user_written_response
response_text: [user text as string]

If this occurs during the New User flow, we should also capture:

is_onboarding = true
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/chISuCbBnX/trigger/WuGGmdAu7
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.responseText: The response to an assessment question (e.g. 'Strongly agree|Agree|Neither agree nor disagree|Disagree|Strongly disagree|NULL')
 * @param properties.isOnboarding: T/F flag for whether an event occurred during onboarding or not.
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/chISuCbBnX}
 */
export function intentionSelected(properties: IntentionSelectedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "32zgCY_PY", name: "response_text", value: properties.responseText});
  properties.isOnboarding !== undefined && properties.isOnboarding !== null ?
    eventPropertiesArray.push({id: "RCLdkrcD0", name: "is_onboarding", value: properties.isOnboarding}) :
    eventPropertiesArray.push({id: "RCLdkrcD0", name: "is_onboarding", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "chISuCbBnX", "d2c46d94972228601eb6368d0b9b52a97123b457e029e02b1d4dc350a04b3db0", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("intention_selected", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("chISuCbBnX", "intention_selected", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("intention_selected", eventProperties, "chISuCbBnX", "d2c46d94972228601eb6368d0b9b52a97123b457e029e02b1d4dc350a04b3db0");
    }
    // destination Segment
    Segment.logEvent("intention_selected", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FocusAreasSetProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  subscriptionId: string;
  subscriptionType: string;
  license: string;
  isLoggedIn: string;
  previousPath: string;
  companyId: string;
  focusAreas: FocusAreasValueType[];
  isOnboarding: boolean;
  responseText: string | null | undefined;
}
/**
 * focus_areas_set: When the user sets or is assigned their Focus Areas through the Intention Setting experience.
 *
 * When to trigger this event:
 * 1. When the user accepts their Focus Areas after Intention Setting and ultimately click "See my personalized plan".

feature_type = intention_setting
activity_type = personalized_plan_selected
focus_areas = [list representing the Topics used for the user's personalized plan]

If this occurs during the New User flow, we should also capture:

is_onboarding = true
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/uIb4BIs6BF/trigger/oTPEBRPku
 * 2. If the user selects "Choose for me" after Intention Setting and ultimately clicks "See my personalized plan", then the focus_areas property should default to 'Sleep'.

feature_type = intention_setting
activity_type = personalized_plan_selected
focus_areas = recharge (sleep)
response_text = "Choose for me"

If this occurs during the New User flow, we should also capture:

is_onboarding = true
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/uIb4BIs6BF/trigger/zgdrjAJyy
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.subscriptionId: Subscription ID that a company has
 * @param properties.subscriptionType: The type of subscription a user has
 * @param properties.license: The type of license a company has with Thrive
 * @param properties.isLoggedIn: True if user is logged in. Otherwise null.
 * @param properties.previousPath: Previous Micro Frontend the user came from
 * @param properties.companyId: ID of the company.
 * @param properties.focusAreas: Capture the focus areas set by the user during the Intention Setting flow on the focus_areas_set event. The focus areas should be listed in alphabetical order.
 * @param properties.isOnboarding: T/F flag for whether an event occurred during onboarding or not.
 * @param properties.responseText: The response to an assessment question (e.g. 'Strongly agree|Agree|Neither agree nor disagree|Disagree|Strongly disagree|NULL')
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/uIb4BIs6BF}
 */
export function focusAreasSet(properties: FocusAreasSetProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "lhNCuk9Xd8A1", name: "subscription_id", value: properties.subscriptionId});
  eventPropertiesArray.push({id: "j2e-0ySYTB", name: "subscription_type", value: properties.subscriptionType});
  eventPropertiesArray.push({id: "gNhhk8W44l", name: "license", value: properties.license});
  eventPropertiesArray.push({id: "ioZ2j5wBem", name: "is_logged_in", value: properties.isLoggedIn});
  eventPropertiesArray.push({id: "CuLJJR3OpL", name: "previous_path", value: properties.previousPath});
  eventPropertiesArray.push({id: "h_HkYy-iweh5", name: "company_id", value: properties.companyId});
  eventPropertiesArray.push({id: "Z363re2s2", name: "focus_areas", value: properties.focusAreas});
  eventPropertiesArray.push({id: "RCLdkrcD0", name: "is_onboarding", value: properties.isOnboarding});
  properties.responseText !== undefined && properties.responseText !== null ?
    eventPropertiesArray.push({id: "32zgCY_PY", name: "response_text", value: properties.responseText}) :
    eventPropertiesArray.push({id: "32zgCY_PY", name: "response_text", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "uIb4BIs6BF", "b9e80e0a1204e8b0bf152f1e65df528c3407f6fdda1c4bf416ce28e399521170", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("focus_areas_set", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("uIb4BIs6BF", "focus_areas_set", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("focus_areas_set", eventProperties, "uIb4BIs6BF", "b9e80e0a1204e8b0bf152f1e65df528c3407f6fdda1c4bf416ce28e399521170");
    }
    // destination Segment
    Segment.logEvent("focus_areas_set", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface FocusAreasSuggestedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  focusAreas: FocusAreasValueType[];
  isOnboarding: boolean | null | undefined;
}
/**
 * focus_areas_suggested: Captures the focus areas suggested by the system.
 *
 * When to trigger this event:
 * 1. Fires when the user is presented with the Focus Area suggestion based on their Intention response.

feature_type = intention_setting
activity_type = focus_areas_suggested
focus_areas = [list of focus areas suggested in alphabetical order]
is_system_event = true

If this occurs during the New User flow, we should also capture:

is_onboarding = true
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/oM60f1DzhP/trigger/zdAxCe4p1
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.focusAreas: Capture the focus areas set by the user during the Intention Setting flow on the focus_areas_set event. The focus areas should be listed in alphabetical order.
 * @param properties.isOnboarding: T/F flag for whether an event occurred during onboarding or not.
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/oM60f1DzhP}
 */
export function focusAreasSuggested(properties: FocusAreasSuggestedProperties
  ) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "Z363re2s2", name: "focus_areas", value: properties.focusAreas});
  properties.isOnboarding !== undefined && properties.isOnboarding !== null ?
    eventPropertiesArray.push({id: "RCLdkrcD0", name: "is_onboarding", value: properties.isOnboarding}) :
    eventPropertiesArray.push({id: "RCLdkrcD0", name: "is_onboarding", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "oM60f1DzhP", "db2da53cb8f8ca5dec42de0a3741d64a99ee4640d55ffe64d4c529b87b643ebb", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("focus_areas_suggested", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("oM60f1DzhP", "focus_areas_suggested", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("focus_areas_suggested", eventProperties, "oM60f1DzhP", "db2da53cb8f8ca5dec42de0a3741d64a99ee4640d55ffe64d4c529b87b643ebb");
    }
    // destination Segment
    Segment.logEvent("focus_areas_suggested", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface VoteStartedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
}
/**
 * vote_started: When a user initiates a Live Share Reset vote.
 *
 * When to trigger this event:
 * 1. Fires when a user selects "Begin vote" after selecting the Resets to include in the voting.
feature_type = 'content'
activity_type = 'reset_poll_started'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/MCZ4rq_SL3/trigger/R9Jm5cglb
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/MCZ4rq_SL3}
 */
export function voteStarted(properties: VoteStartedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "MCZ4rq_SL3", "84fc2f90b56993b7348f61e1bfaa7999454835db7b9c7c36581fa62ed17df863", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("vote_started", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("MCZ4rq_SL3", "vote_started", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("vote_started", eventProperties, "MCZ4rq_SL3", "84fc2f90b56993b7348f61e1bfaa7999454835db7b9c7c36581fa62ed17df863");
    }
    // destination Segment
    Segment.logEvent("vote_started", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface VoteSubmittedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
  contentId: string;
  contentType: ContentTypeValueType;
  contentTitle: string;
  contentSubtype: ContentSubtypeValueType | null | undefined;
  contentFormatType: ContentFormatTypeValueType;
  contentUrl: string;
  contentSource: ContentSourceValueType;
}
/**
 * vote_submitted: Fires when a user votes on a Reset to watch during Live Share
 *
 * When to trigger this event:
 * 1. Fires when a user selects "Vote for this Reset".
feature_type = 'content'
activity_type = 'reset_vote_submitted'
+ content_properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/vJeWkmET-Pn/trigger/MAOIVLIHm
 * 2. Fires when a user votes on a Reset
feature_type = 'content'
activity_type = 'reset_vote_submitted'
+ content_properties
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/vJeWkmET-Pn/trigger/4c-v0w58u
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 * @param properties.contentId: Recommended Content ID including external resources
 * @param properties.contentType: The type of content (reset, video, article etc.) in English
 * @param properties.contentTitle: The title of the content (Resets, Articles, Videos etc.) including external resources as well
 * @param properties.contentSubtype: Secondary type based on the content type:
Articles: role_model | recipe | watch_me_thrive | article

 * @param properties.contentFormatType: The format of the content displayed/consumed.

Articles: audio | video | text
Resets: audio | video
External resources: pdf | audio | video | text
 * @param properties.contentUrl: This is the URL of the content:
- If it is an iframe rendered - it would be the iframe full URL
- If it is an external resource (such as benefit connect / sequoia links) - it would be the external full URL

example:
https://thriveglobal.showpad.com/share/cpCAwJwBjxiFfegfWnIHj
https://login.sequoia.com/
 * @param properties.contentSource: Describes the source of the content or elements within the content (UMG audio for personal resets for example)
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/vJeWkmET-Pn}
 */
export function voteSubmitted(properties: VoteSubmittedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "cLPXrN62eIA5", name: "content_id", value: properties.contentId});
  eventPropertiesArray.push({id: "0BWD93y9FHRR", name: "content_type", value: properties.contentType});
  eventPropertiesArray.push({id: "MyH_0iQzD", name: "content_title", value: properties.contentTitle});
  properties.contentSubtype !== undefined && properties.contentSubtype !== null ?
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: properties.contentSubtype}) :
    eventPropertiesArray.push({id: "07tQlza0wJ", name: "content_subtype", value: null});
  eventPropertiesArray.push({id: "i52PQ_TtsF", name: "content_format_type", value: properties.contentFormatType});
  eventPropertiesArray.push({id: "w4sFUgwXD", name: "content_url", value: properties.contentUrl});
  eventPropertiesArray.push({id: "5mLUiVL9X", name: "content_source", value: properties.contentSource});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "vJeWkmET-Pn", "6090eea475a91f50ad3b81600b6212436323b0ad61b01ac13e3cb3fd30239f3c", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("vote_submitted", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("vJeWkmET-Pn", "vote_submitted", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("vote_submitted", eventProperties, "vJeWkmET-Pn", "6090eea475a91f50ad3b81600b6212436323b0ad61b01ac13e3cb3fd30239f3c");
    }
    // destination Segment
    Segment.logEvent("vote_submitted", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface VoteCompletedProperties {
  featureType: FeatureTypeValueType;
  activityType: ActivityTypeValueType;
  isSystemEvent: boolean | null | undefined;
}
/**
 * vote_completed: Fires when the voting on a Live Share Reset is ended
 *
 * When to trigger this event:
 * 1. Fires when a user selects "End Voting" to conclude the Reset voting.
feature_type = 'content'
activity_type = 'reset_vote_completed'
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/4OaOzBxkZ7/trigger/8ZQQFFM2pP
 *
 * @param properties the properties associatied with this event
 * @param properties.featureType: Feature Type for event (snakecase lowercase format)

= learn_recommended_content when user clicks on the articles from the recommended section.
= learn_popular_content when user clicks on the articles from the popular section
= library when user clicks on microsteps from the microstep library
= content when a user clicks on content-related activities
 * @param properties.activityType:
= thrive_guide_recommendation when event originates within the thrive guide chat bot
= learn_article_clicked when event originates from the Library
= microstep_management when user clicks on microsteps from the microstep library
 * @param properties.isSystemEvent: Flag for system events (i.e. events that do not require a user action). Should always be pinned as True or False
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/4OaOzBxkZ7}
 */
export function voteCompleted(properties: VoteCompletedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "XflwLXM_6", name: "feature_type", value: properties.featureType});
  eventPropertiesArray.push({id: "8HodUgqGhCAz", name: "activity_type", value: properties.activityType});
  properties.isSystemEvent !== undefined && properties.isSystemEvent !== null ?
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: properties.isSystemEvent}) :
    eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: null});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "4OaOzBxkZ7", "b943675625a00af6b2b3ff147efcaaa40bd91eca8d238c97aaf3b6215b26f0ee", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("vote_completed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("4OaOzBxkZ7", "vote_completed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("vote_completed", eventProperties, "4OaOzBxkZ7", "b943675625a00af6b2b3ff147efcaaa40bd91eca8d238c97aaf3b6215b26f0ee");
    }
    // destination Segment
    Segment.logEvent("vote_completed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export interface ActivityRemovedProperties {
  activityDate: string;
  durationInMinutes: number;
  stepCount: number;
  numberOfActivities: number;
}
/**
 * activity_removed: Triggers when a user selects "Save Changes" after selecting to delete/remove an activity. If more than one activity is removed, they should fire different events
 *
 * When to trigger this event:
 * 1. web
When a User clicks "Delete", the button will update to "Undo". The activity_removed event should fire when they chose to Save Changes after removing an activity.
 * View in Avo: https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/NxzVx0Q_u/trigger/vYnFHkFiW
 *
 * @param properties the properties associatied with this event
 * @param properties.activityDate: The date that the activity is being added/deleted.
 * @param properties.durationInMinutes: The duration in minutes that the activity lasted. For deleting multiple manual activities at once, this would be the sum of all activities that were deleted.
 * @param properties.stepCount: The total Step count for manually added/deleted activities. For multiple additions/deletions, this will be the sum of the steps.
 * @param properties.numberOfActivities: Number of activities that were manually deleted.
 *
 * @see {@link https://www.avo.app/schemas/MouZL0iIZXRZD1IE6U6E/events/NxzVx0Q_u}
 */
export function activityRemoved(properties: ActivityRemovedProperties) {
  // @ts-ignore
  let eventPropertiesArray: array = [];
  eventPropertiesArray.push({id: "Kj-yej1Bw", name: "activity_date", value: properties.activityDate});
  eventPropertiesArray.push({id: "jQ9A1fcOG", name: "duration_in_minutes", value: properties.durationInMinutes});
  eventPropertiesArray.push({id: "GVf0TLVTi", name: "step_count", value: properties.stepCount});
  eventPropertiesArray.push({id: "2lI43Twr1", name: "number_of_activities", value: properties.numberOfActivities});
  eventPropertiesArray.push({id: "5cv95vgaj", name: "is_system_event", value: false});
  eventPropertiesArray.push({id: "6TGdO8iNVr", name: "app_source", value: sysAppSource});
  eventPropertiesArray.push({id: "JpIoBTTrlt", name: "thrive_locale", value: sysThriveLocale});
  eventPropertiesArray.push({id: "lQCP4_ynKd", name: "session_id", value: sysSessionId});
  eventPropertiesArray.push({id: "ErBg5CaWkZ", name: "client", value: sysClient});
  let eventProperties = convertPropertiesArrayToMap(eventPropertiesArray)
  // @ts-ignore
  let userPropertiesArray: array = [];
  let userProperties = convertPropertiesArrayToMap(userPropertiesArray)
  // assert properties
  if (__AVO_ENV__ !== AvoEnv.Prod || __WEB_DEBUGGER__) {
    let messages: AvoAssertMessage[] = [];
    // debug console in Avo
    if (!__AVO_NOOP__) {
      _avo_invoke(__AVO_ENV__, "NxzVx0Q_u", "de653698ae1a0f4b741d5aa89d2de686e7129ceb100b32eea0a64bdb8b6f6d6e", messages.map(m => Object.assign({}, {tag: m.tag, propertyId: m.propertyId, additionalProperties: m.additionalProperties, actualType: m.actualType})), 'event');
    }
    InternalAvoLogger.logEventSent("activity_removed", eventProperties, userProperties);
    if (__WEB_DEBUGGER__) {
      // Avo web debugger
      _avo_debugger_log("NxzVx0Q_u", "activity_removed", messages, eventPropertiesArray, userPropertiesArray, []);
    }
  }
  if (!__AVO_NOOP__) {
    if (__INSPECTOR__ != null) {
      // @ts-ignore
      __INSPECTOR__._avoFunctionTrackSchemaFromEvent("activity_removed", eventProperties, "NxzVx0Q_u", "de653698ae1a0f4b741d5aa89d2de686e7129ceb100b32eea0a64bdb8b6f6d6e");
    }
    // destination Segment
    Segment.logEvent("activity_removed", (Object as any).assign({}, eventProperties));
  } else {
    // do nothing
  }
}

export default {
  AvoEnv,
  initAvo,
  setSystemProperties,
  avoInspectorApiKey,
  ContentSubtype,
  ContentType,
  Method,
  ContentSource,
  AppSource,
  Status,
  ActivityType,
  ResetType,
  PageSection,
  Client,
  FeatureName,
  TstPlatform,
  FeatureType,
  FocusAreas,
  FeedbackAnswer,
  ContentFormatType,
  TabName,
  MessageType,
  DciFrequency,
  RemovalReason,
  TeamType,
  plantStarted,
  activityAdded,
  feedbackSubmitted,
  responseSubmitted,
  contentStarted,
  contentCompleted,
  surveySubmitted,
  surveyServed,
  promptSelected,
  contentDownloaded,
  contentShared,
  microstepRemoved,
  microstepAdded,
  microstepCheckinCompleted,
  microstepCheckinRemoved,
  reactionSubmitted,
  submissionStarted,
  submissionCompleted,
  challengeGoalCompleted,
  challengeStarted,
  challengeSelected,
  contentCreationStarted,
  contentCreationCompleted,
  responseServed,
  socialGroupJoined,
  challengeLeft,
  signinCompleted,
  signupStarted,
  signupFailed,
  signupCompleted,
  signoutCompleted,
  passwordResetRequested,
  referralStarted,
  resourceSelected,
  resourceServed,
  journeyStarted,
  journeyLevelCompleted,
  achievementCompleted,
  plantAdded,
  inviteSent,
  dciSelected,
  contentServed,
  pageViewed,
  contentSelected,
  surveySubscribed,
  onboardingCompleted,
  surveyStarted,
  surveyCompleted,
  gardenViewed,
  achievementViewed,
  signinFailed,
  promptServed,
  recommendationServed,
  searchStarted,
  challengeCompleted,
  issueReported,
  sendReset,
  challengeGoalAllCompleted,
  microstepServed,
  contentEdited,
  contentCreationCancelled,
  categorySelected,
  listSorted,
  filterSelected,
  notificationSubscribed,
  notificationUnsubscribed,
  featureFlagged,
  intentionSelected,
  focusAreasSet,
  focusAreasSuggested,
  voteStarted,
  voteSubmitted,
  voteCompleted,
  activityRemoved,
}

// AVOMODULEMAP:"Avo"
// AVOEVENTMAP:["plantStarted","activityAdded","feedbackSubmitted","responseSubmitted","contentStarted","contentCompleted","surveySubmitted","surveyServed","promptSelected","contentDownloaded","contentShared","microstepRemoved","microstepAdded","microstepCheckinCompleted","microstepCheckinRemoved","reactionSubmitted","submissionStarted","submissionCompleted","challengeGoalCompleted","challengeStarted","challengeSelected","contentCreationStarted","contentCreationCompleted","responseServed","socialGroupJoined","challengeLeft","signinCompleted","signupStarted","signupFailed","signupCompleted","signoutCompleted","passwordResetRequested","referralStarted","resourceSelected","resourceServed","journeyStarted","journeyLevelCompleted","achievementCompleted","plantAdded","inviteSent","dciSelected","contentServed","pageViewed","contentSelected","surveySubscribed","onboardingCompleted","surveyStarted","surveyCompleted","gardenViewed","achievementViewed","signinFailed","promptServed","recommendationServed","searchStarted","challengeCompleted","issueReported","sendReset","challengeGoalAllCompleted","microstepServed","contentEdited","contentCreationCancelled","categorySelected","listSorted","filterSelected","notificationSubscribed","notificationUnsubscribed","featureFlagged","intentionSelected","focusAreasSet","focusAreasSuggested","voteStarted","voteSubmitted","voteCompleted","activityRemoved"]
